import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Dialog from '@material-ui/core/Dialog'
import CircularProgress from '@material-ui/core/CircularProgress'
import MUIDataTable from 'mui-datatables'
import ContactDialog from './ContactDialog'
import Hidden from '@material-ui/core/Hidden'
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

import { fetchAllContacts, syncADContacts, addContactToAllProjectSites, removeContactFromAllProjectSites, changeContactSiteAssociation } from '../../../actions/contacts'
import { Input } from '@material-ui/core';

import groupBy from 'lodash/groupBy';
import map from 'lodash/map';

const styles = theme => ({
	admin: {
		float: 'right',
		marginLeft: theme.spacing.unit * 1,
		backgroundColor: theme.palette.admin.main,
		'&:hover': {
			backgroundColor: theme.palette.admin.dark
		}
	},
	dialogPaper: {
	    padding: theme.spacing.unit * 4,
	},
	adSync: {
		float: 'left',
		// marginLeft: theme.spacing.unit * 1
	},
	btnAddToProject : {
		float: 'right',
		marginLeft: theme.spacing.unit * 1,
		marginRight: theme.spacing.unit * 1,
	},
	addContactButton : {
		marginTop: 0,
		marginLeft: 15
	}
})

const mapStateToProps = (state, ownProps) => {
	// console.log('statestate', state)
	// console.log('ownProps', ownProps)
	// we might eventually only pass the state of the current site/crossing/project
	let projectContactsResult = state.user.selectedProjectId && state.projects[state.user.selectedProjectId] ? state.contacts.userContacts.filter(contact => contact.projectId === state.projects[state.user.selectedProjectId].id) : [];
	let groupedProjectContacts = groupBy(projectContactsResult, 'contact.id');
	// console.log('groupedProjectContacts', groupedProjectContacts)
	let projectContacts = map(groupedProjectContacts, (g) => {
		let contact = g[0].contact;
		contact.projectId = g[0].projectId;
		contact.siteIds = g.map(pc => pc.siteId)
		return contact;
	});
	return {
		selectedProject: state.user.selectedProjectId && state.projects[state.user.selectedProjectId] ? state.projects[state.user.selectedProjectId] : null,
		selectedSite: state.user.selectedSiteId && state.sites[state.user.selectedSiteId] ? state.sites[state.user.selectedSiteId] : null,
		projectSites: state.user.selectedProjectId && state.sites && state.projects && state.projects[state.user.selectedProjectId] ? state.projects[state.user.selectedProjectId].projectSites.map(p => state.sites[p]).sort((a,b) => a.name - b.name) : [],
		contacts: state.contacts,
		projectContacts
		// projectContacts: state.projects[state.user.selectedProjectId] && state.projects[state.user.selectedProjectId].associatedContacts ? state.projects[state.user.selectedProjectId].associatedContacts.map(contact => state.contacts[contact]) : [],
		// siteContacts: state.sites[state.user.selectedSiteId] && state.sites[state.user.selectedSiteId].associatedContacts ? state.sites[state.user.selectedSiteId].associatedContacts.map(contact => state.contacts[contact]) : []
	}
}

const mapDispatchToProps = dispatch => {
	return {
		fetchAllContacts: () => dispatch(fetchAllContacts()),
		syncADContacts: () => dispatch(syncADContacts()),
		addContactToProject: (c) => dispatch(addContactToAllProjectSites(c)),
		removeContactFromAllProjectSites: (id) => dispatch(removeContactFromAllProjectSites(id)),
		changeContactSiteAssociation: (sa, c) => dispatch(changeContactSiteAssociation(sa, c))
	}
}

class ContactsManagementModal extends React.Component {

	state = {
		open: false,
		projectPage: 1,
		allPage: 1
	}
	componentWillMount(){
		this.props.fetchAllContacts()
	}

	handleOpen = () => {
		// console.log("OPENING MANAGEMENT");
    	this.setState({ open: true });
  	};

  	handleClose = () => {
    	this.setState({ open: false });
  	};

  	render() {
    	const { classes, selectedProject, projectContacts, contacts, projectSites } = this.props;
		// console.log('this.props', this.props)
		// console.log('projectContacts', projectContacts)

	    return (
	      	<div>
		        <Button
					className={classes.admin}
					onClick={this.handleOpen}
					size="small"
					variant="contained"
				>
					Manage
				</Button>
		        <Dialog
					// fullScreen
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={this.state.open}
					onClose={this.handleClose}
					maxWidth={false}
					scroll='body'
					// fullWidth={true}
					PaperProps={{className: classes.dialogPaper}}
		        >
		        	<Grid container spacing={16} alignItems='flex-start'>
						<Grid item xs={12}>
							<MUIDataTable
								title={`Associated Contacts for ${selectedProject ? selectedProject.name : 'loading'}`}
								data={projectContacts.length ? projectContacts.map(n =>{
									// console.log('n', n)
									let { firstName, lastName, jobTitle, email, phoneNumber, id, siteIds } = n
									// let { id, siteIds } = n
									// console.log('subset', subset)
									let subset = [
										firstName || '',
										lastName || '',
										jobTitle || '',
										phoneNumber || '',
										email || '',
										siteIds || [],
										id || '',
									]
									// return Object.values(subset)
									return subset;
								}) : []}
								columns={[
									"First",
									"Last",
									"Title",
									"Phone",
									"Email",
									{
										name: "Sites",
										options: {
											customBodyRender: (v, m, u) => {
												// console.log('v', v, 'm', m, 'u', u)
												return (
													<MultiSelect
														value={v}
														onClose={(siteIdArray) => this.props.changeContactSiteAssociation(siteIdArray, m.rowData[6])}
														items={projectSites}
													/>
												)
											}
										}
									},
									{
										name: "Action",
										options: {
											customBodyRender: (v, m, u) => {
												return (
													<Button
														size="small"
														color="secondary"
														variant="outlined"
														onClick={(e) => this.props.removeContactFromAllProjectSites(v)}
													>
														Remove<Hidden mdDown> from Project</Hidden>
													</Button>
												)
											}
										}
									}
								]}
								options={{
									print: false,
									download: false,
									rowsPerPage: 5,
									selectableRows: 'none',
									page: this.state.projectPage,
									onChangePage: (p) => this.setState({projectPage: p})
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<MUIDataTable
								title={"All Existing Contacts"}
								data={contacts.allContacts.length ? contacts.allContacts.map(n => {
									// console.log('n', n)
									// MUIDataTable requires an array of arrays for data
									let { firstName, lastName, jobTitle, email, phoneNumber, id} = n
									let subset = [
										firstName || '',
										lastName || '',
										jobTitle || '',
										phoneNumber || '',
										email || '',
										id || ''
									]
									// console.log("subset", subset);
									return subset;
									// return Object.values(subset)
								}) : []}
								columns={[
									"First",
									"Last",
									"Title",
									"Phone",
									"Email",
									{
										name: "Action",
										options: {
											customBodyRender: (v, m, u) => {
												return (
													<div>
														<ContactDialog
															buttonTitle="Edit Contact"
															contact={m.rowData}
															screenProps={{contacts:contacts.allContacts}}
														/>
														<Button
															className={classes.btnAddToProject}
															size="small"
															color="secondary"
															variant="outlined"
															onClick={(e) => this.props.addContactToProject(m.rowData)}
														>
															Add<Hidden mdDown> to Project</Hidden>
														</Button>
													</div>
												)
											}
										}
									}
								]}
								options={{
									rowsPerPage: 5,
									print: false,
									download: false,
									selectableRows: 'none',
									page: this.state.allPage,
									onChangePage: (p) => this.setState({allPage: p})
								}}
							/>
						</Grid>
						<Grid item xs={12}>

							<Tooltip
								title="Sync missing GeoEngineers contacts from directory"
								placement="right"
							>
								<Button
									className={classes.adSync}
									size="small"
									color="secondary"
									variant="outlined"
									onClick={this.props.syncADContacts}
								>
									{contacts.adContactSyncLoading ? <CircularProgress size={20}/> : false} Sync Geo Contacts
								</Button>
							</Tooltip>
							<Button
								className={classes.admin}
								size="small"
								color="secondary"
								variant="contained"
								onClick={this.handleClose}
							>
								Close
							</Button>
							<ContactDialog
								className={classes.addContactButton}
								buttonTitle="Add New Contact to All Contacts"
								screenProps={{contacts:contacts.allContacts}}
							/>
						</Grid>
					</Grid>
		        </Dialog>
	      	</div>
	    );
  	}
}

const MultiSelect = ({value, onClose, items}) => {
	// console.log('value', value)
	// console.log('items', items)
	const [selected, setSelected] = useState(value);
	const [open, setOpen] = useState(false)

	function handleChange(event){

		// console.log('event.target.value', event.target.value)
		setSelected(event.target.value);
	}

	function handleClose(e){
		setOpen(!open)
		onClose(selected)
	}

	return (
		<Select
			multiple
			value={selected}
			open={open}
			onOpen={() => setOpen(!open)}
			onClose={handleClose}
			onChange={handleChange}
			input={<Input id="select-multiple"/>}
			renderValue={() => `${selected.length} / ${items.length}`}
			// MenuProps={MenuProps}
		>
			{items.map(item => (
				<MenuItem key={item.name} value={item.id}>
					<Checkbox checked={selected.indexOf(item.id) > -1} />
					<ListItemText primary={item.name}/>
				</MenuItem>
			))}
		</Select>
	)
}

const ContactsManagement = connect(
	mapStateToProps,
	mapDispatchToProps
)(ContactsManagementModal)

export default withStyles(styles)(ContactsManagement);
