import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ViewListIcon from '@material-ui/icons/ViewList'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ExitToApp from '@material-ui/icons/ExitToApp'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PeopleIcon from '@material-ui/icons/People'
import DescriptionIcon from '@material-ui/icons/Description'
import PhotoIcon from '@material-ui/icons/Photo'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
import { NavLink } from 'react-router-dom'
import InsertChartIcon from '@material-ui/icons/InsertChart'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { connect } from 'react-redux';
import { push } from 'react-router-redux'
import { logout, toggleUserAttributes } from '../../actions/user'
import config from '../../config'
import { red } from '@material-ui/core/colors'

const styles = theme => ({
	admin: {
		backgroundColor: theme.palette.admin.main,
		'&:hover': {
			backgroundColor: theme.palette.admin.dark
		}
	},
	menuVersion: {
		// display: 'inline-block',
		// float: 'right',
		// textAlign: 'right',
		fontSize: '0.6em',
		fontWeight: '100'

	},
	active: {
		backgroundColor: theme.palette.action.selected
	},
	colorSwitchBase: {
		color: red[500],
		'&$colorChecked': {
			color: red[700],
			'& + $colorBar': {
				backgroundColor: red[700]
			}
		}
	},
	colorBar: {},
	colorChecked: {}
})

const mapStateToProps = (state) => {
    // console.log('state', state)
    return {
		user: state.user,
		admin: state.user.admin,
		organization: state.user.userInfo.organizationName,
		showInactive: state.user.showInactive,
		showArchived: state.user.showArchived
	}
}

function ListItemLink(props) {
	const { primary, to, icon, classes } = props
	return (
		<ListItem button exact component={NavLink} to={to} activeClassName={classes.active}>
			<ListItemIcon>{icon}</ListItemIcon>
			<ListItemText primary={primary}/>
		</ListItem>
	)
}

const ListItemNavLink = withStyles(styles)(ListItemLink)

export function AppItems(props) {
	return (
		<div>
			<ListItemNavLink to="/" primary="All Projects" icon={<ViewListIcon/>}/>
			<ListItemNavLink to="/SiteDashboard" primary="Site Dashboard" icon={<DashboardIcon/>}/>
			<ListItemNavLink to="/ProgressOverview" primary="Progress" icon={<InsertChartIcon/>}/>
			<ListItemNavLink to="/Contacts" primary="Contacts" icon={<PeopleIcon/>}/>
			<ListItemNavLink to="/Documents" primary="Documents" icon={<DescriptionIcon/>}/>
			<ListItemNavLink to="/Photos" primary="Photos" icon={<PhotoIcon/>}/>
		</div>
	)
}

function AccountItemsList(props){
	// console.log('props', props)
	const {classes, user, admin, organization, showInactive, showArchived, toggleUserAttributes} = props;
	// console.log('showInactive', showInactive)
	// console.log('showArchived', showArchived)
	// const {userInfo} = props.user.userInfo
	// console.log('admin', admin)

	return (
		<div>
			<ListItem >
				<ListItemIcon>
					<AccountCircleIcon/>
				</ListItemIcon>

				<ListItemText
					primary={`${user.userInfo.firstName} ${user.userInfo.lastName}`}
					secondary={organization}
				/>
			</ListItem>
			{admin ?
			<ListItemNavLink to="/Admin" primary="Admin Settings" icon={<SettingsApplicationsIcon/>}/>
			: false}
	  		<ListItem button onClick={props.logout}>
	  			<ListItemIcon>
	  				<ExitToApp/>
	  			</ListItemIcon>
	  			<ListItemText primary={"Log Out"} />
	  		</ListItem>
			<ListItem>
				<FormControlLabel
					control={
						<Switch
							id="inactive"
							checked={showInactive}
							onChange={toggleUserAttributes('inactive')}
							value={showInactive}
						/>
					}
					label="Show Inactive"
				/>
			</ListItem>
			{admin ? <ListItem>
				<FormControlLabel
					control={
						<Switch
							classes={{
								switchBase: classes.colorSwitchBase,
								checked: classes.colorChecked,
								bar: classes.colorBar
							}}
							id="archived"
							checked={showArchived}
							onChange={toggleUserAttributes('archived')}
							value={showArchived}
						/>
					}
					label="Show Archived"
				/>
			</ListItem> : null }
			<ListItem>
				<Typography variant='h6' color="inherit" noWrap={true}>
					<small className={classes.menuVersion}> Version beta {config.version}</small>
				</Typography>
			</ListItem>
		</div>
	)
}

const mapDispatchToProps = (dispatch) => {
	return {
		logout: () => {
			dispatch(logout())
			dispatch(push('/login'))
		},
		toggleUserAttributes: name => event => {
			// console.log('event', name, event)
			dispatch(toggleUserAttributes(name))
		}
	}
}

export const AccountItems = withStyles(styles)(connect(
	mapStateToProps,
	mapDispatchToProps
)(AccountItemsList))

// export default withStyles(styles)(AccountItems);
