import HostUrl from '../../utils/hostUrl'
import {phaseDetailsEditStart, phaseDetailsEditSuccess} from './phase'

export const PHASE_UPDATE_EDIT_START = 'PHASE_UPDATE_EDIT_START'
export const PHASE_UPDATE_EDIT_SUCCESS = 'PHASE_UPDATE_EDIT_SUCCESS'
export const PHASE_UPDATE_DELETE_SUCCESS = 'PHASE_UPDATE_DELETE_SUCCESS'

let hostUrl = HostUrl();

/* --------- phase update actions ------- */

function phaseUpdateAddStart(item){
	return {
      	type: PHASE_UPDATE_EDIT_START,
	    item: item.phase,
  	};
}

function phaseUpdateAddSuccess(phase, update, site){
	return {
      	type: PHASE_UPDATE_EDIT_SUCCESS,
	    update,
		phase,
		site
  	};
}

export function addProgressToPhase(item){
	// console.log("item", JSON.stringify(item));
	// console.log('item', item)
	return function(dispatch, getState){
		let contactId = getState().user.userInfo.id;
		// console.log('contact id', contactId)
		dispatch(phaseUpdateAddStart(item))
		let postObject = {
			projectPhaseId: item.phase.id,
			feetSinceLastUpdate: item.feetSinceLastUpdate,
			shiftsSinceLastUpdate: item.shiftsSinceLastUpdate,
			percentSinceLastUpdate: item.percentSinceLastUpdate,
			date: item.selectedDate.toJSON(),
			comment: item.comment,
			contactId
		}
		// If this is editing an existing
		if (item.id) {
			postObject.id = item.id;
		}
		let token = getState().user.userInfo.token
		// let auth = { "authorization": token};
		return fetch(`${hostUrl}/EaProject/ProjectPhaseUpdates?authorization=${token}`,
			{
				"body": JSON.stringify(postObject),
				"headers": {
					'user-agent': 'ProjectDelivery',
					'content-type': 'application/json'
				},
				"method": 'POST'
			}
		)
		.then(response => response.json())
		.then(json => {
			if (json.name === 'EaError'){
				console.log('error', json)
			} else {
				fetch(`${hostUrl}/EaProject/ProjectPhaseUpdates/${json.id}?authorization=${token}`)
				.then(response => response.json())
				.then(json2 => {
					// console.log('json', json2)
					dispatch(phaseUpdateAddSuccess(item.phase, json2, item.site))
					item.cb()
				})
			}
		})
	}
}

export function deleteProgressUpdateFromPhase(item){
	// console.log("item", JSON.stringify(item));
	// console.log('delete item', item)
	// console.log('delete this update', item.update)
	// console.log('edit this phase', item.phase)
	return function(dispatch, getState){
		let token = getState().user.userInfo.token
		// console.log('state', getState())
		// let thisPhase = getState().phases.findWhere({id: update.projectPhaseId})
		// console.log('thisPhase', thisPhase)
		dispatch(phaseDetailsEditStart(item.phase, item.site))
		return fetch(`${hostUrl}/EaProject/ProjectPhaseUpdates/${item.update.id}?authorization=${token}`,
			{
				// "body": JSON.stringify(postObject),
				"headers": {
					'user-agent': 'ProjectDelivery',
					'content-type': 'application/json'
				},
				"method": 'DELETE'
			}
		)
		.then(response => response.json())
		.then(json => {
			if (json.name === 'EaError'){
				console.log('error', json)
			} else {
				item.phase.projectPhaseUpdates = item.phase.projectPhaseUpdates.filter(u => u !== item.update.uid)
				dispatch(phaseUpdateDeleteSuccess(item.update))
				dispatch(phaseDetailsEditSuccess(item.phase, item.site))
				item.cb()
			}
		})
	}
}

function phaseUpdateDeleteSuccess(update){
	return {
		type: PHASE_UPDATE_DELETE_SUCCESS,
		update
	}
}
