import {
	// ADD_PROGRESS_TO_PHASE,
	PHASE_UPDATE_EDIT_SUCCESS,
	PHASE_UPDATE_DELETE_SUCCESS,
	// PHASE_UPDATE_EDIT_DETAILS_SUCCESS
} from '../../actions/projectOverview';

import {
	// HYDRATE_PROJECTS_FROM_SERVER,
	// HYDRATE_SITES_FROM_SERVER,
	// HYDRATE_PHASES_FROM_SERVER,
	HYDRATE_UPDATES_FROM_SERVER,
} from '../../actions/dashboard'

import { LOGOUT } from '../../actions/user'

function phaseUpdateReducers(state = {}, action){
	// console.log('state', state)
	// console.log('action', action)
	switch (action.type) {
		case PHASE_UPDATE_DELETE_SUCCESS:
			let stateCopy = Object.assign({}, state)
			delete stateCopy[action.update.uid]
			return stateCopy
		case PHASE_UPDATE_EDIT_SUCCESS:
			// console.log('action', action)
			action.update.date = new Date(action.update.date)
			let a = {}
			a[action.update.uid] = action.update;
			return Object.assign({}, state, {
				...a
			})
		case HYDRATE_UPDATES_FROM_SERVER:
			// console.log('action', action)
			return Object.assign({}, state, {
				...action.payload
			})
		case LOGOUT:
			return state
		default:
			return state
	}
}

export default phaseUpdateReducers;
