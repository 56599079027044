import React, { useEffect, useState } from 'react'
import { Collapse, Divider, List, ListItem, ListItemIcon, ListItemText, Input, IconButton, Avatar } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import FolderIcon from '@material-ui/icons/Folder'
import CloseIcon from '@material-ui/icons/Close'
import FileIcon from '@material-ui/icons/InsertDriveFile'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { pdfjs, Document, Page } from 'react-pdf'

import sortBy from 'lodash/sortBy';

import HostUrl from './hostUrl'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AbortController = window.AbortController;

let hostUrl = HostUrl();

const styles = theme => ({
    loading: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        '& > * + *': {
          marginTop: theme.spacing.unit * 2,
        }
    },
	nested: {
        paddingLeft: theme.spacing.unit * 4
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
      }
});

const PDFPager = ({file, token}) => {
    // console.log('file', file)
    const [ numPages, setNumPages ] = useState(null);
    const [ pageNumber, setPageNumber ] = useState(1);

    const changePage = offset => setPageNumber(pageNumber + offset)
    return (
        <React.Fragment>
            <div style={{marginBottom: 10}}>
                <Button  size="small" disabled={pageNumber <= 1} variant="outlined" onClick={() => changePage(-1)}>Previous Page</Button>
                <Input style={{width: 40, marginLeft: 10, marginRight: 3}} label="Page" step={1} type="number" value={pageNumber} min={1} max={numPages} onChange={e => setPageNumber(parseInt(e.target.value, 10))}/>
                <Typography style={{marginRight: 10}} inline>{`of ${numPages}`}</Typography>
                <Button size="small" disabled={pageNumber >= numPages} variant="outlined" onClick={() => changePage(1)}>Next Page</Button>
            </div>
            <Document

                file={`${hostUrl}/EaSharepoint/Proxy?path=${file.path}&authorization=${token}`}
                onLoadSuccess={(success) => setNumPages(success.numPages)}
            >
                <Page pageNumber={pageNumber} />
            </Document>
        </React.Fragment>
    )
}

export const Preview = withStyles(styles)(({file, token, DialogButton, classes}) => {
    // console.log('file', file)
    const [ open, setOpen ] = useState(false);


    const download = () => {
        // const { file, token } = .props
		// let path = this.props.tile.path
		let url = `${hostUrl}/EaSharepoint/ProjectDocumentDownload?path=${file.path}&authorization=${token}`
		window.location.href = url
		return false
    }

    const toggleOpen = () => {
        setOpen(!open)
    }

    const getImagePreviewPath = (file) => {

		let pathArray = file.path.split('/')
		// console.log('pathArray', pathArray)
		let fileName = pathArray[pathArray.length - 1]
		// console.log('fileName', fileName)
		let lastDotIndex = fileName.lastIndexOf('.');
		let fileNameArray = [fileName.substring(0, lastDotIndex), fileName.substring(lastDotIndex + 1)]
		// console.log('fileNameArray', fileNameArray)
		let fileExtension = fileNameArray[fileNameArray.length -1]
		// console.log('fileExtension', fileExtension)
		let newNamePart = '_' + fileExtension.toLowerCase()
		fileNameArray.splice(-1, 1, newNamePart)
		let newFileName = fileNameArray.join('') + '.' + fileExtension
		// console.log('newFileName', newFileName)
		pathArray.splice(-1, 1, '_w', newFileName)
		// console.log('pathArray', pathArray)
		let newPath = pathArray.join('/')

		return `${hostUrl}/EaSharepoint/Proxy?path=${newPath}&authorization=${token}`
    }

    const getPreviewType = (file) => {
        let extension = file.path.substring(file.path.lastIndexOf('.')).toLowerCase() || null;
        // console.log('extension', extension)
        if(extension === '.pdf'){
            return <PDFPager file={file} token={token}/>
        } else if (extension === '.jpg' || extension === '.jpeg' || extension === '.png' || extension === '.gif'){
            return <img src={getImagePreviewPath(file)} alt={file.name} />
        } else {
            return <Typography>Could not preview this file type.</Typography>
        }
    }

    return (
        <div>
            <DialogButton onClick={toggleOpen}/>
            <Dialog
                maxWidth='lg'
                open={open}
                onClose={toggleOpen}
                // onEnter={fetchPreview}
            >
                <DialogTitle>
                    Previewing {file.name}
                    <IconButton className={classes.closeButton} aria-label="Close" onClick={toggleOpen}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2">{`Last modified: ${new Date(file.timeLastModified).toLocaleString()}`}</Typography>
                    {getPreviewType(file)}
                </DialogContent>
                <DialogActions>
                    <Button fullWidth onClick={download} color="primary" autoFocus="autoFocus">
                        Download File
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
})

function Folder({folder, token}){
    const [open, toggleOpen] = useState(false);
    return (
        <div>
            <ListItem button  onClick={() => toggleOpen(!open)}>
                <ListItemIcon>
                    {open ? <FolderOpenIcon/> : <FolderIcon/>}
                </ListItemIcon>
                <ListItemText
                    primary={folder.name}
                    secondary={`${folder.items.length} items`}
                />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse key={`${folder.name}-children`} in={open} timeout='auto'>
                {folder.items.length ? sortBy(folder.items, 'name').map((file, i) => {
                    // console.log('file.type', file)
                    return (
                        [
                            file.type === 'folder' ? <Folder key={file.name} folder={file}/> : <File key={file.name} token={token} file={file}/>,
                            <Divider key={`${file.name}-divider`}/>
                        ]
                    )
                }): <ListItem><ListItemText style={{paddingLeft: 40}} primary="No items"/></ListItem>}
            </Collapse>
            <Divider/>
        </div>
    )
}

// Was hoping to have this dynamically populate and be referenced,
// but something about my async code is screwy
// and these don't seem to exist during the if check in my getFileIconPath
// unless I put these common ones here first. :-/
// These few do save hundreds of network calls in the end, though
const fileExtensionPaths = {
    ".pdf": "https://projects.geoengineers.com/_layouts/15/images/pdf16.png",
    ".jpg": "https://projects.geoengineers.com/_layouts/15/images/icjpg.gif",
    ".csv": "https://projects.geoengineers.com/_layouts/15/images/icgen.gif",
    ".dxf": "https://projects.geoengineers.com/_layouts/15/images/icgen.gif",
    ".xlsm": "https://projects.geoengineers.com/_layouts/15/images/icxlsm.png",
    ".xlsx": "https://projects.geoengineers.com/_layouts/15/images/icxlsx.png",
    ".docx": "https://projects.geoengineers.com/_layouts/15/images/icdocx.png",
    ".zip": "https://projects.geoengineers.com/_layouts/15/images/iczip.gif"
  };

function File({file, token}){
    const [iconPath, setIconPath] = useState('');
    const [loaded, setLoaded] = useState(false);
    const controller = new AbortController();
    useEffect(() => {
        if (!iconPath){
            getFileIconPath(file, token, controller)
        }
        return () => controller.abort();
    }, [file]);

    const getFileIconPath = async (file, token) => {
        // console.log('file', file)
        try {
            let extension = file.name.substring(file.name.lastIndexOf('.'));
            if (fileExtensionPaths[extension]) {
                setIconPath(fileExtensionPaths[extension])
                setLoaded(true)
            } else {
                let url = `${hostUrl}/EaSharepoint/Proxy/?path=/_api/web/maptoicon(filename='file${extension}',progid='',size=${0})&authorization=${token}`
                const response = await fetch(url, {
                    signal: controller.signal,
                    method: 'GET',
                    headers: { "Accept": "application/json; odata=verbose" }
                })
                const data = await response.json()
                // console.log("data", data)
                // let path = `${hostUrl}/EaSharepoint/Proxy/?path=/_layouts/15/images/${data.d.MapToIcon}&authorization=${token}`
                let path = `https://projects.geoengineers.com/_layouts/15/images/${data.d.MapToIcon}`
                fileExtensionPaths[extension] = path;
                // console.log('fileExtensionPaths', fileExtensionPaths)
                // console.log('path', path)
                setIconPath(path)
                setLoaded(true)
            }  
        }
        catch (err) {
            if (controller.signal.aborted){
                // console.log('request has been gracefully cancelled')
            } else {
                throw err;
            }
        }
    }
    return (
        <Preview
            file={file}
            token={token}
            DialogButton={(props) => {
                return (
                    <ListItem {...props} key={file.name} button style={{paddingLeft:40}}>
                        <Avatar imgProps={{style: {objectFit: 'none'}}} src={iconPath} alt="?">
                            {!loaded ? <FileIcon/>: false}
                            {/* <img style={{paddingRight: 10}} src={iconPath} alt="?"></img> */}
                        </Avatar>
                        <ListItemText primary={file.name} secondary={file.fileSize}/>
                    </ListItem>
                )
            }}
        />
    )
}

function GenericFileBrowser({files, loading, classes, token}){
    const sortedFiles = sortBy(files, 'name')
    // console.log('sortedFiles', sortedFiles)
    // const classes = useStyles();
    // files = files.length ? files : [{type: "folder", name: "No contents", items: []}]
    // console.log('loading', loading)
    return (
        !loading ? 
        <List>
            <Divider key="nada divider"/>
            {sortedFiles.length ? sortedFiles.map((file, i) => {
                return file.type === 'folder' ? <Folder token={token} key={i} folder={file}/> : <File token={token} key={i} file={file}/>
            }): <ListItem key="nada"><ListItemText primary="This folder contains no items"/></ListItem>}
        </List> :
        <div className={classes.loading}>
            <CircularProgress color="secondary" />
            <Typography>Loading...</Typography>
        </div>
    )
}

export default withStyles(styles)(GenericFileBrowser);