import {
	// ADD_PHASE,
	// EDIT_PHASE_SETUP,
	// EXPAND_PHASE,
	PHASE_UPDATE_EDIT_START,
	PHASE_UPDATE_EDIT_SUCCESS,
	// PHASE_DETAILS_EDIT_START,
	PHASE_DETAILS_EDIT_SUCCESS,
	PHASE_DELETE_SUCCESS
} from '../../actions/projectOverview';

import {
	// HYDRATE_PROJECTS_FROM_SERVER,
	// HYDRATE_SITES_FROM_SERVER,
	HYDRATE_PHASES_FROM_SERVER,
	// HYDRATE_UPDATES_FROM_SERVER,
} from '../../actions/dashboard'

import { LOGOUT } from '../../actions/user'

function phaseReducers(state = {}, action){
	// console.log('state', state)
	// console.log('action', action)
	switch (action.type) {
		case HYDRATE_PHASES_FROM_SERVER:
			// console.log('action', action)
			return Object.assign({}, state, {
				...action.payload
			})
		case PHASE_UPDATE_EDIT_START:
			let phaseUpdateStartReturnState = Object.assign({}, state)
			// phaseUpdateStartReturnState[action.item.uid].inProgress = true;
			return phaseUpdateStartReturnState;
		case PHASE_UPDATE_EDIT_SUCCESS:
			// check if this item already exists
			let index = action.phase.projectPhaseUpdates.indexOf(action.update.uid)
			if (index === -1){
				// if it doesn't, add it to the array
				action.phase.projectPhaseUpdates.push(action.update.uid)
			}
			// action.phase.inProgress = false;
			let phaseUpdateSuccessReturnState = Object.assign({}, state)
			phaseUpdateSuccessReturnState[action.phase.uid] = action.phase
			return phaseUpdateSuccessReturnState;
		// case PHASE_DETAILS_EDIT_START:
		// 	let phaseDetailEditStartReturnState = Object.assign({}, state)
		// 	phaseDetailEditStartReturnState[action.phase.uid].inProgress = true;
		// 	return phaseDetailEditStartReturnState;
		case PHASE_DETAILS_EDIT_SUCCESS:
			// console.log('action.phase', action.phase)
			action.phase.inProgress = false;
			let phaseDetailsEditSuccessReturnState = Object.assign({}, state)
			action.phase.projectPhaseUpdates = action.phase.projectPhaseUpdates ? action.phase.projectPhaseUpdates : [];
			let editedPhase = Object.assign({}, phaseDetailsEditSuccessReturnState[action.phase.uid], {...action.phase})
			phaseDetailsEditSuccessReturnState[action.phase.uid] = editedPhase;
			return phaseDetailsEditSuccessReturnState;
		// case ADD_PHASE:
		// 	return state;
		case PHASE_DELETE_SUCCESS:
			let stateCopy = Object.assign({}, state);
			delete stateCopy[action.phase.uid];
			return stateCopy;
		// case EDIT_PHASE_SETUP:
		// 	// console.log('action payload', action.item)
		// 	let returnState2 = Object.assign({}, state)
		// 	returnState2[action.item.uid] = action.item;
		// 	return returnState2;
		case LOGOUT:
			return state
		default:
			return state
	}
}

export default phaseReducers;
