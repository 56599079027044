// changes based on environment
import HostUrl from '../../utils/hostUrl'

import {setSelectedPhotoFolder, setSelectedDocumentFolder} from '../documents'

export const GET_SERVER_PROJECTS = 'GET_SERVER_PROJECTS'
export const HYDRATE_PROJECTS_FROM_SERVER = 'HYDRATE_PROJECTS_FROM_SERVER'
export const HYDRATE_SITES_FROM_SERVER = 'HYDRATE_SITES_FROM_SERVER'
export const HYDRATE_PHASES_FROM_SERVER = 'HYDRATE_PHASES_FROM_SERVER'
export const HYDRATE_UPDATES_FROM_SERVER = 'HYDRATE_UPDATES_FROM_SERVER'
export const FETCH_PROJECT_DOCUMENT_FOLDERS_SUCCESS = 'FETCH_PROJECT_DOCUMENT_FOLDERS_SUCCESS'
export const FETCH_PROJECT_PHOTO_FOLDERS_SUCCESS = 'FETCH_PROJECT_PHOTO_FOLDERS_SUCCESS'

let hostUrl = HostUrl();

function populateStore(json){
	return function(dispatch, getState){
		// console.log('thissssss', json)
		// Creating some objects for to update store
		let projects = {}
		let sites = {}
		let site_phases = {}
		let site_phase_updates = {}
		// Iterating through server object if it has length

		// console.log("json", json);

		if (json.length) {
			json.forEach(p => {
				// console.log('p', p)
				// If projectSites has length, map it
				p.projectSites = p.projectSites ? p.projectSites.map(s => {
					// If projectPhases in projectSites has length, map it
					s.projectPhases = s.projectPhases ? s.projectPhases.map(ph => {
						// If projectPhaseUpdates has length, map it
						// If active flag is null, make it true. Future ph should have bool value.
						ph.active = ph.active === null ? true : ph.active
						ph.projectPhaseUpdates = ph.projectPhaseUpdates ? ph.projectPhaseUpdates.map(phu => {
							phu.date = new Date(phu.date)
							site_phase_updates[phu.uid] = phu;
							// return just the uid for our new array
							return phu.uid
						}) : [];
						site_phases[ph.uid] = ph;
						// return just the uid fro our new array
						return ph.uid;
					}) : [];
					// console.log("s", s);
					sites[s.uid] = s;
					// ... return just the uid for our new array
					return s.uid;
				}) : [];
				// p.projectSites = undefined;
				projects[p.uid] = p;
			})
		}
		dispatch(populatePhaseUpdates(site_phase_updates))
		dispatch(populatePhases(site_phases))
		dispatch(populateSites({sites, projects}))
		dispatch(populateProjects(projects))
		dispatch(setDefaultSelectedFolders())
		// console.log('getState()', getState())
	}
}

function populateProjects(payload){
	return {
		type: HYDRATE_PROJECTS_FROM_SERVER,
		payload
	}
}

function populateSites(payload){
	return function(dispatch, getState){
		// console.log('getState', getState())
		// let selectedSiteId = getState().user.selectedSiteId
		// console.log('selectedSiteId', selectedSiteId)
		dispatch({
			type: HYDRATE_SITES_FROM_SERVER,
			payload
		})

	}
}

function setDefaultSelectedFolders(payload){
	return function(dispatch, getState){
		// console.log('getState()', getState())
		let selectedSite = getState().sites[getState().user.selectedSiteId]
		// console.log('selectedSite', selectedSite)
		dispatch(setSelectedPhotoFolder({selectedFolder: selectedSite && selectedSite.photosFolderPath ? selectedSite.photosFolderPath : null}))
		dispatch(setSelectedDocumentFolder({selectedFolder: selectedSite && selectedSite.documentsFolderPath ? selectedSite.documentsFolderPath : null}))
	}
}

function populatePhases(payload){
	// console.log('requesting login for ', creds)
	return {
		type: HYDRATE_PHASES_FROM_SERVER,
		payload
	}
}

function populatePhaseUpdates(payload){
	// console.log('requesting login for ', creds)
	// console.log('phase updates', item )
	return {
		type: HYDRATE_UPDATES_FROM_SERVER,
		payload
	}
}

export function fetchProjects(){
	// console.log('fetching projects from server');
	return function(dispatch, getState){
		let token = getState().user.userInfo.token
		let endpoint = getState().user.admin ? 'AppProjectDelivery/AdminProjectComposite' : 'EaProject/ProjectComposite'
		// let endpoint = 'AppProjectDelivery/AdminProjectComposite'
		// let auth = { "authorization": token};
		return fetch(`${hostUrl}/${endpoint}?authorization=${token}`)
			.then(response => response.json())
			.then(json => {
				// console.log('json', json)
				if (json.name === 'EaError'){
					// dispatch error action
				} else {
					dispatch(populateStore(json))
					if (getState().user.admin){
						dispatch(fetchProjectDocumentFolders())
					}
				}
			})
	}
}

// this fetches lists of folders to populate selects in admin setup
export function fetchProjectDocumentFolders(){
	return function(dispatch, getState){
		let token = getState().user.userInfo.token
		// let selectedProjectId = getState().user.selectedProjectId
		// console.log('getState', getState())
		// let project = getState().projects[selectedProjectId]
		// console.log('project', project)
		let projectNumber = getState().projects[getState().user.selectedProjectId] ? getState().projects[getState().user.selectedProjectId].projectNumber : null
		if (projectNumber){
			// console.log('blah')
			return fetch(`${hostUrl}/EaSharepoint/Documents/${projectNumber}?folder=Field Reports&authorization=${token}`)
				.then(response => response.json())
				.then(json => {
					// console.log('docs json', json)
					if (json.name === 'EaError'){
						// dispatch error action
					} else {
						// console.log('json', json)
						dispatch(fetchProjectDocumentFoldersSuccess(json))
						dispatch(fetchProjectPhotoFolders())
						// dispatch(fetchDocumentsSucceed({projectId: payload.projectNumber, folder: payload.folder, json: json}))
					}
				})
				.catch(error => {
					console.log('error: ', error)
					// dispatch(fetchSharepointFailed())
				})
		}

	}
}

function fetchProjectDocumentFoldersSuccess(payload){
	return {
		type: FETCH_PROJECT_DOCUMENT_FOLDERS_SUCCESS,
		payload
	}
}

export function fetchProjectPhotoFolders(){
	return function(dispatch, getState){
		let token = getState().user.userInfo.token
		// let selectedProjectId = getState().user.selectedProjectId
		// console.log('getState', getState())
		// let project = getState().projects[selectedProjectId]
		// console.log('project', project)
		let projectNumber = getState().projects[getState().user.selectedProjectId].projectNumber
		if (projectNumber){
			// console.log('blah')
			return fetch(`${hostUrl}/EaSharepoint/Documents/${projectNumber}?folder=Project Photos&authorization=${token}`)
				.then(response => response.json())
				.then(json => {
					// console.log('docs json', json)
					if (json.name === 'EaError'){
						// dispatch error action
					} else {
						// console.log('json', json)
						dispatch(fetchProjectPhotoFoldersSuccess(json))
						// dispatch(fetchDocumentsSucceed({projectId: payload.projectNumber, folder: payload.folder, json: json}))
					}
				})
				.catch(error => {
					console.log('error: ', error)
					// dispatch(fetchSharepointFailed())
				})
		}

	}
}

function fetchProjectPhotoFoldersSuccess(payload){
	return {
		type: FETCH_PROJECT_PHOTO_FOLDERS_SUCCESS,
		payload
	}
}
