import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux'
import { withStyles } from '@material-ui/core/styles'
import {logout} from '../../actions/user'
import Grid from '@material-ui/core/Grid'

import ProgressOverview from '../ProgressOverview'
import Contacts from '../Contacts'
import Documents from '../Documents'
import Photos from '../Photos'

const styles = theme => ({
	paper: {
    	padding: theme.spacing.unit * 2,
		// marginTop: 20,
    	// textAlign: 'center',
    	color: theme.palette.text.secondary
    },
	appFrame: {
		// padding: 20,
		// height: '100%'
	}
})

const mapStateToProps = (state) => {
	let projectArray = [];
	for (var key in state.projects){
		if (key !== 'selectedProjectId'){
			projectArray.push(state.projects[key])
		}
	}
	// console.log('projectArray', projectArray)
	return {
		user: state.user,
		projects: projectArray,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		logout: () => {
			dispatch(logout())
			dispatch(push('/login'))
		},

	}
}

const Dashboard = ({classes}) => {
	return (
		<div className={classes.appFrame}>
			<Grid container justify={'space-between'} spacing={16}>
				<Grid item xs={12} md={12}>
					<ProgressOverview/>
				</Grid>
				<Grid item xs={12} md={4}>
					<Contacts widget={true}/>
				</Grid>
				<Grid item xs={12} md={4}>
					<Documents widget={true}/>
				</Grid>
				<Grid item xs={12} md={4}>
					<Photos widget={true}/>
				</Grid>
			</Grid>
		</div>
	)
}

const DashboardWrapper = connect(
	mapStateToProps,
	mapDispatchToProps
)(Dashboard)

export default withStyles(styles)(DashboardWrapper)
