// import rest from '../../utils/rest';
import HostUrl from '../../utils/hostUrl'
import unionBy from 'lodash/unionBy';
import filter from 'lodash/filter';
import without from 'lodash/without';

// export const ADD_CONTACT = 'ADD_CONTACT'
export const UPDATE_CONTACT_START = 'UPDATE_CONTACT_START'
// export const UPDATE_CONTACT_SUCCEEDED = 'UPDATE_CONTACT_SUCCEEDED'
// export const REMOVE_CONTACT_START = 'REMOVE_CONTACT_START'
// export const REMOVE_CONTACT_START = 'REMOVE_CONTACT_START'
export const ASSOCIATE_CONTACT_WITH_PROJECT = 'ASSOCIATE_CONTACT_WITH_PROJECT'
export const DISASSOCIATE_CONTACT_WITH_PROJECT = 'DISASSOCIATE_CONTACT_WITH_PROJECT'
export const ASSOCIATE_CONTACT_WITH_SITE = 'ASSOCIATE_CONTACT_WITH_SITE'
export const DISASSOCIATE_CONTACT_WITH_SITE = 'DISASSOCIATE_CONTACT_WITH_SITE'
export const USER_CONTACTS_FETCH_STARTED = 'USER_CONTACTS_FETCH_STARTED'
export const USER_CONTACTS_FETCH_SUCCEEDED = 'USER_CONTACTS_FETCH_SUCCEEDED'
export const ALL_CONTACTS_FETCH_STARTED = 'ALL_CONTACTS_FETCH_STARTED'
export const ALL_CONTACTS_FETCH_SUCCEEDED = 'ALL_CONTACTS_FETCH_SUCCEEDED'
export const SYNC_AD_CONTACTS_STARTED = 'SYNC_AD_CONTACTS_STARTED'
export const SYNC_AD_CONTACTS_SUCCEEDED = 'SYNC_AD_CONTACTS_SUCCEEDED'
export const ADD_CONTACT_TO_PROJECT_START = 'ADD_CONTACT_TO_PROJECT_START'
export const ADD_CONTACT_TO_PROJECT_SUCCEEDED = 'ADD_CONTACT_TO_PROJECT_SUCCEEDED'
export const REMOVE_CONTACT_FROM_PROJECT_START = 'REMOVE_CONTACT_FROM_PROJECT_START'
export const FETCH_ORGANIZATION_LIST_SUCCEEDED = 'FETCH_ORGANIZATION_LIST_SUCCEEDED'

let hostUrl = HostUrl();



export function updateContact(payload){
	// console.log('payload', payload)
	return function(dispatch, getState){
		dispatch(updateContactStart())
		let postObject = payload.contact;
		// console.log('postObject', postObject)
		if (!postObject.id) {
			delete postObject.id
		}
		let token = getState().user.userInfo.token
		return fetch(`${hostUrl}/AppProjectDelivery/AdminContact?authorization=${token}`,
			{
				"body": JSON.stringify(postObject),
				"headers": {
					'user-agent': 'ProjectDelivery',
					'content-type': 'application/json'
				},
				"method": 'POST'
			}
		)
		.then(response => response.json())
		.then(json => {
			// console.log('json', json)
			// console.log('contacts json', json)
			if (json.name === 'EaError'){
				// dispatch error action
			} else {
				// fetch action, if successful:
				dispatch(fetchAllContacts())
				payload.cb()
			}
		})
		.catch(error => console.log('error: ', error))


	}
}

export function deleteContact(payload){
	// console.log('delete contact payload', payload)
	return function(dispatch, getState){
		dispatch(updateContactStart())
		let id = payload.contact.id;
		let token = getState().user.userInfo.token;
		return fetch(`${hostUrl}/AppProjectDelivery/AdminContact/${id}?authorization=${token}`,
			{
				// "body": JSON.stringify(postObject),
				"headers": {
					'user-agent': 'ProjectDelivery',
					'content-type': 'application/json'
				},
				"method": 'DELETE'
			}
		)
		.then(response => response.json())
		.then(json => {
			// console.log('contacts json', json)
			if (json.name === 'EaError'){
				// dispatch error action
			} else {
				dispatch(fetchAllContacts())
				payload.cb()
			}
		})
		.catch(error => console.log('error: ', error))
	}
}

function updateContactStart(payload){
	// console.log('requesting login for ', creds)
	return {
		type: UPDATE_CONTACT_START
	}
}

// function updateContactSucceeded(payload){
// 	// console.log('requesting login for ', creds)
// 	return {
// 		type: UPDATE_CONTACT_SUCCEEDED,
// 		payload
// 	}
// }

export function fetchUserContacts(){
	return function(dispatch, getState){
		// let state = getState()
		// console.log('state', state)
		// let projectId = state.user.selectedProjectId ? state.projects[state.user.selectedProjectId].id : null
		dispatch(userContactsFetchStarted())
		let token = getState().user.userInfo.token
		// let auth = { "authorization": token};
		return fetch(`${hostUrl}/EaProject/ProjectSiteContactComposite?authorization=${token}`)
			.then(response => response.json())
			.then(json => {
				// console.log('contacts json', json)
				if (json.name === 'EaError'){
					// dispatch error action
				} else {
					dispatch(userContactsFetchSucceeded(json))
				}
			})
			.catch(error => console.log('error: ', error))

	}
}

function userContactsFetchStarted(){
	return {
		type: USER_CONTACTS_FETCH_STARTED,
	}
}

function userContactsFetchSucceeded(payload){
	// console.log('payload', payload)
	return {
		type: USER_CONTACTS_FETCH_SUCCEEDED,
		payload
	}
}

export function fetchAllContacts(){
	return function(dispatch, getState){
		// let state = getState()
		// console.log('state', state)
		// let projectId = state.user.selectedProjectId ? state.projects[state.user.selectedProjectId].id : null
		dispatch(allContactsFetchStarted())
		let token = getState().user.userInfo.token
		// let auth = { "authorization": token};
		return fetch(`${hostUrl}/AppProjectDelivery/AdminContact?authorization=${token}`)
			.then(response => response.json())
			.then(json => {
				// console.log('contacts json', json)
				if (json.name === 'EaError'){
					// dispatch error action
				} else {
					dispatch(allContactsFetchSucceeded(json))
				}
			})
			.catch(error => console.log('error: ', error))
	}
}

function allContactsFetchStarted(){
	return {
		type: ALL_CONTACTS_FETCH_STARTED,
	}
}

function allContactsFetchSucceeded(payload){
	// console.log('fetch succeeded')
	return {
		type: ALL_CONTACTS_FETCH_SUCCEEDED,
		payload
	}
}

export function syncADContacts(){
	return function(dispatch, getState){
		dispatch(syncADContactsStarted())
		let token = getState().user.userInfo.token
		// let auth = { "authorization": token};
		return fetch(`${hostUrl}/EaLogin/AzureUser?authorization=${token}`)
			.then(response => response.json())
			.then(json => {
				// console.log('contacts json', json)
				if (json.name === 'EaError'){
					// dispatch error action
				} else {
					dispatch(syncADContactsSucceeded(json))
				}
			})
			.catch(error => console.log('error: ', error))
	}
}

function syncADContactsStarted(){
	// console.log('started AD contacts fetch')
	return {
		type: SYNC_AD_CONTACTS_STARTED,
	}
}

function groomADContacts(contacts){
	// console.log('contacts', contacts)
	return contacts.map(c =>{
		// let { givenName, lastName, phoneNumber, email, jobTitle, inactive } = c
		return {
			organizationId: 1,
			uid: c.id,
			firstName: c.givenName,
			lastName: c.surname,
			phoneNumber: c.businessPhones[0],
			email: c.mail,
			jobTitle: c.jobTitle,
		}
	})
}

function syncADContactsSucceeded(payload){
	// console.log('succeeded AD contacts fetch', payload)
	// need to sync these to the database, then fetch users again
	return function(dispatch, getState){
		let currentContacts = getState().contacts.allContacts;
		let groomedAdContacts = groomADContacts(payload);
		let mergedContacts = unionBy(currentContacts, groomedAdContacts, 'email')
		console.log('mergedContacts', mergedContacts)
		let status = (r) => {
			if (r.ok) {
				return Promise.resolve(r)
			} else {
				return Promise.reject(new Error(r.statusText))
			}
		}

		let json = (r) => r.json();
		let token = getState().user.userInfo.token
		let promises = mergedContacts.map(contact => {
			return fetch(`${hostUrl}/AppProjectDelivery/AdminContact?authorization=${token}`,
				{
					"body": JSON.stringify(contact),
					"headers": {
						'user-agent': 'ProjectDelivery',
						'content-type': 'application/json'
					},
					"method": 'POST'
				}
			)
			.then(status)
			.then(json)
			.then(d => Promise.resolve(d))
			.catch(e => Promise.reject(new Error(e)));
		});

		Promise.all(promises).then(d => {
			console.log('d', d)
			dispatch(fetchAllContacts())
		}).catch(e => {
			console.log('Whoops, something went wrong while syncing AD contacts', e)
			dispatch(fetchAllContacts())
		})
	}
}

export function changeContactSiteAssociation(siteIdArray, contactId){
	return function(dispatch, getState){
		// console.log('getState', getState())
		// console.log('siteIdArray', siteIdArray);
		// console.log('contactId', contactId)
		// find project_contacts where id === contactId;
		let projectId = getState().projects[getState().user.selectedProjectId].id;
		let thisContactAssociations = filter(getState().contacts.userContacts, { contactId });
		// console.log("thisContactAssociations", thisContactAssociations);
		
		let existingSitesIdArray = thisContactAssociations.map(ca => ca.siteId);
		// console.log("existingSitesIdArray", existingSitesIdArray);
		let toAdd = without(siteIdArray, ...existingSitesIdArray);
		let toDelete = without(existingSitesIdArray, ...siteIdArray)

		console.log('toAdd', toAdd);
		console.log('toDelete', toDelete);

		toDelete.forEach(id => dispatch(removeContactFromProjectSite(thisContactAssociations.find(tca => tca.siteId === id).id)));

		toAdd.forEach(id => dispatch(addContactToProjectSite(contactId, projectId, id)))
	}
}

export function addContactToProjectSite(contactId, projectId, siteId){
	return function(dispatch, getState){
		dispatch(addContactToProjectStart())
		let token = getState().user.userInfo.token;
		
		let postObject = {
			projectId: projectId,
			contactId: contactId,
			// projectContactTypeId: 1,
			siteId: siteId
		}

		return fetch(`${hostUrl}/EaProject/ProjectSiteContact?authorization=${token}`,
			{
				"body": JSON.stringify(postObject),
				"headers": {
					'user-agent': 'ProjectDelivery',
					'content-type': 'application/json'
				},
				"method": 'POST'
			}
		)
		.then(response => response.json())
		.then(json => {
			// console.log('json', json)
			dispatch(fetchUserContacts())
		})
		.catch(error => console.log('error', error))
	}
}

export function addContactToAllProjectSites(contact){
	// console.log('contact', contact)
	// console.log('contactUpdate', contactUpdate)
	return function(dispatch, getState){
		dispatch(addContactToProjectStart())
		let token = getState().user.userInfo.token;
		let project = getState().projects[getState().user.selectedProjectId];
		let sites = getState().sites;
		
		// get list of project sites &
		// create array of post fetches for each site
		let fetchArray = project ? project.projectSites.map(s => {
			let postObject = {
				projectId: project.id,
				contactId: contact[5],
				// projectContactTypeId: 1,
				siteId: sites[s].id
			}
			return fetch(`${hostUrl}/EaProject/ProjectSiteContact?authorization=${token}`,
				{
					"body": JSON.stringify(postObject),
					"headers": {
						'user-agent': 'ProjectDelivery',
						'content-type': 'application/json'
					},
					"method": 'POST'
				}
			);
		}) : [];
		
		// Post all the project contacts.
		// Users are automatically assigned to all sites
		return Promise.all(fetchArray)
			.then(response => {
				let jsons = response.map(r => r.json())
				return Promise.all(jsons)
			})
			.then(responses => {
				console.log('all project contact responses', responses)
				dispatch(fetchUserContacts())
			})
			.catch(error => console.log('error: ', error))
	}
}

function addContactToProjectStart(){
	return {
		type: ADD_CONTACT_TO_PROJECT_START
	}
}

export function fetchOrganizationList(){
	return function(dispatch, getState){
		dispatch(userContactsFetchStarted())
		let token = getState().user.userInfo.token;
		return fetch(`${hostUrl}/EaLogin/SuperOrganization?authorization=${token}`)
			.then(response => response.json())
			.then(json => {
				// console.log('contacts json', json)
				if (json.name === 'EaError'){
					// dispatch error action
				} else {
					dispatch(fetchOrganizationListSucceeded(json))
				}
			})
			.catch(error => console.log('error: ', error))
	}
}

function fetchOrganizationListSucceeded(payload){
	return {
		type: FETCH_ORGANIZATION_LIST_SUCCEEDED,
		payload
	}
}

export function editOrganization(payload){
	return function(dispatch, getState){
		dispatch(updateContactStart())
		let postObject = payload.updateObject;
		// console.log('postObject', postObject)
		if (!postObject.id) {
			delete postObject.id
		}
		let editUrl = `${hostUrl}/AppProjectDelivery/AdminOrganization`;
		if (postObject.id) {
			editUrl += "/"+postObject.id;
		}
		let token = getState().user.userInfo.token
		return fetch(editUrl + `?authorization=${token}`,
			{
				"body": JSON.stringify(postObject),
				"headers": {
					'user-agent': 'ProjectDelivery',
					'content-type': 'application/json'
				},
				"method": (postObject.id ? 'PUT' : 'POST')
			}
		)
		.then(response => response.json())
		.then(json => {
			// console.log('json', json)
			// console.log('contacts json', json)
			if (json.name === 'EaError'){
				// dispatch error action
			} else {
				// fetch action, if successful:
				payload.cb()
				dispatch(fetchOrganizationList())
			}
		})
		.catch(error => console.log('error: ', error))


	}
}

export function removeContactFromAllProjectSites(contactId){
	// console.log('contactId', contactId)
	return function(dispatch, getState){
		dispatch(removeContactFromProjectStart());
		let token = getState().user.userInfo.token;
		let thisContactAssociations = filter(getState().contacts.userContacts, { contactId });
		let fetchArray = thisContactAssociations.map(s => {
			return fetch(`${hostUrl}/EaProject/ProjectSiteContact/${s.id}?authorization=${token}`,
				{
					"headers": {
						'user-agent': 'ProjectDelivery',
						'content-type': 'application/json'
					},
					"method": 'DELETE'
				}
			)
		})
		
		return Promise.all(fetchArray)
			.then(response => {
				let jsons = response.map(r => r.json())
				return Promise.all(jsons)
			})
			.then(responses => {
				console.log('all project contact delete responses', responses)
				dispatch(fetchUserContacts())
			})
			.catch(error => console.log('error: ', error))
	}
}

function removeContactFromProjectSite(id){
	// console.log('id', id)
	return function(dispatch, getState){
		dispatch(removeContactFromProjectStart())
		// console.log('id', id)
		let token = getState().user.userInfo.token;
		return fetch(`${hostUrl}/EaProject/ProjectSiteContact/${id}?authorization=${token}`,
			{
				"headers": {
					'user-agent': 'ProjectDelivery',
					'content-type': 'application/json'
				},
				"method": 'DELETE'
			}
		)
		// .then(response => response.json())
		.then(json => {

			if (json.name === 'EaError'){
				// console.log('contacts json', json)
				// dispatch error action
			} else {
				dispatch(fetchUserContacts())
			}
		})
		.catch(error => console.log('error: ', error))
	}
}

function removeContactFromProjectStart(){
	return {
		type: REMOVE_CONTACT_FROM_PROJECT_START
	}
}
