import {
	PHASE_DETAILS_EDIT_START,
	PHASE_DETAILS_EDIT_SUCCESS,
	SITE_DETAILS_EDIT_SUCCESS,
	SITE_DELETE_SUCCESS
} from '../../actions/projectOverview';

import {
	HYDRATE_SITES_FROM_SERVER,
} from '../../actions/dashboard'

import { LOGOUT } from '../../actions/user'

function siteReducers(state = {}, action){
	// console.log('state', state)
	// console.log('action', action)
	switch (action.type) {
		case PHASE_DETAILS_EDIT_START:
			// console.log('action', action)
			// console.log('state', state)
			let phaseDetailEditStartReturnState = Object.assign({}, state)
			phaseDetailEditStartReturnState[action.site.uid].inProgress = true;
			return phaseDetailEditStartReturnState;
		case PHASE_DETAILS_EDIT_SUCCESS:
			// console.log('action', action)
			action.site.inProgress = false;
			let index = action.site.projectPhases.indexOf(action.phase.uid)
			if (index === -1){
				// if it doesn't, add it to the array
				action.site.projectPhases.push(action.phase.uid)
			}
			return Object.assign({}, state, {
				[action.site.uid]: action.site
			});
		case SITE_DETAILS_EDIT_SUCCESS:
			// console.log('action', action)
			action.site.projectPhases = action.site.projectPhases ? action.site.projectPhases : [];
			return Object.assign({}, state, {
				[action.site.uid]: action.site,
				// selectedSiteId: action.site.uid
			})
		case SITE_DELETE_SUCCESS:
			let stateCopy = Object.assign({}, state);
			delete stateCopy[action.site.uid];
			stateCopy.selectedSiteId = Object.keys(stateCopy)[0];
			return stateCopy;
		case HYDRATE_SITES_FROM_SERVER:
			// console.log('action', action)
			return Object.assign({}, state, {
				...action.payload.sites
			})
		case LOGOUT:
			return state
		default:
			return state
	}
}

export default siteReducers;
