import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import hostUrl from '../../utils/hostUrl'
import logo from '../../geo_logo.svg'
let hosturl = hostUrl();

const styles = theme => ({
	// root: {
	// 	flexGrow: 1,
	// 	borderColor: "#DDD",
	// 	borderWidth: "1px",
	// 	backgroundColor: theme.palette.background.paper,
	// 	padding: "10px",
	// },
	paper: {
    	padding: theme.spacing.unit * 2,
		// marginTop: 20,
    	// textAlign: 'center',
    	color: theme.palette.text.secondary
    },
	appFrame: {
		padding: 20,
		height: '100%'
	},
	alert: {
		color: 'red'
	}
});

class ActivateAccount extends React.Component {
	state = {
		value: 0,
	};

	constructor(props){
		super(props);
		//var selectedContentTemplate =
		//this.authString !== undefined && this.authString !== null ?
			//ActivateAccountFormTemplate : ActivateAccountRestrictedTemplate;

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
		this.setState({
			[event.target.id]: event.target.value
		})
	}

	activateAccount = (event) => {
		// console.log("GET DATA FROM FORM!", event, this);
		if(this.state.txtNewPassword1 !== this.state.txtNewPassword2){
			alert("The passwords you entered do not match");
			return false;
		}
		if(this.state.txtNewPassword1 && this.state.txtNewPassword1.indexOf(":") > -1){
			alert("The colon (:) character is not a valid character when creating passwords. Please use a different password that does not include a colon.");
			return false;
		}
		var regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
		if(!regex.test(this.state.txtNewPassword1)){
			alert("Passwords much contain at least one letter, one number, one special character, and have at least 8 characters.");
			return false;
		}

		//alert("PASSWORD RULES PASSED");

		let newPostObject = {
			token: this.props.match.params.id, //this.authString,
			password: this.state.txtNewPassword1
		}

		// console.log("hosturl", hosturl);

		fetch(`${hosturl}/EaLogin/ActivatePassword`,
			{
				"body": JSON.stringify(newPostObject),
				"headers": {
					'user-agent': 'ProjectDelivery',
					'content-type': 'application/json'
				},
				"method": 'POST'
			}
		)
		.then(response => response.json())
		.then(json => {
			console.log("json", json);
			if(json && json.name && json.name === "EaError"){
				alert(`There was an error activating your account. Please contact softwareengineeringsupport@geoengineers.com if there continues to be an issue. More info: ${JSON.stringify(json, null, '\t')}`);
			}else{
				alert("Password updated successfully, you will be directed to the login page now.");
				window.location.href = "/login";
			}
		})
		.catch(err => console.log('erroooor', err))
	}

	render() {
		const { classes } = this.props;
		// const { value } = this.state;

		return (

			<div className={classes.appFrame}>
				<Grid container justify={'center'} alignItems={'center'} spacing={16}>
					<Grid item xs={12} md={3}>
						<Paper className={classes.paper}>
							<img alt='GeoEngineers Logo' src={logo}/>
							<h3>Activate Account</h3>
							Welcome to the Project Delivery system. <span className={classes.alert}>Please enter a new password to continue. The password must contain at least one letter, one number, one special character, and have at least 8 characters.</span>
							{/* <label htmlFor="txtNewPassword1">New password</label> */}
							<TextField
								fullWidth
								margin='dense'
								onChange={this.handleChange}
								name='txtNewPassword1'
								id='txtNewPassword1'
								type="password"
								placeholder="New Password" />
							{/* <label htmlFor="txtNewPassword2">Re-type new password</label> */}
							<TextField
								fullWidth
								margin='dense'
								onChange={this.handleChange}
								name='txtNewPassword2'
								id='txtNewPassword2'
								type="password"
								placeholder="Repeat New Password" />
							<Button
								style={{marginTop: 10}}
								fullWidth
								variant='outlined'
								color="primary"
								id='btnReset'
								onClick={e => this.activateAccount(e)}
							>
								Activate account
							</Button>
						</Paper>
					</Grid>
				</Grid>
			</div>
		);
	}
}

ActivateAccount.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActivateAccount);
