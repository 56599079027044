import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
// import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
// import Divider from '@material-ui/core/Divider'
import ProjectDialog from '../ProgressOverview/ProjectDialog';
import ArchivedChip from '../../utils/ArchivedChip';

// import values from 'lodash/values'
import filter from 'lodash/filter'
import keyBy from 'lodash/keyBy'

import { setSelectedProjectAndSite } from '../../actions/user'
import { fetchOrganizationList } from '../../actions/contacts'
import { calcTotalProgress } from '../ProgressOverview/ProgressIndicator'

const styles = theme => ({
	paper: {
		// padding: theme.spacing.unit * 2,
		color: theme.palette.text.secondary
    },
	project: {
		// marginButton: 15,
	},
	site: {
		// marginBottom: 10,
	},
	phase: {
		paddingTop: 0,
		paddingBottom: 0
	},
	progress: {
		marginTop: 4.5,
		height: 11
	},
	setSiteButton: {
		float: "right"
	},
	bar: {
		transition: "transform .7s ease-in-out"
	},
	microButton: {
		padding: "2px 4px",
		minHeight: 20,
		lineHeight: 0.8,
		fontSize: "0.6em",
	}
})

const mapStateToProps = (state, ownProps) => {
	// console.log('state', state)
	let filters = {};
	if (!state.user.showInactive) { filters.active = true;}
	if (!state.user.showArchived) { filters.archived = false;}
	let filteredProjects = filter(state.projects, filters);
	let filteredSites = keyBy(filter(state.sites, filters), 'uid');
	return {
		admin: state.user.admin,
		projects: filteredProjects,
		sites: filteredSites,
		phases: state.user.showInactive ? state.phases : keyBy(filter(state.phases, ['active', true]), 'uid'),
		phaseUpdates: state.phaseUpdates
	}
}

const mapDispatchToProps = (dispatch, next) => {
	return {
		setSelectedProjectAndSite: payload => dispatch(setSelectedProjectAndSite(payload)),
		push: r => dispatch(push(r)),
		fetchOrgs: () => dispatch(fetchOrganizationList())
	}
}

class AllProjects extends React.Component {

	componentWillMount(){
		if (this.props.admin){
			this.props.fetchOrgs()
		}
	}

	navigateToSite(s){
		const { projects, setSelectedProjectAndSite, push } = this.props
		// console.log('navigate to this site', s)
		let payload = {
			project: projects.find(project => project.id === s.projectId).uid,
			site: s.uid
		}
		// console.log("payload", payload)
		setSelectedProjectAndSite(payload)
		push('/SiteDashboard')
	}

	getSites(project) {
		const {sites, classes} = this.props
		// console.log('project', project)
		return project.projectSites.length ? project.projectSites.sort((a,b) => a.name - b.name).map(site => {
			// console.log('site', site)
			let s = sites[site] ? sites[site] : null;
			// console.log('site', s)
			if (s) {
				return (
					<ListItem disableGutters key={s.uid}>
						<Grid container spacing={0}>
							<Grid item xs={12} zeroMinWidth>
								<Typography variant="h6">
									{s.name}
									<Button
										classes={{
											root: classes.microButton
										}}
										style={{marginLeft: 15}}
										variant="outlined"
										color="secondary"
										size="small"
										onClick={() => this.navigateToSite(s)}
									>
										Site Dashboard
									</Button>
									{s.archived ? <ArchivedChip small/> : null }
								</Typography>
								<List disablePadding>
									<ListItem className={classes.phase} disableGutters dense key={666}>
										<Grid container spacing={8} >
											<Grid item xs={12} md={2}>
												<Typography variant="body2">Overall site progress</Typography>
											</Grid>
											<Grid item xs={9} md={9}>
												<AnimatedLinearProgress
													color='secondary'
													progress={s.percentComplete}
													classes={classes}
												/>
											</Grid>
											<Grid item xs={3} md={1}>
												<Typography variant="body2">{`${Math.min(s.percentComplete, 100)}%`}</Typography>
											</Grid>
										</Grid>
									</ListItem>
									{this.getPhases(s)}
								</List>
							</Grid>
						</Grid>
					</ListItem>
				)
			} else {
				return (
					<ListItem key={666} disableGutters>
						<Typography color="textSecondary" variant="h6">
							No active sites
						</Typography>
					</ListItem>
				)
			}
		}) : (
			<ListItem key={666} disableGutters>
				<Typography color="textSecondary" variant="h6">
					No sites
				</Typography>
			</ListItem>
		)
	}

	getPhases(site){
		const {phases, classes} = this.props
		return site.projectPhases.map(id => phases[id]).sort((a,b) => {
			// console.log('a', a, 'b', b)
			return new Date(a.startedAt) - new Date(b.startedAt)
		}).map(phase => {
			// let p = phases[phase] ? phases[phase] : null;
			// console.log('phase', phase)
			if (phase) {
				let progress = this.getUpdatePercent(phase).totalProgress
				return (
					<ListItem className={classes.phase} disableGutters dense key={phase.uid}>
						<Grid container spacing={8} >
							<Grid item xs={12} md={2}>
								<Typography variant="body2">{phase.name}</Typography>
							</Grid>
							<Grid item xs={9} md={9}>
								<AnimatedLinearProgress
									progress={progress}
									classes={classes}
								/>
							</Grid>
							<Grid item xs={3} md={1}>
								<Typography variant="body2">{`${Math.min(progress, 100)}%`}</Typography>
							</Grid>
						</Grid>
					</ListItem>
				)
			} else {
				return false
			}
		})
	}

	getUpdatePercent(phase){
		// console.log('phase', phase)
		let updates = phase && phase.projectPhaseUpdates ? phase.projectPhaseUpdates.map(update => this.props.phaseUpdates[update]) : []
		// console.log('updates', updates)
		return calcTotalProgress(phase, updates)
	}

  	render() {
		const { classes, projects, admin } = this.props;
		// console.log('projects', projects.length)  
    	return (
			<Paper className={classes.paper}>
				<List>
					{projects.length ? projects.sort((a,b) => a.name - b.name).map((project, index, array) => {
						// console.log('project', project)
						return (
							<ListItem divider={index < array.length - 1 ? true: false} key={project.uid}>
								<Grid container className={classes.project} >
									<Grid item xs={12}>
										<Typography variant="h5">
											{project.name} 
											{project.archived ? <ArchivedChip/> : false }
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<List dense disablePadding>
											{this.getSites(project)}
										</List>
									</Grid>
								</Grid>
							</ListItem>
						)
					}) : <ListItem divider={admin ? true : false} ><ListItemText primary="No projects"/></ListItem>}
					{ admin ? 
						<ListItem key={666}>
							<ProjectDialog
								dialogButton={(props) => (
									<Button
										{...props}
										variant="outlined"
										color="secondary"
									>
										Add new project
									</Button>
								)}
							/>
						</ListItem> 
					: false }
				</List>
			</Paper>
		)
  	}
}

class AnimatedLinearProgress extends React.Component {
	state = {
		completed: 0
	}

	componentDidMount() {
		const { progress } = this.props
		this.timer = setTimeout(() => this.setState({completed: progress}), 100)
	}
	render() {
		const { completed } = this.state
		const { classes, color } = this.props
		// console.log('color', color)
		return (
			<LinearProgress
				color={color ? color : 'primary'}
				// color='secondary'
				className={classes.progress}
				variant="determinate"
				value={completed}
			/>
		)
	}

}

export default withStyles(styles)(connect(
	mapStateToProps,
	mapDispatchToProps
)(AllProjects));
