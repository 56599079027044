import HostUrl from '../../utils/hostUrl'
import {siteDetailsEditStart, siteDetailsEditSuccess} from './site'

export const PHASE_DETAILS_EDIT_START = 'PHASE_DETAILS_EDIT_START'
export const PHASE_DETAILS_EDIT_SUCCESS = 'PHASE_DETAILS_EDIT_SUCCESS'
export const PHASE_DELETE_SUCCESS = 'PHASE_DELETE_SUCCESS'

let hostUrl = HostUrl();

/* ------- phase actions --------*/


// export function addPhase(item){
// 	console.log('add phase:', item);
// 	return {
// 		type: ADD_PHASE,
// 		item
// 	}
// }

export function phaseDetailsEditStart(phase, site){
	// console.log('phase edit start', phase)
	// console.log('site', site)
	return {
      	type: PHASE_DETAILS_EDIT_START,
		phase,
		site
  	};
}

export function phaseDetailsEditSuccess(phase, site){
	// console.log('phase edit success', phase)
	// console.log('site', site)
	return {
      	type: PHASE_DETAILS_EDIT_SUCCESS,
		phase,
		site
  	};
}

function phaseDeleteSuccess(phase, site){
	return {
		type: PHASE_DELETE_SUCCESS,
		phase,
		site
	}
}

export function editPhaseDetails(item){
	return function(dispatch, getState){
		dispatch(phaseDetailsEditStart(item.updateObject, item.site))
		let postObject = {
			projectSiteId: item.site.id,
			feet: parseFloat(item.updateObject.feet),
			estimatedShifts: parseFloat(item.updateObject.estimatedShifts),
			startedAt: new Date(item.updateObject.startedAt).toJSON(),
			name: item.updateObject.name,
			diameter: parseFloat(item.updateObject.diameter),
			active: item.updateObject.active,
			type: item.updateObject.type
		}
		if (item.updateObject.id !== null){
			postObject.id = item.updateObject.id
		}
		let token = getState().user.userInfo.token
		// let auth = { "authorization": token};
		return fetch(`${hostUrl}/EaProject/ProjectPhase?authorization=${token}`,
			{
				"body": JSON.stringify(postObject),
				"headers": {
					'user-agent': 'ProjectDelivery',
					'content-type': 'application/json'
				},
				"method": 'POST'
			}
		)
		.then(response => response.json())
		.then(json => {
			// console.log('json', json)
			if (json.name === 'EaError'){
				console.log('error', json)
			} else {
				fetch(`${hostUrl}/EaProject/ProjectPhase/${json.id}?authorization=${token}`)
				.then(response => response.json())
				.then(json2 => {
					// console.log('json', json2)
					json2.projectPhaseUpdates = item.updateObject.projectPhaseUpdates ? item.updateObject.projectPhaseUpdates :[]
					dispatch(phaseDetailsEditSuccess(json2, item.site))
					item.cb()
				})
			}
		})
	}
}

export function deletePhase(item){
	return function(dispatch, getState){
		let token = getState().user.userInfo.token
		// console.log('state', getState())
		// let thisPhase = getState().phases.findWhere({id: update.projectPhaseId})
		// console.log('thisPhase', thisPhase)
		// console.log("item", item);
		dispatch(siteDetailsEditStart(item.site, item.project))
		return fetch(`${hostUrl}/EaProject/ProjectPhase/${item.phase.id}?authorization=${token}`,
			{
				// "body": JSON.stringify(postObject),
				"headers": {
					'user-agent': 'ProjectDelivery',
					'content-type': 'application/json'
				},
				"method": 'DELETE'
			}
		)
		.then(response => response.json())
		.then(json => {
			if (json.name === 'EaError'){
				console.log('error', json)
			} else {
				item.site.projectPhases = item.site.projectPhases.filter(u => u !== item.phase.uid)
				dispatch(phaseDeleteSuccess(item.phase, item.site))
				dispatch(siteDetailsEditSuccess(item.site, item.project))
				item.cb()
			}
		})
	}
}
