import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import Tooltip from '@material-ui/core/Tooltip'
import CircularProgress from '@material-ui/core/CircularProgress';
import DateTimePicker from 'material-ui-pickers/DateTimePicker';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { editPhaseDetails, deletePhase } from '../../actions/projectOverview';
import clone from 'lodash/clone';

const styles = theme => ({
	admin: {
		color: theme.palette.admin.main
	},
	typeFieldSet: {
		marginTop: 10
	},
	radioGroup: {
		display: 'flex',
		flexDirection: 'row',
		'& label': {
			flex: '0 0 auto'
		}
	}
})

const mapStateToProps = (state, ownProps) => {
	return {
		site: state.sites[state.user.selectedSiteId],
		project: state.projects[state.user.selectedProjectId]
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onEditPhaseDetails: (update) => dispatch(editPhaseDetails(update)),
		onDeletePhase: (update) => dispatch(deletePhase(update))
	}
}

class SetupForm extends React.Component {
	constructor(props){
		// console.log(props)
		super(props);
		this.state = {
			name: this.props.phase ? clone(this.props.phase.name) : "",
			diameter: this.props.phase ? clone(this.props.phase.diameter) : 0,
			startedAt: this.props.phase ? clone(this.props.phase.startedAt) : new Date(),
			feet: this.props.phase ? clone(this.props.phase.feet) : 0,
			projectPhaseUpdates: this.props.phase ? clone(this.props.phase.projectPhaseUpdates) : [],
			estimatedShifts: this.props.phase ? clone(this.props.phase.estimatedShifts) : 0,
			uid: this.props.phase ? clone(this.props.phase.uid) : null,
			id: this.props.phase ? clone(this.props.phase.id) : null,
			active: this.props.phase ? clone(this.props.phase.active) : true,
			type: this.props.phase ? clone(this.props.phase.type) : 'length',
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
		// this.handleSubmit = this.handleSubmit.bind(this);
		this.props.wrapperContext.setupFormContext = this;
	}

	handleChange(event, checked) {
		let newValue = checked ? checked : event.target.value
		// console.log('name', event.target.name, checked)
		this.setState({
			[event.target.name]: newValue
		})
	}

	handleDateChange(event){
		this.handleChange({target:{name: "startedAt", value: event}})
	}

	render() {
		const { classes, phase } = this.props;
		const typeLocked = phase && phase.projectPhaseUpdates.length ? true : false;
		const { name, diameter, startedAt, feet, estimatedShifts, active, type } = this.state;
		// console.log('type', type)
		return (
			<form id='setupForm'>
				<FormControl fullWidth component="fieldset">
					<TextField
						autoFocus
						name="name"
						margin="dense"
						id="name"
						label="Name"
						type="text"
						// validate={required}
						defaultValue={name}
						InputLabelProps={{shrink:true}}
						fullWidth
						onChange={this.handleChange}
					/>
					<DateTimePicker
						fullWidth
						value={startedAt}
						name="startedAt"
						label="Start date"
						onChange={this.handleDateChange}
					/>
				</FormControl>
				<FormControl disabled={typeLocked} className={classes.typeFieldSet} fullWidth component="fieldset">
					<FormLabel component="legend">Type <small>{typeLocked ? '(Phase type cannot be changed after updates have been added)' : ""}</small></FormLabel>
					<RadioGroup
						aria-label="Type"
						name="type"
						value={type}
						onChange={this.handleChange}
						className={classes.radioGroup}
					>
						<FormControlLabel value="length" control={<Radio/>} label="By Length"/>	
						<FormControlLabel value="percent" control={<Radio/>} label="By Percent"/>	
					</RadioGroup>
				</FormControl>
				{type === 'length' ? 
					<FormControl fullWidth component="fieldset">
						<TextField
							name="diameter"
							id="diameter"
							label="Diameter"
							type="number"
							// step="0.1"
							defaultValue={diameter}
							inputProps={{step: 0.01}}
							// validate={}
							fullWidth
							onChange={this.handleChange}
						/>
						<TextField
							name="feet"
							margin="dense"
							id="feet"
							label="Hole length"
							type="number"
							// validate={required}
							defaultValue={feet}
							fullWidth
							onChange={this.handleChange}
						/>

					</FormControl> 
				: null }
				<TextField
						name="estimatedShifts"
						margin="dense"
						id="estimatedShifts"
						label="Estimate shifts"
						type="number"
						// validate={required}
						defaultValue={estimatedShifts}
						fullWidth
						onChange={this.handleChange}
					/>
				<FormControlLabel
					control={
						<Switch
							checked={active === "true" || active === true ? true : false}
							name='active'
							onChange={this.handleChange}
							value={!active}
						/>
					}
					label="Active"
				/>
			</form>
		)
	}
}

class DialogWrapper extends React.Component {
    state = {
        open: false
    };

    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleDelete = (event) => {

		this.props.onDeletePhase({
			// update: this.props.update,
			phase: this.props.phase,
			site: this.props.site,
			project: this.props.project,
			cb: () => this.setState({ open: false })
		})
	}

    handleClose = () => {
        this.setState({open: false});
    };

    handleSave = () => {
		// console.log('this.props', this.props)
		var updateObject = this.setupFormContext.state;
		// console.log('updateObject', updateObject)
        this.props.onEditPhaseDetails({
            updateObject,
            site: this.props.site,
			cb: () => this.setState({open: false})
        })

    }

    render() {
		const { classes } = this.props
		let dialogTitle = this.props.phase ? "Edit phase details" : "Add phase";
		let phaseButton = this.props.newPhase ? (
			<Tooltip id="tooltip-setup" placement="top" title="Add Phase">
				<IconButton size="small" onClick={this.handleClickOpen}>
					<AddIcon className={classes.admin}/>
				</IconButton>
			</Tooltip>
		) : (
			<Tooltip id="tooltip-setup" placement="top" title="Phase Setup">
				<IconButton  size="small" onClick={this.handleClickOpen}>
					<EditIcon className={classes.admin}/>
				</IconButton>
			</Tooltip>
		)
        return (
			<div style={this.props.displayStyle}>
				{phaseButton}
	            <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
	                <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
	                <DialogContent>
	                    <SetupForm classes={classes} phase={this.props.phase} wrapperContext={this} />
	                </DialogContent>
	                <DialogActions>
	                    <Button onClick={this.handleDelete} disabled={!this.props.phase} color="primary">
			            	Delete
			            </Button>
	                    <Button onClick={this.handleClose} color="primary">
	                        Cancel
	                    </Button>
	                    <Button onClick={this.handleSave} form='setupForm' color="primary">
							{ this.props.site && this.props.site.inProgress ? <CircularProgress size={20} /> : false }
	                        Save
	                    </Button>
	                </DialogActions>
	            </Dialog>
	        </div>
		)
    }
}

const PhaseDialog = withStyles(styles)(connect(
	mapStateToProps,
	mapDispatchToProps
)(DialogWrapper))

export default PhaseDialog;
