// import clone from 'lodash/clone'

import {
	FETCH_SHAREPOINT_START,
	FETCH_SHAREPOINT_FAILED,
	FETCH_SHAREPOINT_SUCCEED,
	SET_SELECTED_DOCUMENT_FOLDER,
	SET_SELECTED_PHOTO_FOLDER,
} from '../../actions/documents'
// import uniq from 'lodash/uniq'
//
import {
	SET_SELECTED_SITE,
	// TOGGLE_SHOW_INACTIVE
} from '../../actions/user'

import {
	SITE_DETAILS_EDIT_SUCCESS
} from '../../actions/projectOverview/site'

import cloneDeep from 'lodash/cloneDeep'
import clone from 'lodash/clone'

const defaultState = {
	docsLoading: false,
	photosLoading: false,
	documents: {},
	selectedDocumentFolder: null,
	selectedPhotoFolder: null,
};
function manageDocs(state = defaultState, action){
	// console.log('state', state)
	// console.log('action', action)
	switch (action.type) {
		case SITE_DETAILS_EDIT_SUCCESS:
			// console.log('action', action)
			return Object.assign({}, state, {
				selectedDocumentFolder: action.site ? action.site.documentsFolderPath : null,
				selectedPhotoFolder: action.site ? action.site.photosFolderPath : null,
			})
		case SET_SELECTED_SITE:
			// console.log('action', action)
			// console.log('start state', state)
			// let returnObject = Object.assign({}, state, {
			return Object.assign({}, state, {
				selectedDocumentFolder: action.payload ? action.payload.documentsFolderPath : null,
				selectedPhotoFolder: action.payload ? action.payload.photosFolderPath : null
			})
			// console.log('return object', returnObject)
			// return returnObject;
		case SET_SELECTED_DOCUMENT_FOLDER:
			return Object.assign({}, state, {
				selectedDocumentFolder: action.payload && action.payload.selectedFolder ? action.payload.selectedFolder : null
			})
		case SET_SELECTED_PHOTO_FOLDER:
			return Object.assign({}, state, {
				selectedPhotoFolder: action.payload && action.payload.selectedFolder ? action.payload.selectedFolder : null
			})
		case FETCH_SHAREPOINT_START:
			// console.log("payload", action.payload)
			return Object.assign({}, state, {
				photosLoading: action.payload && action.payload.folder === state.selectedPhotoFolder ? true : state.photosLoading,
				docsLoading: action.payload && action.payload.folder === state.selectedDocumentFolder ? true : state.docsLoading
			});
		case FETCH_SHAREPOINT_FAILED:
			return Object.assign({}, state, {
				docsLoading: false,
				photosLoading: false
			});
		case FETCH_SHAREPOINT_SUCCEED:
			// console.log(action.payload)
			let theseDocs = [];
			function getFileSize(size) {
				var i = Math.floor( Math.log(size) / Math.log(1024) );
				return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
			};
			function populate(result){
				let returnCollection = [];
				result.Files.results.forEach(f => {
					returnCollection.push({
						name: f.Name,
						path: f.ServerRelativeUrl,
						timeLastModified: f.TimeLastModified,
						fileSize: getFileSize(f.Length)
					})
				})
				result.Folders.results.forEach(f => {
					if (f.Name !== "Forms" && f.Name !== '_w' && f.Name !== '_t'){
						theseDocs.push({
							name: f.Name,
							path: f.ServerRelativeUrl,
							type: 'folder',
							items: populate(f)
						})
					}
				})
				return returnCollection;
			}
			theseDocs.push(...populate(action.payload.json.body.d))
			// console.log('finals', finals)
			let docsCopy = cloneDeep(state.documents)
			// console.log('docsCopy', docsCopy)
			docsCopy[action.payload.folder] = theseDocs
			// console.log('docsCopy', docsCopy)
			let returnState = {
				documents: docsCopy
			}
			returnState.docsLoading = action.payload.folder === state.selectedDocumentFolder ? false : clone(state.docsLoading)
			returnState.photosLoading = action.payload.folder === state.selectedPhotoFolder ? false : clone(state.photosLoading)
			// console.log('action.payload.folder', action.payload.folder)
			// console.log('state.selectedDocumentFolder', state.selectedDocumentFolder)
			// console.log('state.selectedPhotoFolder', state.selectedPhotoFolder)
			// console.log('returnState', returnState)
			return Object.assign({}, state, returnState)
		// case TOGGLE_SHOW_INACTIVE:
		// 	return Object.assign({}, state, {
		// 		selectedDocumentFolder: action.payload.newDocumentFolder,
		// 		selectedPhotoFolder: action.payload.newPhotoFolder
		// 	})
		default:
			return state
	}
}

export default manageDocs;
