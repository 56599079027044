import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types';
import UpdateDialog from './UpdateDialog';
import PhaseDialog from './PhaseDialog';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import ProgressHistory from './ProgressHistory'

import round from 'lodash/round';

// Note fore git signing test

const styles = theme => ({
	// mng: {
	// 	float: 'right',
	// 	marginTop: -15
	// },
	progress: {
		marginTop: 4.5,
		height: 11
	},
	bar: {
		transition: "transform .7s ease-in-out"
	},
	progressDate: {
		// textAlign: 'right',
		paddingRight: 10
	},
	stats: {
		fontSize: '0.8em',
		'& > div': {
			marginBottom: 8
		}
	},
	red: {
		backgroundColor: 'red'
	},
	yellow: {
		backgroundColor: 'yellow',
	},
	green: {
		backgroundColor: 'green'
	},
	admin: {
		color: theme.palette.admin.main
	}
})

// This should really be integrated into the redux store per site
export function calcTotalProgress(phase, updates){
	// console.log('phase', phase)
	var i=0;
	// Account for two types of phases
	if (phase.type === 'length'){
		// Add up the total feet contained in updates
		let totalShifts = 0;
		let totalFeet = 0;
		for (i;i<updates.length;i++){
			totalShifts += updates[i].shiftsSinceLastUpdate;
			totalFeet += updates[i].feetSinceLastUpdate;
		}
		totalFeet = round(totalFeet, 2)

		// Calculate the progress
		let totalProgress = round(totalFeet / phase.feet * 100, 1);
		let expectedFeetPerShift = phase.feet / phase.estimatedShifts;
		let actualFeetPerShiftSoFar = round(totalShifts ? totalFeet / totalShifts : 0, 1);
		let estimatedShiftsRemaining = round((phase.feet - totalFeet) / actualFeetPerShiftSoFar, 1);
		// console.log("estimatedShiftsRemaining", estimatedShiftsRemaining)

		// Also calculate the statusRating, aka the "on target" rating
		// Not currently implemented. We'll add this later.
		let statusRating = round(totalShifts ? (actualFeetPerShiftSoFar / expectedFeetPerShift) : 0, 1);
		// Return an object with useful calcs
		return {totalProgress, totalFeet, statusRating, totalShifts, actualFeetPerShiftSoFar, estimatedShiftsRemaining}
	} else if (phase.type === 'percent') {
		let totalShifts = 0;
		let totalProgress = 0;
		for (i; i < updates.length; i++){
			totalShifts += updates[i].shiftsSinceLastUpdate;
			totalProgress += updates[i].percentSinceLastUpdate;
		}
		return {totalShifts, totalProgress}
	}
	
}

const mapStateToProps = (state, ownProps) => {
	// console.log('ownProps', ownProps)
	// console.log("state", state);
	let updates = ownProps.phase.projectPhaseUpdates ? ownProps.phase.projectPhaseUpdates.map(p => state.phaseUpdates[p]).sort((a,b) => b.date - a.date) : []
	return {
		updates,
		progress: calcTotalProgress(ownProps.phase, updates),
		admin: state.user.admin
	}
}

// class ProgressIndicatorWrapper extends React.Component {
const ProgressIndicatorWrapper = ({phase, updates, progress, classes, admin}) => {
	// console.log('phase', phase)
	const [completed, setCompleted] = useState(0);

	useEffect(() => {
		const timer = setTimeout(() => setCompleted(progress.totalProgress), 100)
		return () => clearTimeout(timer)
	}, [progress])

	return (
		<React.Fragment>
			<Grid container>
				<Grid item xs={12}>
					<Typography component="h4" variant='h6'>
						{/* {phase.name + ' - ' + phase.diameter} */}
						{phase.name}
						{admin ? <PhaseDialog displayStyle={{display: "inline-block"}} phase={phase}/> : null}
						{admin ? <UpdateDialog phase={phase} values={progress}/> : null}
						
					</Typography>
					<Grid container className={classes.stats}>
						<Grid item xs={12} md={6} lg={2}>
							<strong>Phase type:</strong> {`By ${phase.type}`}
						</Grid>
						<Grid item xs={12} md={6} lg={2}>
							<strong>Start date:</strong> {`${new Date(phase.startedAt).toLocaleDateString(undefined, {useGrouping: true})}`}
						</Grid>
						{phase.type === 'length' ? 
							<React.Fragment>
								<Grid item xs={12} md={6} lg={2}>
									<strong>Crossing length:</strong> {`${phase.feet ? phase.feet.toLocaleString() : 'null'} ft`}
								</Grid>
								<Grid item xs={12} md={6} lg={2}>
									<strong>Average feet/shift:</strong> {`${progress.actualFeetPerShiftSoFar.toLocaleString(undefined, {useGrouping: true})} ft`}
								</Grid>
							</React.Fragment>
						: null }
						<Grid item xs={12} md={6} lg={2}>
							<strong>Completion:</strong> {progress.totalProgress ? `${Math.min(progress.totalProgress, 100)}%` : "0%"}
						</Grid>
						{ phase.type === 'length' ? 
							<React.Fragment>
								<Grid item xs={12} md={6} lg={2}>
									<strong>Feet remaining:</strong> {`${(phase.feet - progress.totalFeet).toLocaleString(undefined, {useGrouping: true})} ft`}
								</Grid>
								<Grid item xs={12} md={6} lg={2}>
									{updates.length
										? (<div><strong>Est. shifts remaining:</strong> {`${progress.estimatedShiftsRemaining >= 1 ? progress.estimatedShiftsRemaining : "< 1"}`}</div>)
										: (<div><strong>Est. shifts:</strong> {`${phase.estimatedShifts}`}</div>)
									}
								</Grid>
							</React.Fragment>
						: null }
					</Grid>
				</Grid>
			</Grid>
			<Grid container spacing={16} >
				<Grid item xs={12} lg={2} xl={1}>
					<Typography className={classes.progressDate} variant="body1">
						{updates.length ? updates[0].date.toLocaleDateString() : "No progress"}
					</Typography>
				</Grid>
				<Grid item xs={12} lg={10} xl={11}>
					<LinearProgress
						classes={{
							bar1Determinate: classes.bar
						}}
						className={classes.progress}
						variant="determinate"
						value={completed}
						// classes={
						// 	{colorPrimary: statusRating	}
						// }
					/>
				</Grid>
				<Grid item className={classes.stats} xs={12} md={3}>
					{ phase.type === 'length' ? <React.Fragment><strong>Most recent footage:</strong> {`${updates.length && updates[0].feetSinceLastUpdate ? updates[0].feetSinceLastUpdate : "None"}`}<br/></React.Fragment> : null }
					<strong>Total shifts:</strong> {`${progress.totalShifts}`}
				</Grid>
				<Grid item className={classes.stats} xs={12} md={9}>
					<strong>Most recent comment:</strong> {`"${updates.length && updates[0].comment ? updates[0].comment : "No comment available."}"`}
				</Grid>
			</Grid>
			<ProgressHistory phase={phase} />
		</React.Fragment>
	)
}

ProgressIndicatorWrapper.propTypes = {
	// onClick: PropTypes.func.isRequired,
	phase: PropTypes.object.isRequired
}

const ProgressIndicator = connect(
	mapStateToProps
	// mapDispatchToProps
)(ProgressIndicatorWrapper)

export default withStyles(styles)(ProgressIndicator)
