import packagejson from '../../package.json'

// console.log('packagejson', packagejson)

export default {
	// appName : "EarthAnalytics Micro Client Default UI",
	appName : "Project Delivery",
	version : packagejson.version,
	developerFeedbackRecipients	: ["softwareengineeringsupport@geoengineers.com"],
	hostUrlDev: "https://localhost:20832",
	hostUrlProd: "https://ea-microserver.geoengineers.com",
	mapboxAccessToken : 'pk.eyJ1IjoiamRzdG9wY2hpY2siLCJhIjoiY2o5YWF1eGN0MTJ5MTJxbHNsdWlqM2ExcSJ9.eaQchgKFNA1osoDsxlUSow',
	storeConfigEncrypt : {
		sharedPreferencesName: 'gisEditorSharedPrefs',
		keychainService: 'gisEditorKeychain',
		encrypt: true
	},
	storeConfigDefault : {
		sharedPreferencesName: 'gisEditorSharedPrefs',
		keychainService: 'gisEditorKeychain'
	}
};
