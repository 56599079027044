import {
	LOGIN_REQUEST_SENT,
	LOGIN_REQUEST_SUCCESS,
	LOGIN_REQUEST_ERROR,
	LOGOUT,
	SET_SELECTED_PROJECT,
	SET_SELECTED_SITE,
	TOGGLE_SHOW_INACTIVE,
	TOGGLE_SHOW_ARCHIVED
} from '../../actions/user';

import {
	HYDRATE_PROJECTS_FROM_SERVER,
	HYDRATE_SITES_FROM_SERVER,
	FETCH_PROJECT_DOCUMENT_FOLDERS_SUCCESS,
	FETCH_PROJECT_PHOTO_FOLDERS_SUCCESS
} from '../../actions/dashboard'

import sortBy from 'lodash/sortBy'
import map from 'lodash/map'
import filter from 'lodash/filter'
import keyBy from 'lodash/keyBy'
import intersection from 'lodash/intersection';
// import intersection from 'lodash/intersection'

const defaultState = {
	inProgress: false,
	isLoggedIn: false,
	showInactive: true,
	showArchived: true,
	userInfo: {},
	selectedProjectId: null,
	selectedSiteId: null,
	error: null,
	expireDate: null,
	sharepointProjectDocumentFolders: [],
	sharepointProjectPhotoFolders: []
}

function userLogin(state = defaultState, action){
	// console.log('state', state)
	// console.log('action', action)
	switch (action.type) {
		case LOGIN_REQUEST_SENT:
			return Object.assign({}, state, {
				inProgress: true
			})
		case LOGIN_REQUEST_SUCCESS:
			// console.log('action', action)
			return Object.assign({}, state, {
				inProgress: false,
				isLoggedIn: true,
				showInactive: action.user.licenseAttributesJson.showInactive !== undefined ? action.user.licenseAttributesJson.showInactive : true,
				showArchived: action.user.licenseAttributesJson.showArchived !== undefined ? action.user.licenseAttributesJson.showArchived : true,
				userInfo: action.user,
				admin: action.user.licenseTypeKey === 'SMF_SUPER_ADMIN' || action.user.licenseTypeKey === 'SMF_ADMIN' ? true : false,
				expireDate: new Date(Date.now() + (1000 * 60 * 60 * 1)).toISOString()
			})
		case LOGIN_REQUEST_ERROR:
			// console.log('action', action)
			return Object.assign({}, state, {
				inProgress: false,
				isLoggedIn: false,
				error: action.error
			})
		case LOGOUT:
			return defaultState
		case HYDRATE_PROJECTS_FROM_SERVER:
			// console.log('state', state)
			// console.log('action', action)
			let h = state.showInactive ? action.payload : keyBy(filter(action.payload, ['active', true]), 'uid')
			// console.log('h', h)
			let sortedProjectList = sortBy(h, 'name')
			// console.log('sortedProjectList', sortedProjectList)
			let sortedProjectKeys = map(sortedProjectList, 'uid')
			// console.log('sortedProjectKeys', sortedProjectKeys)
			let newSelectedProjectId = sortedProjectKeys.indexOf(state.selectedProjectId) > -1 ? state.selectedProjectId : sortedProjectKeys.length ? action.payload[sortedProjectKeys[0]].uid : null;
			// console.log('newSelectedProjectId from project hydrate', newSelectedProjectId)
			return Object.assign({}, state, {
				selectedProjectId: newSelectedProjectId,
				// selectedSiteId: !state.selectedSiteId && sortedProjectKeys.length ? action.payload[sortedProjectKeys[0]] && action.payload[sortedProjectKeys[0]].projectSites[0] : state.selectedSiteId,
			})
		case HYDRATE_SITES_FROM_SERVER:
			// console.log('action.payload', action.payload)
			// console.log('state', state)
			let activeProjects = state.showInactive ? action.payload.projects : keyBy(filter(action.payload.projects, ['active', true]), 'uid');
			// console.log('activeProjects', activeProjects)
			let thisSortedProjectList = sortBy(activeProjects, 'name')
			// console.log('sortedProjectList', thisSortedProjectList)
			let projectKeys = map(thisSortedProjectList, 'uid');
			let selectedProjectId = projectKeys.indexOf(state.selectedProjectId) > -1 ? state.selectedProjectId : projectKeys.length ? action.payload.projects[projectKeys[0]].uid : null;
			// console.log('newSelectedProjectId from site hydrate', selectedProjectId)
			let activeSites = state.showInactive ? action.payload.sites : keyBy(filter(action.payload.sites, ['active', true]), 'uid');
			// console.log('activeSites', activeSites)
			let sortedSiteList = sortBy(activeSites, 'name')
			// console.log('sortedSiteList', sortedSiteList)
			let siteKeys = map(sortedSiteList, 'uid');
			// console.log('siteKeys', siteKeys)
			let activeProjectSiteKeys = intersection(activeProjects[selectedProjectId].projectSites, siteKeys)
			// console.log('activeProjectSiteKeys', activeProjectSiteKeys)
			let newSelectedSiteId = activeProjectSiteKeys.indexOf(state.selectedSiteId) > -1 ? state.selectedSiteId : activeProjectSiteKeys[0] ? activeProjectSiteKeys[0] : null
			// console.log('newSelectedSiteId', newSelectedSiteId);
			return Object.assign({}, state, {
				selectedSiteId: newSelectedSiteId
			})
		case SET_SELECTED_PROJECT:
			// console.log('action.payload', action.payload)
			return Object.assign({}, state, {
				selectedProjectId: action.payload,
			})
		case SET_SELECTED_SITE:
			// console.log('action.payload', action.payload)
			return Object.assign({}, state, {
				selectedSiteId: action.payload.site,
			})
		case FETCH_PROJECT_DOCUMENT_FOLDERS_SUCCESS:
			// console.log('action.payload', action.payload)
			return Object.assign({}, state, {
				sharepointProjectDocumentFolders: action.payload.body.d.Folders.results
			})
		case FETCH_PROJECT_PHOTO_FOLDERS_SUCCESS:
			// console.log('action.payload', action.payload)
			return Object.assign({}, state, {
				sharepointProjectPhotoFolders: action.payload.body.d.Folders.results
			})
		case TOGGLE_SHOW_INACTIVE:
			// console.log('action.payload', action.payload)
			return Object.assign({}, state, {
				showInactive: action.payload,
				// selectedProjectId: null,
				// selectedSiteId: null
			})
		case TOGGLE_SHOW_ARCHIVED:
			return Object.assign({}, state, {
				showArchived: action.payload
			})
		default:
			return state
	}
}

export default userLogin;
