import {
	// SET_SELECTED_PROJECT,
	SITE_DETAILS_EDIT_START,
	SITE_DETAILS_EDIT_SUCCESS,
	PROJECT_EDIT_SUCCESS,
	PROJECT_DELETE_SUCCESS
	// REMOVE_CONTACT,
	// ADD_CONTACT
} from '../../actions/projectOverview';

import {
	HYDRATE_PROJECTS_FROM_SERVER,
	// HYDRATE_SITES_FROM_SERVER,
	// HYDRATE_PHASES_FROM_SERVER,
	// HYDRATE_UPDATES_FROM_SERVER,
} from '../../actions/dashboard'

import { LOGOUT } from '../../actions/user'

import pick from 'lodash/pick'
// import omitBy from 'lodash/omit'

function projectReducers(state = {}, action){
	// console.log('state', state)

	switch (action.type) {
		case HYDRATE_PROJECTS_FROM_SERVER:
			// console.log('action', action)
			return Object.assign({}, state, {
				...action.payload
			})
		case SITE_DETAILS_EDIT_START:
			let siteDetailEditStartReturnState = Object.assign({}, state)
			siteDetailEditStartReturnState[action.project.uid].inProgress = true;
			return siteDetailEditStartReturnState;
		case SITE_DETAILS_EDIT_SUCCESS:
			action.project.inProgress = false;
			let index = action.project.projectSites.indexOf(action.site.uid)
			if (index === -1){
				// if it doesn't, add it to the array
				action.project.projectSites.push(action.site.uid)
			}
			return Object.assign({}, state, {
				[action.project.uid]: action.project
			});
		case PROJECT_EDIT_SUCCESS:
			// console.log('action', action)
			let project = state[action.project.uid] ? Object.assign({}, state[action.project.uid], action.project) : action.project;
			// console.log('project', project)
			// action.project.projectSites = action.project.projectSites ? action.project.projectSites : [];
			return Object.assign({}, state, {
				[action.project.uid]: project
			})
		case PROJECT_DELETE_SUCCESS:
			// console.log('action.project', action.project)
			let returnState = pick(state, Object.keys(state).filter(p => p.uid !== action.project.uid))
			// console.log('returnState', returnState)
			return Object.assign({}, returnState)
		case LOGOUT:
			return {};
		default:
			return state;
	}
}

export default projectReducers;
