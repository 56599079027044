import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import Tooltip from '@material-ui/core/Tooltip'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import Slider from '@material-ui/lab/Slider'
// import DateTimePicker from 'material-ui-pickers/DateTimePicker';
import { siteDetailsEdit, deleteSite } from '../../actions/projectOverview';
import clone from 'lodash/clone';
// import HostUrl from '../../utils/hostUrl'

// let hosturl = HostUrl()

const styles = theme => ({
	admin: {
		color: theme.palette.admin.main
	},
	sliderTrack: {
		backgroundColor: theme.palette.secondary.dark
	},
	sliderThumb: {
		backgroundColor: theme.palette.secondary.light
	}
})

const mapStateToProps = (state, ownProps) => {
	// console.log("site dialog state", state)
	return {
		availableProjectDocumentFolders: state.user.sharepointProjectDocumentFolders,
		availableProjectPhotoFolders: state.user.sharepointProjectPhotoFolders,
		project: state.projects ? state.projects[state.user.selectedProjectId] : {},
		projectNumber: state.user.selectedProjectId && state.projects[state.user.selectedProjectId] ? state.projects[state.user.selectedProjectId].projectNumber : null
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onEditSiteDetails: (update) => dispatch(siteDetailsEdit(update)),
		onDeleteSite: (update) => dispatch(deleteSite(update))
	}
}

// Validation function
// const required = value => (value == null ? 'Required' : undefined)

class SetupForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			name: this.props.site ? clone(this.props.site.name) : "",
			id: this.props.site ? clone(this.props.site.id) : null,
			projectPhases: this.props.site ? clone(this.props.site.projectPhases) : [],
			projectId: clone(this.props.project.id),
			photosFolderPath: this.props.site ? clone(this.props.site.photosFolderPath) : "",
			documentsFolderPath: this.props.site ? clone(this.props.site.documentsFolderPath) : "",
			active: this.props.site ? clone(this.props.site.active) : true,
			archived: this.props.site ? clone(this.props.site.archived) : false,
			percentComplete: this.props.site ? clone(this.props.site.percentComplete) : 0
		};
		this.handleChange = this.handleChange.bind(this);
		this.props.wrapperContext.setupFormContext = this;
		// console.log('this.state', this.state)
	}

	handleChange(event, checked) {
		// console.log('checked', checked)
		// let newValue = checked ? checked : event.target.value
		// console.log('name', event.target.name, checked)
		// this.setState({
		// 	[event.target.name]: newValue
		// })
		// console.log('event', event)
		this.setState({
			[event.target.name]: event.target.value
		})
	}

	render() {
		// console.log('this.state', this.state)
		const { name, documentsFolderPath, photosFolderPath, active, percentComplete, archived } = this.state;
		const { availableProjectPhotoFolders, availableProjectDocumentFolders, classes } = this.props;
		// console.log('classes', classes)
		return (
			<form id='setupForm'>
				<TextField
					autoFocus
					name="name"
					margin="dense"
					id="name"
					label="Name"
					type="text"
					// validate={required}
					defaultValue={name}
					InputLabelProps={{shrink:true}}
					fullWidth
					onChange={this.handleChange}
				/>
				<FormControl fullWidth>
					<InputLabel htmlFor='documents-name'>Sharepoint Documents Folder</InputLabel>
					<Select
						id="documents-name"
						value={documentsFolderPath}
						onChange={this.handleChange}
						// autoWidth={true}
						inputProps={{
							name: 'documentsFolderPath',
							id: 'documents-name'
						}}
					>
						{availableProjectDocumentFolders ? availableProjectDocumentFolders.map(f => {
								// console.log('f', f)
								return f.Name !== "Forms" ? <MenuItem value={f.ServerRelativeUrl} key={f.Name}>{f.Name}</MenuItem> : false
						}) : false }
						<MenuItem value={""} key={'noFolder'}>No Folder</MenuItem>
					</Select>
					<p><small>Select a top-level folder from the Field Reports section of this project's Sharepoint site to display when this site is selected.</small></p>
				</FormControl>
				<FormControl fullWidth>
					<InputLabel htmlFor='documents-name'>Sharepoint Photos Folder</InputLabel>
					<Select
						id="photos-name"
						value={photosFolderPath}
						onChange={this.handleChange}
						// autoWidth={true}
						inputProps={{
							name: 'photosFolderPath',
							id: 'photos-name'
						}}
					>
						{availableProjectPhotoFolders ? availableProjectPhotoFolders.map(f => {
								return f.Name !== "Forms" && f.Name !== '_w' && f.Name !== '_t' ? <MenuItem value={f.ServerRelativeUrl} key={f.Name}>{f.Name}</MenuItem> : false
						}) : false}
						<MenuItem value={""} key={'noFolder'}>No Folder</MenuItem>
					</Select>
					<p><small>Select a top-level folder from the Project Photos section of this project's Sharepoint site to display when this site is selected.</small></p>
				</FormControl>
				<FormControl fullWidth style={{marginBottom: 15}}>
					<Typography style={{marginBottom: 10}} variant='body1'>{`Overall Site Percent Complete: ${percentComplete}%`}</Typography>
					<Slider
						style={{paddingLeft: 5, paddingRight: 5, paddingBottom: 5}}
						id='percent-complete'
						value={percentComplete}
						min={0}
						max={100}
						step={1}
						onChange={(event, value) => {
							this.handleChange({
								target: {
									name: 'percentComplete',
									value: value
								}
							})
						}}
						classes={{
							track: classes.sliderTrack,
							thumb: classes.sliderThumb
						}}
					/>
				</FormControl>
				<FormControlLabel
					control={
						<Switch
							checked={active === "true" || active === true ? true : false}
							name='active'
							onChange={this.handleChange}
							value={!active}
						/>
					}
					label="Active"
				/>
				<FormControlLabel
					control={
						<Switch
							checked={archived === "true" || archived === true ? true : false}
							name='archived'
							onChange={this.handleChange}
							value={!archived}
							color='primary'
						/>
					}
					label="Archived"
				/>
				{/* we'll need location data in here eventually */}
			</form>
		)
	}
}

class DialogWrapper extends React.Component {
    state = {
        open: false
    };

    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleDelete = (event) => {
		this.props.onDeleteSite({
			site: this.props.site,
			project: this.props.project,
			cb: () => this.setState({ open: false })
		})
	}

    handleClose = () => {
        this.setState({open: false});
    };

    handleSave = () => {
		// console.log('this', this);
		// console.log("this.setupFormContext.state", this.setupFormContext.state);
		// console.log('updateObject', updateObject)

		var updateObject = this.setupFormContext.state;
		// console.log('updateObject', updateObject)
        this.props.onEditSiteDetails({
            updateObject,
            project: this.props.project,
			cb: () => this.setState({open: false})
        })

    }

    render() {
		const { classes } = this.props
		// console.log('this.props', this.props)
		let dialogTitle = this.props.site ? "Edit site details" : "Add site";
		let dialogButton = this.props.site ? (
			<Tooltip id="tooltip-setup" placement="top" title="Site Setup">
				<IconButton size="small" onClick={this.handleClickOpen}>
					<EditIcon className={classes.admin}/>
				</IconButton>
			</Tooltip>
		) : (

			<MenuItem onClick={this.handleClickOpen}>+ Add new site</MenuItem>
		)
        return (
			<div style={this.props.displayStyle}>
				{dialogButton}
	            <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
	                <DialogTitle id="form-dialog-title">
						{dialogTitle}
					</DialogTitle>
	                <DialogContent style={{overflow: 'hidden'}}>
	                    <SetupForm {...this.props} wrapperContext={this}/>
	                </DialogContent>
	                <DialogActions>
	                    <Button onClick={this.handleDelete} disabled={!this.props.site} color="primary">
	              			Delete
	            		</Button>
	                    <Button onClick={this.handleClose} color="primary">
	                        Cancel
	                    </Button>
	                    <Button onClick={this.handleSave} form='setupForm' color="primary">
							{ this.props.project && this.props.project.inProgress ? <CircularProgress size={20} /> : false }
	                        Save
	                    </Button>
	                </DialogActions>
	            </Dialog>
	        </div>);
    }
}

const PhaseDialog = withStyles(styles)(connect(
	mapStateToProps,
	mapDispatchToProps
)(DialogWrapper))

export default PhaseDialog;
