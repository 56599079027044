import React from 'react';
import ReactDOM from 'react-dom';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import DateFnsUtils from 'material-ui-pickers/utils/date-fns-utils'
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider'
import { PersistGate } from 'redux-persist/integration/react'
import { store, history, persistor } from './utils/store'

import 'core-js/fn/string/includes';

import App from './screens/App';

import './index.css';

// base geo colors
const theme = createMuiTheme({
	typography: {
		useNextVariants: true
	},
	palette: {
		primary: {
			light: '#3f6a94',
			main: '#004066',
			dark: '#001a3b',
			contrastText: '#ffffff',
		},
		secondary: {
			light: '#aee571',
			main: '#7cb342',
			dark: '#4b830d',
			contrastText: '#fafafa',
		},
		admin: {
			light: '#ffe54c',
			main: '#ffb300',
			dark: '#c68400',
			contrastText: '#000000',
		},
		locked: {
			// light: 
		}
	}
});

// console.log('store', store.getState())
// const StoreInstance = store();

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<CssBaseline />
		    <MuiThemeProvider theme={theme}>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<App history={history}/>
				</MuiPickersUtilsProvider>
			</MuiThemeProvider>
		</PersistGate>
	</Provider>,
	document.getElementById('root')
);

unregister()
// registerServiceWorker();
