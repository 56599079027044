import React from 'react';
import { connect } from 'react-redux'
import {withStyles} from '@material-ui/core/styles'
import classnames from 'classnames';
// import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid'
// import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import {lighten} from "@material-ui/core/styles/colorManipulator"
import Collapse from '@material-ui/core/Collapse'
// import Hidden from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import UpdateDialog from './UpdateDialog'

const styles = theme => ({
	expand: {
		verticalAlign: 'top',
		fontSize: 18,
		transform: 'rotate(0deg)',
		transition: theme.transitions.create('transform', {
      		duration: theme.transitions.duration.shortest,
	    })
	},
	expandOpen: {
		transform: 'rotate(180deg)'
	},
	historyBar: {
		marginTop: 4,
		height: 8
	},
	historyDate: {
		fontSize: 12,
		textAlign: 'left',
		paddingRight: 10
	},
	details: {
		textTransform: 'none',
		fontWeight: 'normal',
		textAlign: 'left',
		// color: 'inherit',
		fontSize: "1em",
		letterSpacing: "normal",
		lineHeight: 1,
		"& strong": {
			fontWeight: 500
		},
		color: "rgba(0,0,0,0.54)"
	},
	detailsButton: {
		// paddingLeft: 5,
		// paddingRight: 5,
		// paddingTop: 2,
		// height: '100%',
		// margin: 0,
		// fontWeight: 'bold',
		// fontSize: 12,
		// textTransform: 'uppercase'
	},
	percentContainer: {
		cursor: "pointer",
		transition: "all 0.2s ease-in-out",
		marginTop: 3,
		width: '100%',
		display: 'flex',
		height: 12,
		'&:hover': {
			boxShadow: [[1, 1, 3, (0, lighten)(theme.palette.primary.main, 0.3)]]
		}
	},
	newPercent: {
		background: (0, lighten)(theme.palette.primary.main, 0)
	},
	previousPercent: {
		background: (0, lighten)(theme.palette.primary.main, 0.3)
	},
	backgroundPercent: {
		background:  (0, lighten)(theme.palette.primary.light, 0.8)
	},
	admin: {
		// backgroundColor: theme.palette.admin.main,
		'&:hover': {
			backgroundColor: theme.palette.admin.dark
		}
	}
})

const mapStateToProps = (state, ownProps) => {
	return {
		admin: state.user.admin
	}
}

class ProgressUpdate extends React.Component {
	state = { expanded : false }

	handleExpandClick = event => {
		this.setState(state => ({expanded: !state.expanded}));
	};

	render() {
		const { classes, phase, update, admin, values } = this.props
		// console.log('values', values)
		// const { anchorEl } = this.state
		// console.log('this.props.', this.props.values)
		return (
				<Grid container className={classes.hover}>
					<Grid item xs={12} md={admin ? 11 : 12}>
						<Button size="small" onClick={this.handleExpandClick} fullWidth className={classes.detailsButton}>
							<Grid container spacing={8}>
								<Grid item xs={12} lg={2} xl={1}>
									<Typography className={classes.historyDate} variant="body1">
										{/*
											If you're here because suddenly this.props.date isn't a date anymore,
											check persistConfig in store. Date objects can't be stored in localStorage,
											so we might need to consider storing dates in our store as integers
											or strings and then parsing on show. -AJZ
										*/}
										<ExpandMoreIcon
											color="primary"
											className={classnames(classes.expand, {
												[classes.expandOpen]: this.state.expanded,
											})}
										/>
										{this.props.update.date.toLocaleDateString()}
									</Typography>

								</Grid>
								<Grid item xs={12} lg={10} xl={11}>
									<div
										className={classes.percentContainer}
										>
										<div
											style={{'flex': values.previousPercent}}
											className={classes.previousPercent}
										>
										</div>
										<div
											style={{'flex': values.newPercent}}
											className={classes.newPercent}
										>
										</div>
										<div
											style={{'flex': (100 - values.newPercent - values.previousPercent)}}
											className={classes.backgroundPercent}
										>
										</div>
									</div>
								</Grid>
								<Grid item xs={12}>
									<Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
										<Grid container spacing={8} className={classes.details}>
											<Grid item xs={12} sm={6} md={3}>
												<strong>Date:</strong> {`${update.date.toLocaleString()}`}
											</Grid>
											{phase.type === 'length' ? 
												<React.Fragment>
													<Grid item xs={12} sm={6} md={3}>
														<strong>Feet recorded:</strong> {`${update.feetSinceLastUpdate} ft.`}
													</Grid>
													<Grid item xs={12} sm={6} md={3}>
														<strong>Cumulative feet:</strong> {`${values.cumulativeProgress.toLocaleString(undefined, {useGrouping: true})} ft.`}
													</Grid>
												</React.Fragment>
											: null}
											<Grid item xs={12} sm={6} md={3}>
												<strong>Percentage complete:</strong> {values.newPercent + values.previousPercent > 0 ? `${(values.newPercent + values.previousPercent).toFixed(1)}%` : "0%"}
											</Grid>
											<Grid item xs={12} md={9}>
												<strong>Comment:</strong> {`${update.comment}`}
											</Grid>
											<Grid item xs={12} md={3}>
												<strong>Posted by:</strong> {update.contact ? `${update.contact.firstName} ${update.contact.lastName}` : "Unavailable"}
											</Grid>
										</Grid>
									</Collapse>
								</Grid>
							</Grid>
						</Button>
					</Grid>
					{admin ?
						<Grid item xs={12} md={1}>
							<UpdateDialog values={values} phase={phase} update={update}>
								<Button fullWidth size="small" className={classes.admin}>Edit</Button>
							</UpdateDialog>
						</Grid>
					: false}
				</Grid>
		)
	}
}

const ProgressUpdateWrapper = withStyles(styles)(connect(
	mapStateToProps,
	// mapDispatchToProps
)(ProgressUpdate))

export default ProgressUpdateWrapper;
