import HostUrl from '../../utils/hostUrl'
import {projectEditStart, projectEditSuccess} from './project'

export const SITE_DETAILS_EDIT_START = 'SITE_DETAILS_EDIT_START'
export const SITE_DETAILS_EDIT_SUCCESS = 'SITE_DETAILS_EDIT_SUCCESS'
export const SITE_DELETE_SUCCESS = 'SITE_DELETE_SUCCESS'

let hostUrl = HostUrl();

/* ------- site actions ----------- */

export function siteDetailsEditStart(site, project){
	return {
		type: SITE_DETAILS_EDIT_START,
		site,
		project
	}
}

export function siteDetailsEditSuccess(site, project){
	return {
		type: SITE_DETAILS_EDIT_SUCCESS,
		site,
		project
	}
}

export function siteDetailsEdit(item){
	return function(dispatch, getState){
		// console.log('stattte', getState())
		// fetch action
		// console.log('item', item)

		dispatch(siteDetailsEditStart(item.updateObject, item.project))
		let postObject = {
			projectId: item.project.id,
			name: item.updateObject.name,
			photosFolderPath: item.updateObject.photosFolderPath,
			documentsFolderPath: item.updateObject.documentsFolderPath,
			active: item.updateObject.active,
			archived: item.updateObject.archived,
			percentComplete: item.updateObject.percentComplete
		}
		if (item.updateObject.id !== null){
			postObject.id = item.updateObject.id
		}
		let token = getState().user.userInfo.token
		// let auth = { "authorization": token};
		return fetch(`${hostUrl}/EaProject/ProjectSite?authorization=${token}`,
			{
				"body": JSON.stringify(postObject),
				"headers": {
					'user-agent': 'ProjectDelivery',
					'content-type': 'application/json'
				},
				"method": 'POST'
			}
		)
		.then(response => response.json())
		.then(json => {
			// console.log('json', json)
			if (json.name === 'EaError'){
				console.log('error', json)
			} else {
				fetch(`${hostUrl}/EaProject/ProjectSite/${json.id}?authorization=${token}`)
				.then(response => response.json())
				.then(json2 => {
					json2.projectPhases = item.updateObject.projectPhases ? item.updateObject.projectPhases :[]
					dispatch(siteDetailsEditSuccess(json2, item.project))
					item.cb()
				})
			}
		})
	}
}

export function deleteSite(item){
	return function(dispatch, getState){
		let token = getState().user.userInfo.token
		dispatch(projectEditStart(item.project))
		return fetch(`${hostUrl}/EaProject/ProjectSite/${item.site.id}?authorization=${token}`,
			{
				// "body": JSON.stringify(postObject),
				"headers": {
					'user-agent': 'ProjectDelivery',
					'content-type': 'application/json'
				},
				"method": 'DELETE'
			}
		)
		.then(response => response.json())
		.then(json => {
			if (json.name === 'EaError'){
				console.log('error', json)
			} else {
				item.project.projectSites = item.project.projectSites.filter(u => u !== item.site.uid)
				dispatch(siteDeleteSuccess(item.site, item.project))
				dispatch(projectEditSuccess(item.project))
				item.cb()
			}
		})
	}
}

function siteDeleteSuccess(site, project){
	return {
		type: SITE_DELETE_SUCCESS,
		site,
		project
	}
}
