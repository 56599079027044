// import our individual reducers here
import projectReducers from './projectOverview/projectReducers'
import siteReducers from './projectOverview/siteReducers'
import phaseReducers from './projectOverview/phaseReducers'
import phaseUpdateReducers from './projectOverview/phaseUpdateReducers'
import contactsReducer from './contacts'
import docsReducer from './documents'
// import loginMock from './user/login_mock';
import userReducers from './user'
import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

// see 'reducer composition' on redux docs for more info about combineReducers

// We are combining reducers, each of which is mapped to a part of the state
// using the keys in the argument object
const rootReducer = combineReducers({
	projects: projectReducers,
	sites: siteReducers,
	phases: phaseReducers,
	phaseUpdates: phaseUpdateReducers,
	contacts: contactsReducer,
	router: routerReducer,
	docs: docsReducer,
	user: userReducers
});

export default rootReducer;
