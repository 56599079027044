import React from 'react'
import { connect } from 'react-redux'
import {withStyles} from '@material-ui/core/styles'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
// import IconButton from '@material-ui/core/IconButton'
// import InfoIcon from '@material-ui/icons/Info'
import ButtonBase from '@material-ui/core/ButtonBase'
// import CircularProgress from '@material-ui/core/CircularProgress'

import { Preview } from '../../utils/GenericFileTable'

import HostURL from '../../utils/hostUrl'

let hosturl = HostURL()

const mapStateToProps = (state, ownProps) => {
	return {
		token: state.user.userInfo.token,
	}
}

const styles = theme => ({
	icon: {
	    color: 'rgba(255, 255, 255, 0.54)',
	},
	tile: {
		height: 120,
		width: 160,
		flex: 'auto',
		padding: 2,
		// '& div': {
		// 	height: '100%'
		// }
	},
	tileParent: {
		// padding: 2,
		// minWidth: 160,
		// minHeight: 120,
		// backgroundColor: 'black',
		// backgroundClip: 'content-box'
	},
	preview: {
		height: 120
	},
	progress: {
		marginLeft: '40%',
		marginTop: '10%'
	},
	titleBar: {
	    background:
	      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
	},
	imageButton: {
		backgroundColor: 'black',
		// height: 120,
		// backgroundClip: 'content-box',
		width: '100%',
		height: '100%',
		'& img': {
			width: '100%',
			height: '100%',
			objectFit: 'cover'
		}
	}
})

const Tile = ({tile, classes, token}) => {

	const fetchImage = (path) => {
		// console.log('path', path)
		let pathArray = path.split('/');
		// console.log('pathArray', pathArray)
		let fileName = pathArray[pathArray.length - 1];
		// console.log('fileName', fileName)
		let lastDotIndex = fileName.lastIndexOf('.');
		let fileNameArray = [fileName.substring(0, lastDotIndex), fileName.substring(lastDotIndex + 1)];
		// console.log('fileNameArray', fileNameArray)
		let fileExtension = fileNameArray[fileNameArray.length -1];
		// console.log('fileExtension', fileExtension)
		let newNamePart = '_' + fileExtension.toLowerCase();
		fileNameArray.splice(-1, 1, newNamePart);
		// let newFileName = fileNameArray.join('') + '.' + fileExtension
		let newFileName = fileNameArray.join('') + '.jpg';
		// console.log('newFileName', newFileName)
		pathArray.splice(-1, 1, '_w', newFileName);
		// console.log('pathArray', pathArray)
		let newPath = pathArray.join('/');
		// console.log('newPath', newPath)
		return `${hosturl}/EaSharepoint/Proxy?path=${newPath}&authorization=${token}`;
		// let that = this
		// return fetch(url)
		// return fetch(url, { signal: that.signal})
	}

	return (
		<GridListTile
			cols={1}
			className={classes.tile}>
			component={
				<Preview
					file={tile}
					token={token}
					DialogButton={props =>
						<ButtonBase {...props} className={classes.imageButton} >
							{/* {isLoading ? */}
								{/* <CircularProgress color='secondary' size={20} className={classes.progress} /> : */}
								<img src={fetchImage(tile.path)} alt={tile.name} />
							{/* } */}
							<GridListTileBar
								title={tile.name}
								subtitle={new Date(tile.timeLastModified).toLocaleString()}
								classes={{
									root: classes.titleBar
								}}
								// actionIcon={
								//
								// }
							/>
						</ButtonBase>

					}
					image={tile}
				/>
			}

		</GridListTile>
	)
}

const TileWrapper = connect(
	mapStateToProps,
	// mapDispatchToProps
)(Tile)

export default withStyles(styles)(TileWrapper)
