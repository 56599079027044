import React from 'react'
import { connect } from 'react-redux'
import MUIDataTable from 'mui-datatables'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import OrgDialog from './OrgDialog'

import {fetchOrganizationList} from '../../../actions/contacts'

// import ContactDialog from '../../Contacts/ContactsManagement/ContactDialog'

// import hostUrl from '../../../utils/hostUrl'

// let hosturl = hostUrl();

/* -------- NOTE ---------- */
/* This component is largely disconnected from redux */

const styles = theme => ({
	paper: {
    	padding: theme.spacing.unit * 2,
		// marginTop: 20,
    	// textAlign: 'center',
    	color: theme.palette.text.secondary
    }
})

const mapDispatchToProps = dispatch => {
	return {
		fetchOrganizationList: () => dispatch(fetchOrganizationList())
	}
}

const mapStateToProps = (state, ownProps) => {
	// console.log('statestate', state)
	return {
		token: state.user.userInfo.token,
		organizations: state.contacts.organizationList,
		loading: state.contacts.userContactsLoading
	}
}

class Organizations extends React.Component {

	componentDidMount(){
		this.setState({
			usersLoading: true
		})

		this.props.fetchOrganizationList()

		// ugh this is gross
		// fetch(`${hosturl}/EaLogin/LicenseType?authorization=${this.props.token}`)
		// 	.then(r => r.json())
		// 	.then(json => {
		// 		this.setState({licenseTypes: json})
		// 		// this.setState({usersLoading: false})
		// 		this.fetchUsers()
		// 	})
	}

	render(){
		const {organizations, loading } = this.props
		if (loading) {
			return (
				<Typography >
					Loading organizations...
				</Typography>
			)
		} else {
			// console.log('organizations', organizations)
			return (
				<div>
					<MUIDataTable
						title="Organizations"
						data={organizations ? organizations.map(o => {
							return [
								o.id,
								o.name || "",
								o.city || "",
								o.id
							]
						}) : [ [" "] ]}
						columns={[
							"ID",
							"Name",
							"City",
							// "Manage"
							{
								name: "Manage",
								options: {
									customBodyRender: (v, m, u) => {
										// console.log('v', v, 'm', m, 'u', u)
										return (
											<OrgDialog org={organizations[m.rowIndex]}/>
										)
									}
								}
							},
						]}
						options={{
							rowsPerPage: 10,
							selectableRows: 'none',
							sort: false,
							filter: false,
							print: false,
							download: false,
							viewColumns: false,
							pagination: true,
							responsive: 'scrollMaxHeight'
						}}
					/>
					<OrgDialog style={{display: 'block'}}/>
				</div>
			)
		}
	}
}

const OrganizationsWrapper = connect(
	mapStateToProps,
	mapDispatchToProps
)(Organizations)

export default withStyles(styles)(OrganizationsWrapper)
