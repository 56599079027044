import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Chip, Tooltip } from '@material-ui/core';
import red from '@material-ui/core/colors/red';

const styles = theme => ({
	archivedChip : {
		color: red[500],
		border: '1px solid',
		borderColor: red[700],
		marginLeft: 5,
	},
	smallChip: {
		height: 20	
	}
})

const ArchivedChip = ({small, classes}) => {
    return (
        <Tooltip
            title="Archived projects and sites are only visible to administrators."
            placement="top"
        >
            <Chip 
                label='Archived' 
                variant='outlined'
                className={classNames(classes.archivedChip, {
                    [classes.smallChip]: small ? true : false
                })}
            />
        </Tooltip>
    )
}

export default withStyles(styles)(ArchivedChip);