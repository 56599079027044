import {UserAgentApplication} from 'msal'

const msalConfig = {
    auth: {
        clientId: "1f96a373-a722-4e37-b991-ed078c6902a7",
        authority: "https://login.microsoftonline.com/9c984c59-1346-40ee-833f-330437a233ce"
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
};

const msalInstance = new UserAgentApplication(msalConfig);

export default msalInstance;