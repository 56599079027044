import React from 'react';
import {connect} from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid'
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton'
import HistoryIcon from '@material-ui/icons/History'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import ProgressUpdate from './ProgressUpdate'

// import isEqual from 'lodash/isEqual'

const styles = theme => ({
	expand: {
		transform: 'rotate(0deg)',
		transition: theme.transitions.create('transform', {
      		duration: theme.transitions.duration.shortest,
	    })
	},
	expandOpen: {
		transform: 'rotate(180deg)'
	},
	historyIcon: {
		color: '#fffff'
	},
	historyCollapse: {
		marginBottom: 10
	}
})

const mapStateToProps = (state, ownProps) => {
	// console.log('ownProps', ownProps)
	// console.log("state", state);
	return {
		// contacts: state.contacts,
		updates: ownProps.phase.projectPhaseUpdates ? ownProps.phase.projectPhaseUpdates.map((p) => { return state.phaseUpdates[p] }) : []
	}
}

class ProgressHistoryWrapper extends React.Component {
	state = { expanded: false }

	toggleHidden = () => {
		this.setState({
			expanded: !this.state.expanded
		})
	}

	calcValuesForHistory(index){
		let updatesSoFar = this.props.updates.slice(index)
		let progressSoFar = 0;
		let thisProgress;
		let fullNewPercent;
		let fullPreviousPercent;
		var i = 0; 
		// console.log('updatesSoFar', updatesSoFar)
		if (this.props.phase.type === 'length'){
			thisProgress = updatesSoFar[0].feetSinceLastUpdate;
			for (i; i < updatesSoFar.length; i++){
				progressSoFar += updatesSoFar[i].feetSinceLastUpdate;
			}

			fullNewPercent = this.props.phase.feet !== 0 ? (thisProgress / this.props.phase.feet) * 100 : 0;
			// let fullNewPercent = (thisProgress / this.props.phase.feet !== 0 ? this.props.phase.feet : 0) * 100;
			fullPreviousPercent = this.props.phase.feet !== 0 ? ((progressSoFar - thisProgress) / this.props.phase.feet) * 100 : 0;
		} else if (this.props.phase.type === 'percent'){
			thisProgress = updatesSoFar[0].percentSinceLastUpdate;
			for (i; i < updatesSoFar.length; i++){
				progressSoFar += updatesSoFar[i].percentSinceLastUpdate;
			}
			fullNewPercent = thisProgress;
			fullPreviousPercent = progressSoFar - thisProgress;
		}

		
		return {
			cumulativeProgress: progressSoFar,
			newPercent: fullNewPercent,
			previousPercent: fullPreviousPercent,
			date: updatesSoFar[0].date
		}
	}
	render() {
		const { classes } = this.props;
		// console.log('this.props', this.props)
		let that = this;
		this.props.updates.sort((a,b) => b.date - a.date )
		// console.log('this.props.updates', this.props.updates)
		const progressList = this.props.updates.length > 0 ? this.props.updates.map((update, index) => {
			// console.log('update', update, index)
			let values = that.calcValuesForHistory(index);
				// console.log('values', values)
				// console.log('update', update)
			return <ProgressUpdate phase={that.props.phase} update={update} key={update.id} values={values} />
		}) : <Typography component="h5" variant='h6'>No Progress Updates</Typography>

		return (
			<div>
				<Grid container>
					<Grid item xs={12}>
						History
						<IconButton disabled={true}>
							<HistoryIcon color="disabled"/>
						</IconButton>
						<Tooltip id="tooltip-icon" title="Show history" placement="right">
							<IconButton
								className={classnames(classes.expand, {
									[classes.expandOpen]: this.state.expanded,
								})}
								onClick={this.toggleHidden}
								aria-expanded={this.state.expanded}
								aria-label="Show history"
							>
								<ExpandMoreIcon color="primary"/>
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
				<Collapse className={classes.historyCollapse} in={this.state.expanded} timeout="auto">
					{progressList}
				</Collapse>
			</div>
		)
	}
}

const ProgressHistory = connect(
	mapStateToProps
	// mapDispatchToProps
)(ProgressHistoryWrapper)

export default withStyles(styles)(ProgressHistory);
