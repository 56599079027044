import React from 'react'
// import { render } from 'react-dom'
import { connect } from 'react-redux'
import {
  ConnectedRouter,
  // routerReducer,
  // routerMiddleware,
  push
} from 'react-router-redux'

// import createHistory from 'history/createBrowserHistory'

import { withStyles } from '@material-ui/core/styles'
import { Route, Switch } from 'react-router'
import { Redirect } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

// import {authSuccess, authFail} from '../actions/login/mock.js';

import ProjectSwitcher from './ProjectSwitcher'
import AppBar from './AppBar'

import AllProjects from './AllProjects'
import Dashboard from './Dashboard'
import LoginForm from './Login'
import ProgressOverview from './ProgressOverview'
import Contacts from './Contacts'
import Documents from './Documents'
import Photos from './Photos'
import Admin from './Admin'
import ActivateAccount from './ActivateAccount'
import ResetPassword from './ResetPassword'

import logo from '../geo_logo.svg'

// console.log('admin', Admin)

const styles = theme => ({
	paper: {
    	padding: theme.spacing.unit * 2,
		// marginTop: 20,
    	// textAlign: 'center',
    	color: theme.palette.text.secondary
    },
	appFrame: {
		padding: 20,
		height: '100%'
	}
})

class LoginContainer extends React.Component {
  	render() {
	  	const { classes } = this.props;
    	return (
			<div className={classes.appFrame}>
				<Grid container justify={'center'} alignItems={'center'} spacing={16}>
					<Grid item xs={12} md={3}>
						<Paper className={classes.paper}>
							<img alt='GeoEngineers Logo' src={logo}/>
							<h3>Log into Project Delivery</h3>
							<LoginForm cb={this.props.cb}/>
						</Paper>
					</Grid>
				</Grid>
			</div>
		)
  	}
}

class PrivateRouteContainer extends React.Component {
  render() {
    const {
		hideSwitcher,
      	isAuthenticated,
		// admin,
      	component: Component,
      	...props
    } = this.props
	// console.log('this', this)
    return (
      <Route
        {...props}
        render={props =>
          isAuthenticated
            ? (
				<AppBar>
					{!hideSwitcher ? <ProjectSwitcher /> : null }
					<Component {...props} />
				</AppBar>
			)
            : (
            <Redirect to={{
              pathname: '/login',
              state: { from: props.location }
            }} />
          )
        }
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
	// console.log('state', state)
	return {
	  isAuthenticated: state.user.isLoggedIn,
	  admin: state.user.admin
	}
}

const PrivateRoute = connect(
	mapStateToProps
)(PrivateRouteContainer)

const Login = withStyles(styles)(connect(null, dispatch => ({
  cb: () => {
    dispatch(push('/'))
  }
}))(LoginContainer))

class App extends React.Component {
	render() {
		const { history, admin } = this.props;
		// console.log('admin', admin)
		return (
			<ConnectedRouter history={history}>
				<Switch>
					<Route path="/login" component={Login} />
					<Route path="/ActivateAccount/:id" component={ActivateAccount} />
					<Route path="/ResetPassword/:id" component={ResetPassword} />
					<PrivateRoute exact path="/SiteDashboard" component={Dashboard} />
					<PrivateRoute exact path="/" hideSwitcher={true} component={AllProjects} />
					<PrivateRoute exact path="/ProgressOverview" component={ProgressOverview} />
					<PrivateRoute exact path="/Contacts" component={Contacts} />
					<PrivateRoute exact path="/Documents" component={Documents} />
					<PrivateRoute exact path="/Photos" component={Photos} />
					{ admin ? <PrivateRoute exact path="/Admin" hideSwitcher={true} component={Admin} /> : null }
					{/* <PrivateRoute exact path="/Admin" hideSwitcher={true} component={Admin} /> */}
					<Route component={NoMatch} />
				</Switch>
			</ConnectedRouter>
		)
	}
}

const NoMatch = ({location}) => (
	<Grid container justify={'center'} alignItems={'center'} spacing={16}>
		<Grid item xs={12} md={3}>
			<img alt='GeoEngineers Logo' src={logo}/>
			<h1>404</h1>
			<Typography variant='h6' paragraph>
				{`Sorry, ${location.pathname} does not exist.`}
			</Typography>
			<Button href='/' variant='outlined'>
				Return to Dashboard
			</Button>
		</Grid>
	</Grid>
)

export default withStyles(styles)(connect(
	mapStateToProps
)(App));
