import Config from '../config';

export default function(){
	let hostUrl = '';
	// console.log('process.env', process.env)
	// console.log('window', window)
	if (process.env.NODE_ENV === 'production'){
		hostUrl = Config.hostUrlProd;
	} else {
		// hostUrl = 'https://192.168.10.2:20832'
		// hostUrl = 'http://192.168.140.218:20832'
		hostUrl = Config.hostUrlDev;
	}
	return hostUrl;
}
