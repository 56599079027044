import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
// import IconButton from '@material-ui/core/IconButton'
// import EditIcon from '@material-ui/icons/Edit'
// import MenuItem from '@material-ui/core/MenuItem'
// import Tooltip from '@material-ui/core/Tooltip'
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid'
// import DateTimePicker from 'material-ui-pickers/DateTimePicker';
import { updateContact, deleteContact } from '../../../actions/contacts';
import clone from 'lodash/clone';

const mapStateToProps = (state, ownProps) => {
	return {
		contacts: state.contacts,
		user: state.user,
		// organizationList: state.contacts.organizationList,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		updateContact: (update) => { dispatch(updateContact(update)) },
		deleteContact: (contact) => { dispatch(deleteContact(contact))}
	}
}

const styles = theme => ({
	formControl: {
		width: '100%'
	}
})

class ContactForm extends React.Component {
	constructor(props){
		super(props);
		const { contact } = this.props
		// console.log('contact', this.props.contact)
		this.state = {
			id: contact ? clone(contact.id) : null,
			organizationId: contact ? clone(contact.organizationId) : null,
			firstName: contact ? clone(contact.firstName) : "",
			lastName: contact ? clone(contact.lastName) : "",
			phoneNumber: contact ? clone(contact.phoneNumber) : "",
			email: contact ? clone(contact.email) : "",
			jobTitle: contact ? clone(contact.jobTitle) : "",
			inactive: contact ? clone(contact.inactive) : false,
		};
		this.handleChange = this.handleChange.bind(this);
		// this.handleDateChange = this.handleDateChange.bind(this);
		// this.handleSubmit = this.handleSubmit.bind(this);
		this.props.wrapperContext.setupFormContext = this;
	}

	handleChange(event) {
		// console.log('event', event)
		this.setState({
			[event.target.name]: event.target.value
		})
		// console.log('this.state', this.state)
	}

	render() {
		// console.log('this.state', this.state)
		const { organizationId, firstName, lastName, phoneNumber, email, jobTitle } = this.state
		// console.log('this.props', this.props)
		const { organizationList } = this.props.contacts
		const { classes } = this.props
		// console.log('organizations', organizations)
		return (
			<form id='contactForm'>
				<Grid container spacing={16}>
					<Grid item xs={12} md={6}>
						<TextField
							autoFocus
							name="firstName"
							margin="dense"
							id="firstName"
							label="First Name"
							type="text"
							// validate={required}
							defaultValue={firstName}
							InputLabelProps={{shrink:true}}
							fullWidth
							onChange={this.handleChange}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							autoFocus
							name="lastName"
							margin="dense"
							id="lastName"
							label="Last Name"
							type="text"
							// validate={required}
							defaultValue={lastName}
							InputLabelProps={{shrink:true}}
							fullWidth
							onChange={this.handleChange}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							autoFocus
							name="phoneNumber"
							margin="dense"
							id="phoneNumber"
							label="Phone Number"
							type="text"
							// validate={required}
							defaultValue={phoneNumber}
							InputLabelProps={{shrink:true}}
							fullWidth
							onChange={this.handleChange}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							autoFocus
							name="email"
							margin="dense"
							id="email"
							label="Email"
							type="text"
							// validate={required}
							defaultValue={email}
							InputLabelProps={{shrink:true}}
							fullWidth
							onChange={this.handleChange}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							autoFocus
							name="jobTitle"
							margin="dense"
							id="jobTitle"
							label="Job Title"
							type="text"
							// validate={required}
							defaultValue={jobTitle}
							InputLabelProps={{shrink:true}}
							fullWidth
							onChange={this.handleChange}
						/>
					</Grid>
					{/* <Grid item xs={12} md={6}>
						<FormControl className={classes.formControl}>
							<InputLabel htmlFor="inactive-simple">Inactive</InputLabel>
							<Select
								fullWidth
								value={inactive}
								onChange={this.handleChange}
								inputProps={{
									name: 'inactive',
									id: 'inactive-simple'
								}}
							>
								<MenuItem value={true}>True</MenuItem>
								<MenuItem value={false}>False</MenuItem>
							</Select>
						</FormControl> */}
					{/* </Grid> */}
					<Grid item xs={12} md={6}>
						{/* select for organization */}
						<FormControl className={classes.formControl}>
							<InputLabel htmlFor="organization-simple">Organization</InputLabel>
							<Select
								fullWidth
								value={organizationId || 0}
								onChange={this.handleChange}
								inputProps={{
									name: 'organizationId',
									id: 'organization-simple'
								}}
							>
								{organizationList ? organizationList.map(org => <MenuItem key={org.uid} value={org.id}>{org.name}</MenuItem>) : false }
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</form>
		)
	}
}

const ContactFormWrapper = withStyles(styles)(ContactForm);

class DialogWrapper extends React.Component {
    state = {
        open: false
    };
	//
	// constructor(props){
	// 	super(props);
	// 	// console.log("this", this);
	// }

    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleDelete = () => {
		// console.log('this.props', this.props)
		this.props.deleteContact({
			contact: this.thisContact,
			cb: () => {
				this.setState({open: false})
				if(this.props.cb){ this.props.cb() }
			}
		})
	}

    handleClose = () => {
        this.setState({open: false});
    };

    handleSave = () => {
		var updateObject = this.setupFormContext.state;
		var foundContact = this.props.screenProps.contacts.filter((contactItem)=>{
			// console.log(contactItem.email.toLowerCase(), updateObject.email.toLowerCase());
			return contactItem.email.toLowerCase() === updateObject.email.toLowerCase();
		});
		if(foundContact.length && !updateObject.id){
			alert("A contact with this email already exists.");
			return false;
		}else{
			console.log("updateObject", updateObject);
	        this.props.updateContact({
	            contact: updateObject,
	            // site: this.props.site,
				cb: () => {
					// console.log("this", this);
					this.setState({open: false});
					if(this.props.cb){ this.props.cb() }
				}
	        })
		}

    }

    render() {
		let { contact, contacts, buttonTitle, className } = this.props
		this.thisContact = this.props.contact ? contacts.allContacts.filter(c => c.id === contact[5])[0] : null
		// console.log('thisContact', this.thisContact)
		let dialogTitle = contact ? "Edit" : buttonTitle;
        return (
			<div style={{display: 'inline'}}>
				<Button
					// style={contact ? { float: 'right'} : { marginTop: 15}}
					size="small"
					variant="outlined"
					className={className}
					// color="secondary"
					onClick={this.handleClickOpen}
				>
					{dialogTitle}
				</Button>
	            <Dialog maxWidth="md" open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
	                <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
	                <DialogContent>
	                    <ContactFormWrapper contact={this.thisContact} contacts={contacts} wrapperContext={this} onDelete={this.handleDelete}/>
	                </DialogContent>
	                <DialogActions>
	                    {/* <Button onClick={this.handleDelete}  disabled={!this.props.contact} color="default">
			            	Delete
			            </Button> */}
	                    <Button onClick={this.handleClose} color="primary">
	                        Cancel
	                    </Button>
	                    <Button onClick={this.handleSave} form='contactForm' color="secondary">
							{ this.props.contacts.allContactsLoading ? <CircularProgress size={20} /> : false }
	                        Save
	                    </Button>
	                </DialogActions>
	            </Dialog>
	        </div>
		);
    }
}

const ContactDialog = connect(
	mapStateToProps,
	mapDispatchToProps
)(DialogWrapper)

export default withStyles(styles)(ContactDialog);
