import HostUrl from '../../utils/hostUrl'
import { changeSelectedProject } from '../user'
// import { setSelectedSite } from './site'
// import { fetchDocumentFinals, fetchDocumentPhotos } from '../docs'

export const PROJECT_EDIT_START = 'PROJECT_EDIT_START'
export const PROJECT_EDIT_SUCCESS = 'PROJECT_EDIT_SUCCESS'
export const PROJECT_DELETE_SUCCESS = 'PROJECT_DELETE_SUCCESS'

let hostUrl = HostUrl();

/* ------- project actions -------- */

export function projectEditStart(project){
	return {
		type: PROJECT_EDIT_START,
		project
	}
}

export function projectEditSuccess(project){
	return {
		type: PROJECT_EDIT_SUCCESS,
		project
	}
}

export function editProjectDetails(item){
	return function(dispatch, getState){
		// console.log("item", JSON.stringify(item));
		// console.log('state', getState())
		dispatch(projectEditStart(item.project))
		let postObject = {
			...item.project
		}
		if (postObject.id === null || postObject.id === undefined) {
			delete postObject.id
		}
		// console.log('item.project', item.project)
		// console.log('postObject', postObject)

		// console.log('postObject', postObject)
		let token = getState().user.userInfo.token

		return fetch(`${hostUrl}/AppProjectDelivery/AdminProject?authorization=${token}`,
			{
				"body": JSON.stringify(postObject),
				"headers": {
					'user-agent': 'ProjectDelivery',
					'content-type': 'application/json'
				},
				"method": 'POST'
			}
		)
		.then(response => response.json())
		.then(json => {
			if (json.name === 'EaError'){
				console.log('error', json)
			} else {
				fetch(`${hostUrl}/AppProjectDelivery/AdminProject/${json.id}?authorization=${token}`)
				.then(response => response.json())
				.then(json2 => {
					// console.log('json', json2)
					dispatch(projectEditSuccess(json2))
					item.cb()
				})
			}
		})
		// dispatch(projectEditSuccess(item.project))
	}
}

function projectDeleteSuccess(project){
	return {
		type: PROJECT_DELETE_SUCCESS,
		project
	}
}

export function deleteProject(item){
	// console.log('item', item)
	return function(dispatch, getState){
		let token = getState().user.userInfo.token
		dispatch(projectEditStart(item.project))
		return fetch(`${hostUrl}/AppProjectDelivery/AdminProject/${item.project.id}?authorization=${token}`,
			{
				// "body": JSON.stringify(postObject),
				"headers": {
					'user-agent': 'ProjectDelivery',
					'content-type': 'application/json'
				},
				"method": 'DELETE'
			}
		)
		.then(response => response.json())
		.then(json => {
			if (json.name === 'EaError'){
				console.log('error', json)
			} else {
				let newSelectedProject = Object.keys(getState().projects).filter(p => {
					return p !== 'selectedProjectId' && p !== item.project.uid
				})[0]
				dispatch(changeSelectedProject(newSelectedProject))
				dispatch(projectDeleteSuccess(item.project))
				item.cb()
			}
		})
	}
}
