import { createStore, applyMiddleware } from 'redux';
import rootReducer from  '../reducers';
import thunk from 'redux-thunk'
import {routerMiddleware} from 'react-router-redux';
import { createBrowserHistory } from 'history';

import { persistStore, persistReducer } from 'redux-persist'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
import createExpirationTransform from 'redux-persist-transform-expire'
// import generateDummyStore from './generateDummyStore'
export const history = createBrowserHistory();

// import uuid from 'uuid/v1';

// this will generate some bunk data on load
// eventually needs to be hydrated from database

// args are (projectCount, sitesPerProject, phasesPerSite, updatesPerPhase, contacts)

// const defaultState = generateDummyStore(5, 5, 4, 10)

// console.log('defaultState', defaultState)

const expireTransform = createExpirationTransform({
	expireKey: 'expireDate',
	defaultState: {}
})

const persistConfig = {
  	key: 'root',
  	storage,
	whitelist: ['user'], // only user will be persisted
	transforms: [expireTransform],
	stateReconciler: hardSet
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const createStoreWithMiddleware = applyMiddleware(thunk, routerMiddleware(history))(createStore)
export const store = createStoreWithMiddleware(persistedReducer);
export const persistor = persistStore(store)
