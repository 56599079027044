import React from 'react';
// import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
// import DateTimePicker from 'material-ui-pickers/DateTimePicker'
// import clone from 'lodash/clone'
import omit from 'lodash/omit'

const styles = theme => ({
})

class GenericDialog extends React.Component {

	constructor(props){
		super(props);
		// this.formState = {}
		this.state = {
			open: false,
		};

		this.handleChange = this.handleChange.bind(this)
		this.createFormItems = this.createFormItems.bind(this)
		// this.formItems = []
		// Generic handlers
		this.onSaveCallback = (this.props.onSaveCallback || this.props.onOkCallback) ? (this.props.onSaveCallback || this.props.onOkCallback) : () => console.log('No onSaveCallback set')
		this.onDeleteCallback = this.props.onDeleteCallback ? this.props.onDeleteCallback : () => console.log('No onDeleteCallback set')
		this.onCancelCallback = this.props.onCancelCallback ? this.props.onCancelCallback : () => console.log('No onCancelCallback set')
	}

	componentDidMount(){
		this.props.dialogFields.forEach(formItem => {
			this.setState({
				[formItem.field]: this.props.data && this.props.data[formItem.field] ? this.props.data[formItem.field] : ""
			})
		})
	}

	createFormItems(){
		return this.props.dialogFields.map(formItem => {
			if (formItem.type === 'text' || formItem.type === 'textarea' || !formItem.type) {
				// console.log('formItem', formItem)
				return (
					<TextField
						key={formItem.name}
						autoFocus
						name={formItem.field}
						margin="dense"
						id={formItem.field}
						label={formItem.name}
						type="text"
						multiline={formItem.type && formItem.type === 'textarea' ? true : false}
						rows={formItem.type && formItem.type === 'textarea' ? 4 : null}
						// validate={required}
						value={this.state[formItem.field]}
						InputLabelProps={{shrink:true}}
						fullWidth
						onChange={this.handleChange}
					/>
				)
			} else if (formItem.type === 'number'){
				return (
					<TextField
						key={formItem.name}
						autoFocus
						name={formItem.field}
						margin="dense"
						id={formItem.field}
						label={formItem.name}
						type="number"
						// multiline={formItem.type && formItem.type === 'textarea' ? true : false}
						// rows={formItem.type && formItem.type === 'textarea' ? 4 : null}
						// validate={required}
						value={this.state[formItem.field]}
						InputLabelProps={{shrink:true}}
						fullWidth
						onChange={this.handleChange}
					/>
				)
			} else {
				return false
			}
		})
	}

	handleChange(event) {
		// console.log('event', event)
		this.setState({
			[event.target.name]: event.target.value
		})
		// console.log('this.state', this.state)
	}

	// handleDateChange(event){
	// 	this.handleChange({target:{name: "startedAt", value: event}})
	// }

    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false})
    };

    handleDelete = (event) => {
		this.onDeleteCallback(this.state)
		this.handleClose()
	}

	handleCancel = () => {
		this.onCancelCallback(this.state)
        this.handleClose()
    };

    handleSave = () => {
		// console.log('this.state', this.state)
		// return all but the open state, which is just used internally here
		this.onSaveCallback(omit(this.state, 'open'))
		this.handleClose()
    }

    render() {
		// console.log('this.props', this.props)
		// const { classes } = this.props
		// const { dialogFields }
		let DialogButton = this.props.dialogButton ? this.props.dialogButton : <Button>Open dialog</Button>
		let dialogTitle = this.props.dialogTitle ? this.props.dialogTitle : "Edit Item"
		let className = this.props.className ? this.props.className : {};
		let styles = this.props.styles ? this.props.styles : "";

		let showDelete = this.props.showDelete !== undefined ? this.props.showDelete : true;
		let deleteText = this.props.deleteText !== undefined ? this.props.deleteText : "Delete";
		let showCancel = this.props.showCancel !== undefined ? this.props.showCancel : true;
		let cancelText = this.props.cancelText !== undefined ? this.props.cancelText : "Cancel";
		let showSave = this.props.showSave !== undefined ? this.props.showSave : true;
		let saveText = this.props.saveText !== undefined ? this.props.saveText : "Save";

		let DialogButtons = [];
		if(showDelete){
			DialogButtons.push( (
				<Button key="handleDeleteButtonItem" onClick={this.handleDelete} disabled={!this.props.onDeleteCallback} color="primary">
					{deleteText}
				</Button>
			) )
		}
		if(showCancel){
			DialogButtons.push( (
				<Button key="handleCancelButtonItem" onClick={this.handleCancel} color="primary">
					{cancelText}
				</Button>
			) )
		}
		if(showSave){
			DialogButtons.push( (
				<Button key="handleSaveButtonItem" onClick={this.handleSave} color="primary">
					{saveText}
				</Button>
			) )
		}

        return (
			<div styles={styles} className={className}>
				<DialogButton onClick={this.handleClickOpen}/>
	            <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
	                <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
	                <DialogContent>
						{this.createFormItems()}
	                </DialogContent>
	                <DialogActions>
						{DialogButtons}
	                </DialogActions>
	            </Dialog>
	        </div>
		)
    }
}

export default withStyles(styles)(GenericDialog);
