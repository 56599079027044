import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import MUIDataTable from 'mui-datatables'
import { withStyles } from '@material-ui/core/styles'
// import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
// import Switch from '@material-ui/core/Switch'
import sortBy from 'lodash/sortBy';

import {fetchOrganizationList} from '../../../actions/contacts'

import ContactDialog from '../../Contacts/ContactsManagement/ContactDialog'

import hostUrl from '../../../utils/hostUrl'

let hosturl = hostUrl();

/* -------- NOTE ---------- */
/* This component is largely disconnected from redux */

const styles = theme => ({
	paper: {
    	padding: theme.spacing.unit * 2,
		// marginTop: 20,
    	// textAlign: 'center',
    	color: theme.palette.text.secondary
	},
	addContactButton: {
		marginTop: 15,
	}
})

const mapDispatchToProps = dispatch => {
	return {
		fetchOrganizationList: () => dispatch(fetchOrganizationList())
	}
}

const mapStateToProps = (state, ownProps) => {
	// console.log('statestate', state)
	return {
		token: state.user.userInfo.token,
		loggedInContactId: state.user.userInfo.contactId,
		organizations: state.contacts.organizationList
	}
}

class Users extends React.Component {
	state = {
		// organizations: [],
		contacts: [],
		// users: [],
		licenseTypes: [],
		usersLoading: false,
		page: 1
	}

	constructor(props){
		super(props);
		this.fetchUsers = this.fetchUsers.bind(this);
	}

	componentDidMount(){
		this.setState({
			usersLoading: true
		})

		this.props.fetchOrganizationList()

		// fetch license types for... stuff
		fetch(`${hosturl}/EaLogin/LicenseType?authorization=${this.props.token}`)
			.then(r => r.json())
			.then(json => {
				this.setState({licenseTypes: json})
				fetch(`${hosturl}/EaLogin/LicenseStatusType?authorization=${this.props.token}`)
					.then(r => r.json())
					.then(json => {
						this.setState({licenseStatusTypes: json})
						this.fetchUsers();
					});
			});
	}

	fetchUsers(){
		// Since we're using a composite view to drive the beast, we have to fetch it every time we make a change.
		// Otherwise we're managing several parts of the composite in client. Not ideal but it works.
		this.setState({usersLoading: true})

		fetch(`${hosturl}/AppProjectDelivery/AdminContactComposite?authorization=${this.props.token}`)
			.then(r => r.json())
			.then(json => {
				// console.log('json', json)
				this.setState({contacts: json, usersLoading: false})
			})
	}

	activateUser(rowData, value){
		let items = [...this.state.contacts]
		let itemIndex = this.state.contacts.findIndex(i => i.id === rowData[0])
		let item = {...items[itemIndex]}
		// console.log('item', this.state.contacts[rowData.rowIndex])
		// console.log('rowData', rowData, 'value', value)
		// console.log('item', item)

		let newPostObject = {
			contactId: item.id,
			noEmail: false
		}

		if(item.email.indexOf("@geoengineers.com") > -1){
			newPostObject.noEmail=true;
			newPostObject.licenseStatus="ACTIVE";
		}
		// console.log("newPostObject", newPostObject);

		fetch(`${hosturl}/EaLogin/ActivateAccountFromContact?authorization=${this.props.token}`,
			{
				"body": JSON.stringify(newPostObject),
				"headers": {
					'user-agent': 'ProjectDelivery',
					'content-type': 'application/json'
				},
				"method": 'POST'
			}
		)
		.then(response => response.json())
		.then(json => {
			// console.log('json', json)
			// Might also need to hit the reset password endpoint here?
			this.fetchUsers()
		})
		.catch(err => console.log('erroooor', err))
	}

	resendUserActivation(rowData, value){
		if(window.confirm("Are you sure you would like to resend a user activation for this user?")){
			this.activateUser(rowData, value);
		}
	}

	//maybe delete the license instead?  It's way easier...

	deactivateUser(rowData, value){
		// console.log("rowData, value", rowData, value);

		let items = [...this.state.contacts]
		let itemIndex = this.state.contacts.findIndex(i => i.id === rowData[0])
		let item = {...items[itemIndex]}
		// console.log('item', this.state.contacts[rowData.rowIndex])
		// console.log('rowData', rowData, 'value', value)
		// console.log("item", item);

		// let newPostObject = {
		// 	id: item.licenses[0].id
		// }

		fetch(`${hosturl}/AppProjectDelivery/AdminLicense/`+item.licenses[0].id+`?authorization=${this.props.token}`,
			{
				//"body": JSON.stringify(newPostObject),
				"headers": {
					'user-agent': 'ProjectDelivery',
					'content-type': 'application/json'
				},
				"method": 'DELETE'
			}
		)
		.then(response => response.json())
		.then(json => {
			// console.log('json', json)
			// Might also need to hit the reset password endpoint here?
			this.fetchUsers()
		})
		.catch(err => console.log('erroooor', err))

 	}

	postContact(contact){
		// console.log('contact', contact)
		fetch(`${hosturl}/AppProjectDelivery/AdminContact?authorization=${this.props.token}`,
			{
				"body": JSON.stringify(contact),
				"headers": {
					'user-agent': 'ProjectDelivery',
					'content-type': 'application/json'
				},
				"method": 'POST'
			}
		)
		.then(response => response.json())
		.then(json => {
			// console.log('json', json)
			this.fetchUsers()
		})
		.catch(err => console.log('erroooor', err))
	}

	changeOrganization(rowData, value){
		let items = [...this.state.contacts]
		let itemIndex = items.findIndex(i => i.id === rowData[0])
		let item = {...items[itemIndex]}
		// console.log("items", items);
		// console.log("itemIndex", itemIndex);
		// console.log("item", item);
		let postObject = {
			id: item.id,
			organizationId: value
		}
		// console.log('postObject', postObject)
		this.postContact(postObject)
	}

	postLicense(license){
		// console.log('license', license)

		fetch(`${hosturl}/AppProjectDelivery/AdminLicense?authorization=${this.props.token}`,
			{
				"body": JSON.stringify(license),
				"headers": {
					'user-agent': 'ProjectDelivery',
					'content-type': 'application/json'
				},
				"method": 'POST'
			}
		)
		.then(response => response.json())
		.then(json => {
			// console.log('json', json)
			this.fetchUsers()
		})
		.catch(err => console.log('erroooor', err));
	}

	changeLicense(licenseId, licenseTypeId){
		let postObject = {
			id: licenseId,
			licenseTypeId: licenseTypeId
		}
		// console.log('postObject', postObject)
		// console.log("this.state.licenseTypes", this.state.licenseTypes);
		this.postLicense(postObject)
	}

	render(){
		const {usersLoading, contacts, licenseTypes} = this.state
		const { organizations, loggedInContactId, classes } = this.props
		if (usersLoading) {
			return (
				<Typography >
					Loading users...
				</Typography>
			)
		} else {
			// console.log('contacts', contacts)
			// console.log('organizations', organizations)
			// console.log('licenseTypes', licenseTypes)
			let data = contacts ? sortBy(contacts, 'licenses', 'firstName').map(u => {
					return [
						u.id,
						// u.lastName || "",
						// u.firstName || "",
						`${u.firstName} ${u.lastName}`,
						u.email || "",
						// u.jobTitle || "",
						u.organization.id,
						u.id,
						u.id,
						// u.inactive
					]
				}) : [ [" "] ]
			// console.log('data', data)
			return (
				<div>
					<MUIDataTable
						title="Users"
						data={data}
						columns={[
							"ID",
							// "Last Name",
							// "First Name",
							"Full Name",
							"Email",
							// "Job Title",
							{
								name: "Organization",
								options: {
									customBodyRender: (v, m, u) => {
										// console.log('v', v, 'm', m, 'u', u)
										return (
											<Select
												value={v}
												onChange={(e) => this.changeOrganization(m.rowData, e.target.value)}
											>
												{organizations.map(o => {
													return <MenuItem key={o.uid} value={o.id}>{o.name}</MenuItem>;
												})}
											</Select>
										)
									}
								}
							},
							{
								name: "License Type",
								options: {
									customBodyRender: (v, m, u) => {
										// console.log('v', v, 'm', m, 'u', u)
										let contact = contacts.find(c => c.id === v);
										// console.log('contact', contact)
										let licenseId = contact.licenses !== null ? contact.licenses[0].id : null;
										let licenseTypeId = contact.licenses !== null ? contact.licenses[0].licenseType.id : 0;

										// var licenseId = v && v.licenses && v.licenses[0].id ? v.licenses[0].id : null;
										// var licenseTypeId = v && v.licenses && v.licenses[0].licenseType ? v.licenses[0].licenseType.id : null;
										// console.log("licenseTypeId", licenseTypeId);
										return (
											<Select
												disabled={v === loggedInContactId || licenseTypeId === 0}
												value={licenseTypeId}
												onChange={(e) => this.changeLicense(licenseId, e.target.value)}
											>
												{licenseTypes.map(l => {
													return <MenuItem key={`${licenseId}-${l.id}`} value={l.id}>{l.name}</MenuItem>;
												})}
												{licenseTypeId === 0 ? <MenuItem key={'no-license'} value={0}>No license</MenuItem> : null}
											</Select>
									);
									}
								}
							},
							{
								name: "License",
								options: {
									customBodyRender: (v, m, u) => {
										// console.log("this", this);
										// console.log('v', v, 'm', m, 'u', u)
										let activeLicenseType = _.find(this.state.licenseStatusTypes, licenseStatusType => {
											return licenseStatusType.name === "ACTIVE";
										});
										let assignedLicenseType = _.find(this.state.licenseStatusTypes, licenseStatusType => {
											return licenseStatusType.name === "ASSIGNED";
										});

										let contact = contacts.find(c => c.id === v);
										let license = contact.licenses !== null ? contact.licenses[0] : null;

										// console.log("this", this.props.loggedInContactId, v);
										if(license && license.statusTypeId === activeLicenseType.id){
											return (
												<Button
													disabled={loggedInContactId === v}
													color="default"
													onClick={e => this.deactivateUser(m.rowData, false)}
													variant="outlined"
												>
													Deactivate
												</Button>
											)
										}else if(license && license.statusTypeId === assignedLicenseType.id){
											return (
												<Button
													color="secondary"
													onClick={e => this.resendUserActivation(m.rowData, true)}
													variant="outlined"
												>
													Pending (Resend Activation)
												</Button>
											)
										}else{
											return (
												<Button
													color="secondary"
													onClick={e => this.activateUser(m.rowData, true)}
													variant="outlined"
												>
													Activate
												</Button>
											)
										}
									}
								}
							},
						]}
						options={{
							rowsPerPage: 10,
							selectableRows: 'none',
							sort: true,
							filter: false,
							print: false,
							download: false,
							viewColumns: false,
							pagination: true,
							page: this.state.page,
							onChangePage: (p => this.setState({page: p})),
							responsive: 'scrollMaxHeight'
						}}
					/>
					<ContactDialog className={classes.addContactButton} buttonTitle="Add contact" cb={this.fetchUsers} screenProps={{contacts:contacts}} />
				</div>
			)
		}
	}
}

const UsersWrapper = connect(
	mapStateToProps,
	mapDispatchToProps
)(Users)

export default withStyles(styles)(UsersWrapper)
