import React from 'react'
import {connect} from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import AddIcon from '@material-ui/icons/Add'
import Tooltip from '@material-ui/core/Tooltip'
import CircularProgress from '@material-ui/core/CircularProgress';
import DateTimePicker from 'material-ui-pickers/DateTimePicker';

import { addProgressToPhase, deleteProgressUpdateFromPhase } from '../../actions/projectOverview';


const styles = theme => ({
	admin: {
		color: theme.palette.admin.main
	}
})

const mapStateToProps = (state, ownProps) => {
	// console.log("ownProps", ownProps)
	// console.log("state", state)
	return {
		site: state.sites[state.user.selectedSiteId],
		phase: state.phases[ownProps.phase.uid]
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onAddUpdate: (update) => { dispatch(addProgressToPhase(update)) },
		onDeleteUpdate: (update) => {
			// console.log('update', update)
			dispatch(deleteProgressUpdateFromPhase(update))
		}
	}
}

// Validation function
// const required = value => (value == null ? 'Required' : undefined)

class UpdateForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			selectedDate: this.props.update ? this.props.update.date : new Date(),
			shiftsSinceLastUpdate: this.props.update ? this.props.update.shiftsSinceLastUpdate : 1,
			feetSinceLastUpdate: this.props.update ? this.props.update.feetSinceLastUpdate: 0,
			comment: this.props.update ? this.props.update.comment : "",
			percentSinceLastUpdate: this.props.update ? this.props.update.percentSinceLastUpdate : 0
		};
		this.showDelete = this.props.update ? true : false;
		this.state.id = this.props.update ? this.props.update.id : false;
		this.handleChange = this.handleChange.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
		//this.handleSubmit = this.handleSubmit.bind(this);
		this.props.wrapperContext.setupFormContext = this;
		// this.handleDelete = this.handleDelete.bind(this);
	}

	handleChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		})
	}

	// handleDelete(event){
	// 	event.preventDefault();
	// 	this.props.onDelete(this.state)
	// }

	handleDateChange(event){
		// console.log('event', event)
		this.handleChange({target:{name: "selectedDate", value: event}})
	}

	render() {
		const { selectedDate, shiftsSinceLastUpdate, feetSinceLastUpdate, percentSinceLastUpdate, comment } = this.state;
		const { phase, values } = this.props;
		// console.log('values', values)
		return (
			<form id='updateForm'>
				<DateTimePicker
					fullWidth
					keyboard={true}
					value={selectedDate}
					id="selectedDate"
					name="selectedDate"
					onChange={this.handleDateChange}
				/>
				<TextField
					// autoFocus
					id="shiftsSinceLastUpdate"
					name="shiftsSinceLastUpdate"
					// validate={required}
					value={shiftsSinceLastUpdate}
					type="number"
					label="Shifts since last update"
					fullWidth
					onChange={this.handleChange}
				/>
				{ phase.type && phase.type === 'length' ? <TextField
					// autoFocus
					id="feetSinceLastUpdate"
					name="feetSinceLastUpdate"
					// validate={required}
					margin="dense"
					label="Feet since last update"
					type="number"
					value={feetSinceLastUpdate}
					fullWidth
					onChange={this.handleChange}
				/> : null}
				{ phase.type && phase.type === 'percent' ? <TextField
					// autoFocus
					id="percentSinceLastUpdate"
					name="percentSinceLastUpdate"
					// validate={required}
					margin="dense"
					min={0}
					max={values.totalProgress ? 100 - values.totalProgress : values.cumulativeProgress ? 100 - values.cumulativeProgress : 100} // should eventually reflect only available percent
					label="Percent complete since last update"
					type="number"
					value={percentSinceLastUpdate}
					fullWidth
					onChange={this.handleChange}
				/> : null }
				<TextField
					id="comment"
					name="comment"
					multiline={true}
					rows={4}
					margin="dense"
					label="Comment"
					type="text"
					value={comment}
					fullWidth
					onChange={this.handleChange}
				/>
			</form>
		)
	}
}

class DialogWrapper extends React.Component {

	state = {
		open: false,
	};

	handleClickOpen = () => {
		this.setState({ open: true });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	handleDelete = (event) => {
		// this.props.onDeleteUpdate(this.props.update, this.props.phase)
		// console.log('delete this', this.props.update)
		this.props.onDeleteUpdate({
			update: this.props.update,
			phase: this.props.phase,
			site: this.props.site,
			cb: () => this.setState({ open: false })
		})
	};

	handleSave = () => {
		var updateObject = this.setupFormContext.state;
		this.props.onAddUpdate({
			...updateObject,
			phase: this.props.phase,
			cb: () => this.setState({ open: false })
		})

	}

	render() {
		const { classes, values } = this.props;
		let dialogTitle = this.props.update ? "Edit Progress Update" : "Add Progress Update"
		return (
			<div style={!this.props.children ? {display:'inline-block'} : null}>
				{
					this.props.children ?
						<div
							onClick={this.handleClickOpen}
						>
							{this.props.children}
						</div>
					:
						<Tooltip id="tooltip-setup" placement="top" title="Add Progress">
							<IconButton
								className='addNewUpdate'
								size="small"
								onClick={this.handleClickOpen}
							>
								<AddIcon className={classes.admin} />
							</IconButton>
						</Tooltip>
				}
				<Dialog
		          open={this.state.open}
		          onClose={this.handleClose}
		          aria-labelledby="form-dialog-title"
		        >
			        <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
			        <DialogContent>
						<UpdateForm onDelete={this.handleDelete} values={values} phase={this.props.phase} update={this.props.update} wrapperContext={this}/>
		          	</DialogContent>
		          	<DialogActions>
						<Button onClick={this.handleDelete} color="primary" disabled={!this.props.update}>
							Delete
						</Button> : null;
		            	<Button onClick={this.handleClose} id='cancelUpdate' color="primary">
		              		Cancel
		            	</Button>
		            	<Button onClick={this.handleSave} id='submitUpdate' form='updateForm' color="primary">
							{ this.props.phase && this.props.phase.inProgress ? <CircularProgress size={20} /> : "" }
		              		Save
		            	</Button>
		          	</DialogActions>
		        </Dialog>
			</div>
		);
	}
}

const UpdateDialog = withStyles(styles)(connect(
	mapStateToProps,
	mapDispatchToProps
)(DialogWrapper))

export default UpdateDialog;
