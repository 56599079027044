import React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
// import IconButton from '@material-ui/core/IconButton'
// import EditIcon from '@material-ui/icons/Edit'
// import AddIcon from '@material-ui/icons/Add'
// import Tooltip from '@material-ui/core/Tooltip'
// import CircularProgress from '@material-ui/core/CircularProgress';
// import DateTimePicker from 'material-ui-pickers/DateTimePicker';
import { editOrganization } from '../../../actions/contacts'
import clone from 'lodash/clone'

const mapStateToProps = (state, ownProps) => {
	return {
		// site: state.sites[state.user.selectedSiteId],
		// project: state.projects[state.user.selectedProjectId]
	}
}

const mapDispatchToProps = dispatch => {
	return {
		editOrganization: (update) => dispatch(editOrganization(update)),
		// onDeletePhase: (update) => dispatch(deletePhase(update))
	}
}

// Validation function
// const required = value => (value == null ? 'Required' : undefined)

class OrgDialog extends React.Component {
	constructor(props){
		super(props);
		// should add more fields here eventually
		this.state = {
			name: this.props.org ? clone(this.props.org.name) : "",
			id: this.props.org ? clone(this.props.org.id) : null
		};
		this.handleChange = this.handleChange.bind(this);
		// this.handleDateChange = this.handleDateChange.bind(this);
		// this.handleSubmit = this.handleSubmit.bind(this);
		this.props.wrapperContext.setupFormContext = this;
	}

	handleChange(event) {
		// console.log('event', event)
		this.setState({
			[event.target.name]: event.target.value
		})
		// console.log('this.state', this.state)
	}

	handleSubmit(event){
		// console.log('this', this.state)
		// console.log('event', event)
		event.preventDefault();
		this.props.onSubmit(this.state)
	}
	render() {
		// console.log('this.state', this.state)
		const { name } = this.state;
		return (
			<form id='setupForm' onSubmit={this.handleSubmit}>
				<TextField
					autoFocus
					name="name"
					margin="dense"
					id="name"
					label="Name"
					type="text"
					// validate={required}
					defaultValue={name}
					InputLabelProps={{shrink:true}}
					fullWidth
					onChange={this.handleChange}
				/>
			</form>
		)
	}
}

class DialogWrapper extends React.Component {
    state = {
        open: false
    };

    handleClickOpen = () => {
        this.setState({open: true});
    };

    // handleDelete = (event) => {
	//
	// 	this.props.onDeletePhase({
	// 		// update: this.props.update,
	// 		phase: this.props.phase,
	// 		site: this.props.site,
	// 		project: this.props.project,
	// 		cb: () => this.setState({ open: false })
	// 	})
	// }

    handleClose = () => {
        this.setState({open: false});
    };

    handleSave = () => {
		// console.log('this.props', this.props)
		var updateObject = this.setupFormContext.state;
		// console.log('updateObject', updateObject)
        this.props.editOrganization({
            updateObject,
			cb: () => this.setState({open: false})
        })
    }

    render() {
		let dialogTitle = this.props.org ? "Edit organization details" : "Add organization";
        return (
			<div style={this.props.displayStyle}>
				<Button size="small" variant="outlined"
					style={this.props.org ? {} : {marginTop: 15}}
					onClick={this.handleClickOpen}
					>
					{(this.props.org ? "Edit" : "Add") + " Organization"}
				</Button>
	            <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
	                <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
	                <DialogContent>
	                    <OrgDialog org={this.props.org} wrapperContext={this}/>
	                </DialogContent>
	                <DialogActions>
	                    {/* <Button onClick={this.handleDelete} disabled={!this.props.org} color="primary">
			            	Delete
			            </Button> */}
	                    <Button onClick={this.handleClose} color="primary">
	                        Cancel
	                    </Button>
	                    <Button onClick={this.handleSave} form='setupForm' color="primary">
	                        Save
	                    </Button>
	                </DialogActions>
	            </Dialog>
	        </div>
		)
    }
}

const WrappedDialog = connect(
	mapStateToProps,
	mapDispatchToProps
)(DialogWrapper)

export default WrappedDialog;
