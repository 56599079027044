import React from 'react'
import { connect } from 'react-redux'
// import MUIDataTable from 'mui-datatables'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
// import Button from '@material-ui/core/Button'
// import Select from '@material-ui/core/Select'
// import MenuItem from '@material-ui/core/MenuItem'
// import DocIcon from '@material-ui/icons/InsertDriveFile'
// import FolderIcon from '@material-ui/icons/Folder'
// import ImageIcon from '@material-ui/icons/Image'
// import Toolbar from '@material-ui/core/Toolbar'
// import HomeIcon from '@material-ui/icons/Home'
// import IconButton from '@material-ui/core/IconButton'

// import HostURL from '../../utils/hostUrl'

// import Breadcrumbs, { BreadcrumbBack } from '../../utils/breadcrumbs'

// import sortBy from 'lodash/sortBy'

import { fetchDocuments, setSelectedDocumentFolder } from '../../actions/documents'
import GenericFileTable from '../../utils/GenericFileTable'

// let hosturl = HostURL()

const styles = theme => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
	},
	paper: {
    	// padding: theme.spacing.unit * 2,
		// marginTop: 20,
    	// textAlign: 'center',
    	color: theme.palette.text.secondary
    },
	downloadLink: {
		paddingLeft: 5
	},
	homeButton: {
		marginLeft: -10,
		marginRight: 5
	},
	breadcrumb: {
		float: 'left'
		// display: 'inline-block',
		// marginLeft: 10
	},
	paperTemp: {
		padding: '0 24px 24px 24px'
	},
})

const mapDispatchToProps = dispatch => {
	return {
		fetchDocuments: (payload) => dispatch(fetchDocuments(payload)),
		setSelectedDocumentFolder: (payload) => dispatch(setSelectedDocumentFolder(payload))
	}
}

const mapStateToProps = (state, ownProps) => {
	// console.log('documents statestate', state)
	// we might eventually only pass the state of the current site/crossing/project
	return {
		token: state.user.userInfo.token,
		selectedFolder: state.docs.selectedDocumentFolder,
		projectDocs: state.docs.documents[state.docs.selectedDocumentFolder] ? state.docs.documents[state.docs.selectedDocumentFolder] : [],
		docsLoading: state.docs.docsLoading,
		selectedSiteId: state.user.selectedSiteId,
		site: state.user.selectedSiteId && state.sites[state.user.selectedSiteId] ? state.sites[state.user.selectedSiteId] : null,
		projectNumber: state.user.selectedProjectId && state.projects[state.user.selectedProjectId] ? state.projects[state.user.selectedProjectId].projectNumber : null
	}
}
class Documents extends React.Component {

	// constructor(props){
	// 	super(props)
	// 	this.openFolder = this.openFolder.bind(this)
	// }

	componentDidMount(){
		// console.log('this.props', this.props)
		const { projectNumber, selectedFolder, fetchDocuments } = this.props
		// console.log('projectNumber', projectNumber);
		// console.log('selectedFolder', selectedFolder);
		function doIt(){
			return fetchDocuments({projectNumber, folder: selectedFolder})
		}
		
		if (projectNumber && selectedFolder){
			// this is a dumb hack because there are so many things happening here 
			// that the component doesn't seem to update correctly when they are finished
			this.stupidTimeout = setTimeout(doIt, 200)
		}
	}

	componentWillReceiveProps(nextProps){
		// console.log('nextProps', nextProps)
		const { selectedFolder, projectNumber } = nextProps
		// console.log('projectNumber', projectNumber);
		// console.log('selectedFolder', selectedFolder);
		if ((this.props.projectNumber !== projectNumber || this.props.selectedFolder !== selectedFolder) && projectNumber && selectedFolder){
			nextProps.fetchDocuments({projectNumber, folder: selectedFolder})
		}
	}

	componentWillUnmount(){
		clearTimeout(this.stupidTimeout)
	}

	render(){
		const {docsLoading, projectDocs, classes, projectNumber, selectedFolder} = this.props;
		return (
			<Paper className={classes.root}>
				<Typography variant='h6'>
					Documents
				</Typography>

				{docsLoading ? 
					<Typography className={classes.paperTemp}>
						Loading...
					</Typography>
					: (!projectNumber || !selectedFolder) ? 
					<Typography >
						{
							projectNumber && !selectedFolder
							? 'This project is associated with a Project Number but this site is not associated with a Sharepoint photos folder.'
							: 'This project is not associated with a Sharepoint Project.'
						}
					</Typography> 
					: 
					<GenericFileTable
						files={projectDocs}
						loading={docsLoading}
						token={this.props.token}
					/>
				}
			</Paper>
		)
	}
}

const DocumentsOverview = connect(
	mapStateToProps,
	mapDispatchToProps
)(Documents)

export default withStyles(styles)(DocumentsOverview)
