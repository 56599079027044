import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
// import Fab from '@material-ui/core/Fab'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
// import IconButton from '@material-ui/core/IconButton'
// import Button from '@material-ui/core/Button'
// import EditIcon from '@material-ui/icons/Edit'
// import AddIcon from '@material-ui/icons/Add'
import MenuItem from '@material-ui/core/MenuItem'
// import Tooltip from '@material-ui/core/Tooltip'
import CircularProgress from '@material-ui/core/CircularProgress'
// import DateTimePicker from 'material-ui-pickers/DateTimePicker';
import { editProjectDetails, deleteProject } from '../../actions/projectOverview'
import clone from 'lodash/clone'

const styles = theme => ({
	admin: {
		backgroundColor: theme.palette.admin.main,
		'&:hover': {
			backgroundColor: theme.palette.admin.dark
		}
	}
})

const mapStateToProps = (state, ownProps) => {
	// console.log('state', state)
	return {
		user: state.user,
		organizations: state.contacts.organizationList,
		// selectedProjectId: state.user.selectedProjectId
		// site: state.sites[state.user.selectedSiteId],
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onEditProjectDetails: (update) => dispatch(editProjectDetails(update)),
		onDeleteProject: (project) => dispatch(deleteProject(project))
	}
}

class SetupForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			name: this.props.project ? clone(this.props.project.name) : "",
			projectNumber: this.props.project ? clone(this.props.project.projectNumber) : "",
			// contactId: this.props.project ? clone(this.props.project.contactId) : null,
			organizationId: this.props.project ? clone(this.props.project.organizationId) : null,
			id: this.props.project ? clone(this.props.project.id) : null,
			active: this.props.project ? clone(this.props.project.active) : true,
			archived: this.props.project ? clone(this.props.project.archived) : false
		};
		this.handleChange = this.handleChange.bind(this);
		// this.handleDateChange = this.handleDateChange.bind(this);
		this.props.wrapperContext.setupFormContext = this;
	}

	handleChange(event, checked) {
		// console.log('name', event.target.name)
		// console.log('value', event.target.value)
		// console.log('checked', checked)
		let newValue = typeof(checked) === "boolean" && checked ? checked : event.target.value
		// let newValue = event.target.value || event.target.checked
		// console.log('newValue', newValue)
		// newValue = newValue === false ? newValue = "" : newValue
		this.setState({
			[event.target.name]: newValue
		})
		// console.log('this.state', this.state)
	}

	render() {
		// console.log('this.state', this.state)
		const { organizations } = this.props
		// console.log('organizations', organizations)
		const { name, projectNumber, organizationId, active, archived } = this.state;
		// console.log('active', active)
		return (
			<form id='setupForm'>
				<TextField
					autoFocus
					name="name"
					margin="dense"
					id="name"
					label="Project Name"
					type="text"
					// validate={required}
					value={name}
					InputLabelProps={{shrink:true}}
					fullWidth
					onChange={this.handleChange}
				/>
				<TextField
					autoFocus
					name="projectNumber"
					margin="dense"
					id="projectNumber"
					label="Project Number (no dashes)"
					type="text"
					// validate={required}
					value={projectNumber}
					InputLabelProps={{shrink:true}}
					fullWidth
					onChange={this.handleChange}
				/>
				<FormControl fullWidth>
					<InputLabel htmlFor='organization-name'>Organization</InputLabel>
					<Select
						id="organization-name"
						value={organizationId || -1}
						onChange={this.handleChange}
						// autoWidth={true}
						inputProps={{
							name: 'organizationId',
							id: 'organization-name'
						}}
					>
						{organizations.length ? organizations.map(o => {
								// console.log('o', o)
								return <MenuItem value={o.id} key={o.id}>{o.name}</MenuItem>
							}) : null
						}
					</Select>
				</FormControl>
				<FormControlLabel
					control={
						<Switch
							checked={active === "true" || active === true ? true : false}
							name='active'
							onChange={this.handleChange}
							value={!active}
						/>
					}
					label="Active"
				/>
				<FormControlLabel
					control={
						<Switch
							checked={archived === "true" || archived === true ? true : false}
							name='archived'
							onChange={this.handleChange}
							value={!archived}
							color='primary'
						/>
					}
					label="Archived"
				/>
			</form>
		)
	}
}

class DialogWrapper extends React.Component {
    state = {
        open: false
    };

    handleClickOpen = () => {
        this.setState({open: true});
    };

	handleDelete = (event) => {
		// console.log('this.props', this.props)
		this.props.onDeleteProject({
			project: this.props.project,
			cb: () => this.setState({ open: false })
		})
	}

    handleClose = () => {
        this.setState({open: false});
    };

    handleSave = () => {
		// console.log('updateObject', updateObject)
		var updateObject = this.setupFormContext.state;
        this.props.onEditProjectDetails({
            project: updateObject,
            // site: this.props.site,
			cb: () => this.setState({open: false})
        })

    }

    render() {
		// console.log('this.props', this.props)
		const {classes, organizations} = this.props
		// let DialogButton = this.props.dialogButton
		let dialogTitle = this.props.project ? "Edit project details" : "Add project";
		let DialogButton = this.props.dialogButton ? this.props.dialogButton : <Button>Project</Button>
        return (
			<div style={this.props.displayStyle}>
				<DialogButton onClick={this.handleClickOpen}/>
	            <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
	                <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
	                <DialogContent>
	                    <SetupForm organizations={organizations} classes={classes} project={this.props.project} wrapperContext={this}/>
	                </DialogContent>
	                <DialogActions>
	                    <Button onClick={this.handleDelete}  disabled={!this.props.project} color="primary">
			            	Delete
			            </Button>
	                    <Button onClick={this.handleClose} color="primary">
	                        Cancel
	                    </Button>
	                    <Button onClick={this.handleSave} form='setupForm' color="primary">
							{ this.props.user.inProgress ? <CircularProgress size={20} /> : false }
	                        Save
	                    </Button>
	                </DialogActions>
	            </Dialog>
	        </div>
		);
    }
}

const PhaseDialog = withStyles(styles)(connect(
	mapStateToProps,
	mapDispatchToProps
)(DialogWrapper))

export default PhaseDialog
