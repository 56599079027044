import React, { useEffect } from 'react'
import { connect } from 'react-redux'
// import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import CircularProgress from '@material-ui/core/CircularProgress'
// import Avatar from '@material-ui/core/Avatar'
// import PersonIcon from '@material-ui/icons/Person'
// import Button from '@material-ui/core/Button'
// import IconButton from '@material-ui/core/IconButton'
// import MoreVertIcon from '@material-ui/icons/MoreVert'
//
import ContactsManagement from './ContactsManagement'
import { fetchUserContacts, fetchOrganizationList } from '../../actions/contacts'
import { Link } from '@material-ui/core'

const styles = theme => ({
	paper: {
		paddingTop: theme.spacing.unit * 2,
		paddingLeft: theme.spacing.unit * 2,
		paddingRight: theme.spacing.unit * 2,
		// marginTop: 25,
		// textAlign: 'center',
		// color: theme.palette.secondary
	},
	h2: {
		marginTop: 0
	},
	contactCard: {
		marginLeft: theme.spacing.unit / 2,
		marginRight: theme.spacing.unit / 2,
		marginBottom: theme.spacing.unit,
	},
	cardHeader: {
		paddingTop: theme.spacing.unit,
		paddingBottom: theme.spacing.unit,
		background: theme.palette.secondary.main,
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 12,
			paddingRight: 12,
			paddingTop: 8,
			paddingBottom: 8
		}
		// color: theme.palette.secondary.contrastText
	},
	avatar: {
		[theme.breakpoints.down('sm')]: {
			marginRight: 2
		}
	},
	title: {
		color: theme.palette.common.white,
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem'
		}
	},
	cardContent: {
		// marginBottom: 10
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 12,
			paddingRight: 12,
			paddingTop: 8,
			paddingBottom: 8
		},
		'& p': {
			fontSize: '0.9rem',
		}
	}

})

const mapStateToProps = (state, ownProps) => {
	// console.log('statestate', state)
	// console.log('ownProps', ownProps)
	// we might eventually only pass the state of the current site/crossing/project
	let selectedSite = state.user.selectedSiteId ? state.sites[state.user.selectedSiteId] : null
	return {
		admin: state.user.admin,
		// selectedProjectId: state.user.selectedProjectId,
		selectedSiteId: state.user.selectedSiteId,
		// selectedProject: state.user.selectedProjectId ? state.projects[state.user.selectedProjectId] : null,
		selectedSite,
		organizations: state.contacts.organizationList,
		contacts: selectedSite ? state.contacts.userContacts.filter(contact => contact.siteId === selectedSite.id) : [],
		loading: state.contacts.userContactsLoading
	}
}

const mapDispatchToProps = dispatch => {
	return {
		fetchUserContacts: () => dispatch(fetchUserContacts()),
		fetchOrganizationList: () => { dispatch(fetchOrganizationList())}
	}
}

const ContactCard = withStyles(styles)(({siteContact, organizations, widget, classes}) => {
	// console.log('organizations', organizations)
	console.log('widget?', widget)

	// console.log('contact', contact)
	const { contact } = siteContact; // All the contact info is in a sub property
	// console.log('contact', contact)
	const { name } = organizations.find(o => o.id === contact.organizationId)
	return (
		<Grid item xs={12} md={widget ? 12 : 4}>
			<Card className={classes.contactCard}>
				<CardHeader
					className={classes.cardHeader}
					color="secondary"
					title={`${contact.firstName} ${contact.lastName}`}
					classes={{
						title: classes.title,
						avatar: classes.avatar
					}}
					titleTypographyProps={{variant:'title'}}
					// avatar={
					// 	<Hidden smDown>
					// 		<Avatar>
					// 			<PersonIcon/>
					// 		</Avatar>
					// 	</Hidden>
					// }
					>
				</CardHeader>
				<CardContent className={classes.cardContent}>
					<Typography component="p" variant="body1">
						Job title: <strong>{contact.jobTitle}</strong>
					</Typography>
					<Typography component="p" variant="body1">
						Organization: <strong>{name}</strong>
					</Typography>
					<Typography component="p" variant="body1">
						Email: {contact.email ? <Link href={`mailto:${contact.email}`}>{contact.email}</Link> : 'Not available'}
					</Typography>
					<Typography component="p" variant="body1">
						Phone: {contact.phoneNumber ? <Link href={`tel:${contact.phoneNumber}`}>{contact.phoneNumber}</Link> : 'Not available'}
					</Typography>
				</CardContent>
			</Card>
		</Grid>
	)
})

const Overview = ({admin, classes, widget, contacts, loading, organizations, fetchUserContacts, fetchOrganizationList}) => {
	// console.log('contacts', contacts)
	// const refContainer = useRef(null)
	// console.log('refContainer', refContainer.current.offsetWidth)

	useEffect(() => {
		fetchUserContacts()
		fetchOrganizationList()
	}, [])

	return (
		<Paper className={classes.paper}>
			<Grid container>
				<Grid item xs={8}>
					<Typography variant='h6'>Contacts</Typography>
				</Grid>
				{admin ? <Grid item xs={4}>
					<Hidden smDown>
						<ContactsManagement/>
					</Hidden>
				</Grid> : false }
			</Grid>
			<Grid container spacing={16}>
				
				{loading ? 
					<Grid item><Typography component="h4"><CircularProgress size={20}/> Loading contacts</Typography></Grid> 
				: contacts.length ? contacts.map(c => <ContactCard widget={widget} organizations={organizations} key={c.contact.uid} siteContact={c}/>) : <Grid item><Typography>No contacts</Typography></Grid>}
			</Grid>
		</Paper>
	)
}

const ContactsOverview = connect(
	mapStateToProps,
	mapDispatchToProps
)(Overview)

export default withStyles(styles)(ContactsOverview);
