import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import Users from './Users'
import Organizations from './Organizations'

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});

const Admin = ({classes}) => {
  let [value, setValue] = useState(0);
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={(e, v) => setValue(v)}>
          <Tab label="Users" />
          <Tab label="Organizations" />
          {/* <Tab label="Item Three" href="#basic-tabs" /> */}
        </Tabs>
      </AppBar>
      {value === 0 && <TabContainer><Users/></TabContainer>}
      {value === 1 && <TabContainer><Organizations/></TabContainer>}
      {/* {value === 2 && <TabContainer>Item Three</TabContainer>} */}
    </div>
  );
}

Admin.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Admin);
