import {
	// ADD_CONTACT,
	UPDATE_CONTACT_START,
	// REMOVE_CONTACT_START,
	USER_CONTACTS_FETCH_STARTED,
	USER_CONTACTS_FETCH_SUCCEEDED,
	ALL_CONTACTS_FETCH_STARTED,
	ALL_CONTACTS_FETCH_SUCCEEDED,
	SYNC_AD_CONTACTS_STARTED,
	SYNC_AD_CONTACTS_SUCCEEDED,
	ADD_CONTACT_TO_PROJECT_START,
	REMOVE_CONTACT_FROM_PROJECT_START,
	FETCH_ORGANIZATION_LIST_SUCCEEDED
	// ADD_CONTACT_TO_PROJECT_SUCCEEDED
} from '../../actions/contacts'
// import uniq from 'lodash/uniq'

const defaultState = {
	adContactSyncLoading: false,
	userContactsLoading: false,
	allContactsLoading: false,
	userContacts: [],
	allContacts: [],
	organizationList: []
	// projectContacts: [],
	// siteContacts: []
};
function manageContact(state = defaultState, action){
	// console.log('state', state)
	// console.log('action', action)
	switch (action.type) {
		case USER_CONTACTS_FETCH_STARTED:
			return Object.assign({}, state, {
				userContactsLoading: true
			});
		case USER_CONTACTS_FETCH_SUCCEEDED:
			// console.log('action.payload', action.payload)
			return Object.assign({}, state, {
				userContactsLoading: false,
				userContacts: action.payload
			})
		case ALL_CONTACTS_FETCH_STARTED:
			return Object.assign({}, state, {
				allContactsLoading: true
			})
		case ALL_CONTACTS_FETCH_SUCCEEDED:
			// console.log('action.payload', action.payload)
			return Object.assign({}, state, {
				adContactSyncLoading: false,
				allContactsLoading: false,
				allContacts: action.payload
			})
		case SYNC_AD_CONTACTS_STARTED:
			return Object.assign({}, state, {
				adContactSyncLoading: true,
			})
		case SYNC_AD_CONTACTS_SUCCEEDED:
			// console.log('state', state)
			// console.log('action.payload', action.payload)
			return Object.assign({}, state, {
				adContactSyncLoading: false,
			})
		case ADD_CONTACT_TO_PROJECT_START:
			return Object.assign({}, state, {
				userContactsLoading: true
			})
		case REMOVE_CONTACT_FROM_PROJECT_START:
			return Object.assign({}, state, {
				userContactsLoading: true
			})
		case UPDATE_CONTACT_START:
			return Object.assign({}, state, {
				allContactsLoading: true
			})
		// case REMOVE_CONTACT_START:
		// 	return Object.assign({}, state, {
		// 		allContactsLoading: true
		// 	})
		case FETCH_ORGANIZATION_LIST_SUCCEEDED:
			// console.log('action', action)
			return Object.assign({}, state, {
				userContactsLoading: false,
				organizationList: action.payload
			})
		default:
			return state
	}
}

export default manageContact;
