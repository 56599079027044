// import HostUrl from '../../utils/hostUrl'

import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles'
import { darken, fade } from '@material-ui/core/styles/colorManipulator'
// import Card from '@@material-ui/core/core/Card';
// import Grid from '@@material-ui/core/core/Grid';
import TextField from '@material-ui/core/TextField';
// import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden'
import CircularProgress from '@material-ui/core/CircularProgress';
import GenericDialog from '../../utils/GenericDialog';

import config from '../../config'
import {login, office365login, resetPassword} from "../../actions/user";
import office365logo from './office365logo.svg'
import msalAgent from '../../utils/msalAgent'

import { Typography } from '@material-ui/core';

// const hostUrl = HostUrl()

const styles = theme => ({
	forgotPasswordButton: {
		marginTop: 20,
		// marginBottom: 10,
	},
	loginPage: {
		// flexGrow: 1,
		// width: '50%',
		// margin: '0 auto'
	},
	version: {
		textAlign: 'center',
		// display: 'inline-block',
		// float: 'right',
		// textAlign: 'right',
		fontSize: '0.7em',
		fontWeight: '100',
		marginBottom: 0
	},
	office365: {
		// marginTop: 20,
		border: '1px solid #DC3E15',
		color: '#DC3e15',
		'&:hover': {
			border: `1px solid ${darken('#DC3e15', .25)}`,
			color: darken('#DC3e15', .25),
			backgroundColor: fade('#DC3e15', theme.palette.action.hoverOpacity),
			// Reset on touch devices, it doesn't add specificity
			'@media (hover: none)': {
			  backgroundColor: 'transparent',
			},
	  },
	  '& img': {
		  height: 18,
		  marginLeft: 5
	  }
	}
})

const mapStateToProps = (state, ownProps) => {
	// console.log('state', state)
	return {
		user: state.user
	}
}

const mapDispatchToProps = dispatch => {
	return {
		appLogin: (creds) => dispatch(login(creds)),
		appResetPassword: (creds) => dispatch(resetPassword(creds)),
		office365login: (token) => dispatch(office365login(token))
	}
}

class LoginForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleOffice365login = this.handleOffice365login.bind(this);
		this.handleForgotPassword = this.handleForgotPassword.bind(this);
	}

	handleOffice365login(){
		msalAgent.loginPopup()
			.then(response => {
				// console.log('response', response)
				msalAgent.acquireTokenPopup({scopes: ['user.read']})
					.then(tokenResponse => {
						// console.log('tokenResponse', tokenResponse)
						this.props.office365login({
							token: tokenResponse,
							cb: this.props.cb
						})
					})
					.catch(error => {
						console.log(error);
					});
			})
			.catch(err => {
				throw err;
			})

	}

	handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		}, () => {
			if (this.state.login && this.state.login.indexOf('@geoengineers') > -1){
				this.setState({
					disableLoginButton: true
				})
			} else {
				this.setState({
					disabledLoginButton: false
				})
			}
		})
	}

	handleSubmit(event){
		this.props.appLogin({
			login: this.state.login,
			password: this.state.password,
			cb: this.props.cb
		})
		event.preventDefault();
	}

	handleForgotPassword(dialogData){
		// console.log("REQUESTING PASSWORD RESET", dialogData);
		if(dialogData && dialogData.login){
			if(dialogData.login.indexOf && dialogData.login.indexOf("@geoengineers.com") > -1){
				alert("This application uses the GeoEngineers company login for users at \"geoengineers.com\".  If you would like to reset your password, please press \"ctrl-alt-delete\" and select \"Change a Password\" while logged into a GeoEngineers PC or laptop with the specified user. Follow the instructions to reset your password, then reload this page and try logging in again.");
			}else{
				this.props.appResetPassword({
					...dialogData,
					cb: ()=>{
						alert("Password reset email sent.");
					}
				});
			}
		}else{
			alert("Could not validate email address.")
		}
	}

  	render() {
		const {
			classes
		} = this.props

		const {disableLoginButton} = this.state

    	return (
			<div>
				<form className={classes.loginPage} autoComplete="off" onSubmit={this.handleSubmit}>
					<TextField
						fullWidth
						margin='dense'
						onChange={this.handleChange('login')}
						name='login'
						id='login'
						type="email"
						placeholder="Username" />
					<TextField
						fullWidth
						margin='dense'
						onChange={this.handleChange('password')}
						name='password'
						id='password'
						type="password"
						placeholder="Password" />
					<Button id='submitLogin' variant='outlined' disabled={disableLoginButton} style={{marginTop: 10}} fullWidth type='submit' color="primary" >
						{ this.props.user.inProgress ? <CircularProgress size={16} style={{marginRight: 10}} /> : "" }
						Login
					</Button>
					<Typography
						align='center'
						style={{marginTop: 20}}
					>
						{disableLoginButton ? '@GeoEngineers users must use the Office365 login button below' : 'For @GeoEngineers logins:'} 
					</Typography>
					<Button id='' variant='outlined' fullWidth classes={{root: classes.office365}} color='primary' onClick={this.handleOffice365login}>
						Login with <img alt="Office 365 Logo" src={office365logo}/>
					</Button>
				</form>
				<GenericDialog
					className={classes.forgotPasswordButton}
					saveText="Ok"
					showDelete={false}
					dialogButton={(props) =>
						<Button {...props} fullWidth variant="outlined" color="secondary" size="small" style={{marginRight: 10}}>
							<Hidden smDown>I forgot my password</Hidden>
						</Button>
					}
					dialogTitle="Reset my password"
					dialogFields={
						[
							{
								'name': 'Email',
								'type': 'text',
								'field': 'login'
							}
						]
					}
					onOkCallback={this.handleForgotPassword}
				/>
				<p className={classes.version}>Version {config.version}</p>
			</div>
    	)
  	}
};

LoginForm = connect(
	mapStateToProps,
	mapDispatchToProps
)(LoginForm)

export default withStyles(styles)(LoginForm);
