import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Hidden from '@material-ui/core/Hidden'
import Divider from '@material-ui/core/Divider'
import MenuIcon from '@material-ui/icons/Menu'
import FeedbackIcon from '@material-ui/icons/Feedback'
import {AppItems, AccountItems} from './DrawerContents'
import GenericDialog from '../../utils/GenericDialog'
import topo from '../../topo.png'
import logo from '../../geo_logo.png'
import logoWhite from '../../geo_log_white.svg'
import config from '../../config'
import HostUrl from '../../utils/hostUrl'

import {fetchProjects} from '../../actions/dashboard'

let hostUrl = HostUrl()

const drawerWidth = 240;

const styles = theme => ({
    root: {
        width: '100%',
        // height: `calc(100% - ${theme.spacing.unit * 3}px)` ,
        height: '100%',
        // marginTop: theme.spacing.unit * 3,
        zIndex: 1,
        overflow: 'hidden'
    },
    appFrame: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    appBar: {
        position: 'absolute',
        marginLeft: drawerWidth,
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
    },
    navIconHide: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
	flex: {
		flex: 1
	},
	drawerDocked: {
		height: '100%'
	},
    drawerHeader: theme.mixins.toolbar,
	drawerHeaderMobile: {
		padding: 20
	},
	drawerLogo: {
		padding: [[14, 24, 0, 24]],
		width: '100%'
	},
	barLogo: {
		height: 26,
		margin: [[10,0,0,0]],
		width: '100%'
	},
    drawerPaper: {
        width: 250,
        // height: '100%',
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            position: 'relative',
            height: '100%'
        }
    },
    content: {
        background: '#e3e3e3',
		backgroundImage: 'url(' + topo + ')',
		backgroundRepeat: 'repeat',
        width: '100%',
		overflowY: 'auto',
        padding: theme.spacing.unit * 3,
        height: 'calc(100% - 56px)',
        marginTop: 56,
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100% - 64px)',
            marginTop: 64
        }
    },
	iconSmall: {
		marginBottom: -2,
		fontSize: 20,
		marginRight: 8
	}
});

const mapStateToProps = (state) => {
    // console.log('state', state.user)
    return {
		user: state.user
	}
}

const mapDispatchToProps = dispatch => {
    return {
        fetchProjects: () => dispatch(fetchProjects()),
		// fetchProjectDocumentFolders: () => dispatch(fetchProjectDocumentFolders())
        // appLogin: (creds, body) => { dispatch(login(creds, body)) }
    }
}

class ResponsiveDrawer extends React.Component {

    componentWillMount() {
        this.props.fetchProjects()
		// if (this.props.user.admin) {this.props.fetchProjectDocumentFolders()}
    }

    state = {
        mobileOpen: false
    };

    handleDrawerToggle = () => {
        this.setState({
            mobileOpen: !this.state.mobileOpen
        });
    };

	submitFeedback = (formData) => {
		const { email, name, feedback } = formData

		let token = this.props.user.userInfo.token

		let message = `<h4>This message has been automatically generated by ${config.appName}.</h4>`
		message += `<p>${feedback}</p>`
		message += `<p>Sent by ${name} (${email}).</p>`

		let subject = `Feedback from ${config.appName}`
		// console.log('config', config)
		let postBody = JSON.stringify({
			// to: ['softwareengineeringsupport@geoengineers.com'],
			// to: ['azeigert@geoengineers.com'],
			to: [...config.developerFeedbackRecipients],
			subject: subject,
			html: message,
			from: email
		})

		console.log('postBody', postBody)

		fetch(`${hostUrl}/EaAws/SendEmail?authorization=${token}`,
			{
				"body": postBody,
				"headers": {
					'user-agent': 'ProjectDelivery',
					'content-type': 'application/json'
				},
				"method": 'POST'
			})
			.then(response => response.json())
			.then(json => {
				if (json.name === 'EaError'){
					// dispatch error action
				} else {
					alert('Message sent successfully!')
				}
			})
			.catch(err => alert('There was an error sending your message.', err))
	}

    render() {
        const {classes, theme} = this.props;

        const drawer = (
			<div className={classes.drawerContainer}>
	            <div className={classes.drawerHeader}>
					<Hidden smUp><Typography className={classes.drawerHeaderMobile} variant='h6'>Client Project Tracker</Typography></Hidden>
					<Hidden smDown><img className={classes.drawerLogo} alt='GeoEngineers' src={logo}/></Hidden>
				</div>
	            <Divider/>
	            <List component="nav">
					<AppItems/>
				</List>
	            <Divider/>
	            <List component="nav">
					<AccountItems />
				</List>
        	</div>
		);

        return (<div className={classes.root}>
            <div className={classes.appFrame}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={this.handleDrawerToggle}
							className={classes.navIconHide}
						>
                            <MenuIcon/>
                        </IconButton>
                        <Typography variant='h6' color="inherit" className={classes.flex} noWrap={true}>
                            <Hidden smDown>Project Delivery</Hidden>
							<Hidden smUp><img className={classes.barLogo} alt='GeoEngineers' src={logoWhite}/></Hidden>
                        </Typography>
						<GenericDialog
							dialogButton={(props) => <Button {...props} variant="outlined" color="secondary" size="small" style={{marginRight: 10}}><FeedbackIcon className={classes.iconSmall}/><Hidden smDown>Feedback</Hidden></Button>}
							dialogTitle="Send Feedback"
							dialogFields={
								[
									{
										'name': 'Name',
										'type': 'text',
										'field': 'name'
									},
									{
										'name': 'Email',
										'type': 'text',
										'field': 'email'
									},
									{
										'name': 'Feedback',
										'type': 'textarea',
										'field': 'feedback'
									},
									// {
									// 	'name': 'count',
									// 	'type': 'number'
									// }
								]
							}
							onSaveCallback={this.submitFeedback}
							// onDeleteCallback={(formData) => console.log('delete callback')}
							data={{name: `${this.props.user.userInfo.firstName} ${this.props.user.userInfo.lastName}`, email: this.props.user.userInfo.email}}
						/>
                    </Toolbar>
                </AppBar>
                <Hidden mdUp={true}>
                    <Drawer
						variant="temporary"
						anchor={theme.direction === 'rtl'
                            ? 'right'
                            : 'left'}
						open={this.state.mobileOpen}
						classes={{
                            paper: classes.drawerPaper,
                        }}
						onClose={this.handleDrawerToggle}
						ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}>
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden smDown={true} implementation="css">
                    <Drawer
						variant="permanent"
						open={true}
						classes={{
                            paper: classes.drawerPaper,
							docked: classes.drawerDocked
                        }}>
                        {drawer}
                    </Drawer>
                </Hidden>
                <main className={classes.content}>
                    {this.props.children}
                </main>
            </div>
        </div>);
    }
}

ResponsiveDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const AppBarWrapper = connect(mapStateToProps, mapDispatchToProps)(ResponsiveDrawer)

export default withStyles(styles, {withTheme: true})(AppBarWrapper);
