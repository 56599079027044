import React from 'react';
import { connect } from 'react-redux';
// import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import blueGrey from '@material-ui/core/colors/blueGrey'

// import Button from '@material-ui/core/Button'

import SiteDialog from './SiteDialog'
import PhaseDialog from './PhaseDialog'
import ProgressIndicator from './ProgressIndicator'
import LinearProgress from '@material-ui/core/LinearProgress'
import ArchivedChip from '../../utils/ArchivedChip';

// import './styles.css';
// import map from 'lodash/map'
import filter from 'lodash/filter'
import find from 'lodash/find'
// import indexOf from 'lodash/indexOf'
import keyBy from 'lodash/keyBy'
import intersection from 'lodash/intersection'


const styles = theme => ({
	paper: {
		padding: theme.spacing.unit * 2,
		// marginTop: 25,
		// textAlign: 'center',
		color: theme.palette.text.secondary
	},
	h2: {
		marginTop: 0
	},
	bar: {
		transition: "transform .7s ease-in-out"
	},
	progress: {
		height: 14
	},
	siteColorPrimary: {
		backgroundColor: blueGrey['100'],
	},
	siteBarColorPrimary: {
		backgroundColor: blueGrey['500']
	},
	siteProgressLabel: {
		paddingTop: 0
	}
})

const mapStateToProps = (state, ownProps) => {
	// console.log('statestate', state)
	// console.log('ownProps', ownProps)
	let projectList = state.user.showInactive ? state.projects : keyBy(filter(state.projects, ['active', true]), 'uid')
	let siteList = state.user.showInactive ? state.sites : keyBy(filter(state.sites, ['active', true]), 'uid')
	let phaseList = state.user.showInactive ? state.phases : keyBy(filter(state.phases, ['active', true]), 'uid')
	// console.log('projectList', projectList)
	// console.log('siteList', siteList)
	// console.log('phaseList', phaseList)

	let activeProject = projectList[state.user.selectedProjectId] ? projectList[state.user.selectedProjectId] : projectList ? projectList[0] : null
	// console.log('activeProject', activeProject)
	let activeProjectSites = activeProject && activeProject.projectSites ? intersection(activeProject.projectSites, Object.keys(siteList)) : [];
	// console.log('activeProjectSites', activeProjectSites)
	let activeSite = siteList[state.user.selectedSiteId] ? siteList[state.user.selectedSiteId] : activeProject ? find(siteList, (s, key) => key === activeProjectSites[0]) : null
	// console.log('activeSite', activeSite)
	// let activeSitePhases = activeSite && activeSite.projectPhases ? intersection(activeSite.projectPhases, Object.keys(phaseList)) : []
	let activeSitePhases = filter(phaseList, phase => {
		return activeSite && activeSite.projectPhases ? activeSite.projectPhases.indexOf(phase.uid) > -1 : false;  
	})

	return {
		admin: state.user.admin,
		project: activeProject,
		site: activeSite,
		phases: activeSitePhases ? activeSitePhases.sort((a,b) => new Date(a.startedAt) - new Date(b.startedAt)) : [],
		showInactive: state.user.showInactive
	}
}

const OverallPercentage = withStyles(styles)(({classes, site}) => (
	<div>
		<Typography variant='overline' className={classes.siteProgressLabel}>{`Overall site progress: ${site.percentComplete}%`}</Typography>
		<LinearProgress
			// classes={{
			// 	bar1Determinate: classes.bar
			// }}
			color='secondary'
			className={classes.progress}
			variant="determinate"
			value={site ? site.percentComplete : 0}
			// classes={
			// 	{ 
			// 		colorPrimary: classes.siteColorPrimary,
			// 		barColorPrimary: classes.iteBarColorPrimary
			//  	}
			// }
		/>
	</div>
))

const Overview = ({ admin, project, site, phases, classes }) => (
	<Grid container justify={'center'} alignItems={'center'} spacing={16}>
		<Grid item xs={12} md={12}>
			<Paper className={classes.paper}>
				<Typography component="h3" variant='h5'>
					{site ? site.name : "No site selected"}
					{site && site.archived ? <ArchivedChip/> : null}
					{admin && site ? <SiteDialog displayStyle={{display: "inline-block"}} site={site} project={project}/> : null }
					{admin && site ? <PhaseDialog displayStyle={{display: "inline-block"}} newPhase={true} /> : null}
				</Typography>
				{site ? <OverallPercentage site={site}/> : null}
				{phases.length > 0 ? phases.map((phase, key) => <ProgressIndicator key={phase.uid} phase={phase}/>) : <Typography component="h4" variant='h6'>No Phases</Typography>}
			</Paper>
		</Grid>
	</Grid>
)

const ProgressOverview = connect(
	mapStateToProps,
)(Overview)

export default withStyles(styles)(ProgressOverview);
