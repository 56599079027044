import React from 'react'
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
// import classNames from 'classnames'
// import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'

import ProjectDialog from '../ProgressOverview/ProjectDialog';
import SiteDialog from '../ProgressOverview/SiteDialog';

import { changeSelectedProject, setSelectedSite } from '../../actions/user';
import { fetchOrganizationList } from '../../actions/contacts'

// import values from 'lodash/values'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
// import keyBy from 'lodash/keyBy'
// import pickBy from 'lodash/pickBy'
import find from 'lodash/find'
// import indexOf from 'lodash/indexOf'
// import intersection from 'lodash/intersection'

const styles = theme => ({
	formControl: {
	    // margin: theme.spacing.unit,
		// paddingRight: 20,
	    minWidth: 120,
		width: '100%'
	},
	container: {
		marginBottom: 14
	},
	button: {
	    marginTop: 10,
	},
	leftIcon: {
		marginRight: theme.spacing.unit,
	},
	rightIcon: {
		marginLeft: theme.spacing.unit,
	},
	iconSmall: {
		fontSize: 20,
	},
	admin: {
		backgroundColor: theme.palette.admin.main,
		'&:hover': {
			backgroundColor: theme.palette.admin.dark
		}
	}
})

const mapStateToProps = (state, ownProps) => {
	let filters = {};
	if (!state.user.showInactive) { filters.active = true;}
	if (!state.user.showArchived) { filters.archived = false;}
	let filteredProjects = filter(state.projects, filters);
	let filteredSites = filter(state.sites, filters);
	// console.log('filteredSites', filteredSites)
	let selectedProject = find(filteredProjects, {uid: state.user.selectedProjectId}) ? find(filteredProjects, {uid: state.user.selectedProjectId}) : filteredProjects[0];
	let availableProjectSites = selectedProject && selectedProject.projectSites ? filter(filteredSites, s => selectedProject.projectSites.indexOf(s.uid) > -1) : [];
	// console.log('availableProjectSites', availableProjectSites)
	let selectedSite = find(filteredSites, {uid: state.user.selectedSiteId}) ? find(filteredSites, {uid: state.user.selectedSiteId}) : selectedProject ? availableProjectSites[0] : null;
	// console.log('selectedSite', selectedSite)
	return {
		projects: sortBy(filteredProjects, 'name'),
		selectedProject: selectedProject,
		sites: sortBy(availableProjectSites, 'name'),
		selectedSite: selectedSite,
		admin: state.user.admin,
		showInactive: state.user.showInactive,
		user: state.user
	}
}

const mapDispatchToProps = (dispatch, next) => {
	return {
		setProject: (update) => {
			// console.log('project update', update); 
			dispatch(changeSelectedProject(update))
		},
		setSite: (update) => {
			// console.log('site update', update)
			dispatch(setSelectedSite({site: update}))
		},
		fetchOrgs: () => dispatch(fetchOrganizationList())
	}
}

class ProjectSelect extends React.Component {

	componentWillMount(){
		if (this.props.admin){
			this.props.fetchOrgs()
		}
	}

	componentDidUpdate(prevProps){
		// console.log('prevProps', prevProps)
		// console.log('this.props', this.props)
		let p = this.props.selectedProject ? this.props.selectedProject.uid : null;
		let pp = prevProps.user.selectedProjectId;
		let s = this.props.selectedSite ? this.props.selectedSite.uid : null;
		let ss = prevProps.user.selectedSiteId;

		if (p && p !== pp){
			this.props.setProject(this.props.selectedProject.uid)
		} else if (s && s !== ss){
			this.props.setSite(this.props.selectedSite.uid)
		}

	}

	handleChange = event => {
		if (event.target.name === 'newProject') {
			console.log('new project dialog, not a selection!')
		} else if (event.target.name === 'newSite'){
			console.log('new site dialog, not a selection!')
		} else {
			this.props[event.target.name](event.target.value)
		}
	}

	render() {
		const { classes, admin, projects, selectedProject, sites, selectedSite } = this.props;
		// console.log("projects", projects);
		// console.log('selectedProject', selectedProject)
		// console.log('selectedSite', selectedSite)
		// console.log('this.propssss', this.props)
		// console.log("sites", sites);
		// console.log("selectedSiteId", selectedSiteId)
		return (
			<Grid className={classes.container} container spacing={16}>
				<Grid item xs={admin ? 10 : 12} md={admin ? 7 : 8}>
					<FormControl className={classes.formControl}>
						<InputLabel htmlFor='project-name'>Selected Project</InputLabel>
						<Select
							value={selectedProject ? selectedProject.uid : "No projects"}
							onChange={this.handleChange}
							// autoWidth={true}
							inputProps={{
								name: 'setProject',
								id: 'project-name'
							}}
						>
							{projects.length ? sortBy(projects, 'name').map(proj => <MenuItem value={proj.uid} key={proj.uid}>{proj.name} {proj.archived ? "(Archived)" : false}</MenuItem>) : null }

							{admin ? <ProjectDialog dialogButton={(props) => <MenuItem {...props}>+ Add new project</MenuItem>}/> : false}
						</Select>
					</FormControl>
				</Grid>
				{admin ? <Grid item xs={2} md={1}>
					{selectedProject ? (
						<ProjectDialog
							dialogButton={(props) => (
								<Tooltip id="tooltip-setup" placement="top" title="Project Setup">
									<Fab className={classes.admin} size="small" {...props}>
										<EditIcon />
									</Fab>
								</Tooltip>
							)}
							project={selectedProject}
						/>) : (
						<ProjectDialog
							dialogButton={(props) => (
								<Tooltip id="tooltip-setup" placement="top" title="Add Project">
									<Fab className={classes.admin} size="small" {...props}>
										<AddIcon />
									</Fab>
								</Tooltip>
							)}
						/>
					)}
				</Grid> : false}
				<Grid item xs={12} md={4}>
					<FormControl className={classes.formControl}>
						<InputLabel htmlFor='project-name'>Selected Site</InputLabel>
						<Select
							value={selectedSite ? selectedSite.uid : "No sites"}
							onChange={this.handleChange}
							// autoWidth={true}
							inputProps={{
								name: 'setSite',
								id: 'site-name'
							}}
						>
							{sites.length ? sortBy(sites, 'name').map(site => <MenuItem value={site.uid} key={site.uid}>{site.name} {site.archived ? "(Archived)" : false}</MenuItem>) : null}
							{admin ? <MenuItem id='newSite' component={SiteDialog} value='newSite'>+ Add new site</MenuItem> : false}
						</Select>
					</FormControl>
				</Grid>
			</Grid>
		)
	}
}

const ProjectSwitcher = connect(
	mapStateToProps,
	mapDispatchToProps
)(ProjectSelect)

export default withStyles(styles)(ProjectSwitcher);
