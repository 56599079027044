import HostUrl from '../../utils/hostUrl'

import { fetchProjectDocumentFolders, fetchProjectPhotoFolders} from '../dashboard'

// import {setSelectedPhotoFolder, setSelectedDocumentsPath} from '../documents'

import filter from 'lodash/filter'
import intersection from 'lodash/intersection'
// import values from 'lodash/values'

let hostUrl = HostUrl()

export const LOGIN_REQUEST_SENT = 'LOGIN_REQUEST_SENT'
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS'
export const LOGIN_REQUEST_ERROR = 'LOGIN_REQUEST_ERROR'
export const PASSWORD_RESET_SENT = 'PASSWORD_RESET_SENT'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR'
export const LOGOUT = 'LOGOUT'
export const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT'
export const SET_SELECTED_SITE = 'SET_SELECTED_SITE'
export const TOGGLE_SHOW_INACTIVE = 'TOGGLE_SHOW_INACTIVE'
export const TOGGLE_SHOW_ARCHIVED = 'TOGGLE_SHOW_ARCHIVED'


function loginRequestSent(creds){
	// console.log('requesting login for ', creds)
	return {
		type: LOGIN_REQUEST_SENT,
		creds
	}
}

function loginRequestSuccess(creds, json){
	// console.log('received token for', creds)
	// console.log('login info ', json)
	return {
		type: LOGIN_REQUEST_SUCCESS,
		// request,
		user: json,
		receivedAt: Date.now()
	}
}

function loginRequestError(creds, error){

	// console.log('typeof error', typeof error)
	// console.log('error', error)
	alert(error)
	// console.log('error logging in ', creds, json.logEntry.message)
	// let message = JSON.stringify(error)
	return {
		type: LOGIN_REQUEST_ERROR,
		user: creds,
		error: error,
		receivedAt: Date.now()
	}
}

export const login = (creds) => {
	return function (dispatch) {
		// dispatch the request sent action
		dispatch(loginRequestSent(creds))
		// console.log('creds', creds)
		// return a promise from a rest request
		return fetch(`${hostUrl}/EaLogin/LoginUser`,
			{
				"body": JSON.stringify(creds),
				"headers": {
					'user-agent': 'ProjectDelivery',
					'content-type': 'application/json'
				},
				"method": 'POST'
			}
		)
		.then(response => response.json())
		.then(loginResponse => {
			// console.log('loginResponse', loginResponse)
			if (loginResponse.name === 'EaError'){
				dispatch(loginRequestError(creds, loginResponse.logEntry.message))
			} else {
				dispatch(loginRequestSuccess(creds, loginResponse))
				creds.cb()
			}
		})
		.catch(err => dispatch(loginRequestError(creds, err)))
	}
}

export const office365login = (creds) => {
	return function(dispatch){
		// console.log('token', creds)
		let office365creds = {
			login: creds.token.account.userName,
			password: '',
			azureResult: creds.token,
			cb: creds.cb
		}
		dispatch(login(office365creds))
	}
}

function passwordResetSent(creds){
	return {
		type: PASSWORD_RESET_SENT,
		creds
	}
}

function passwordResetSuccess(creds, json){
	// console.log('received token for', creds)
	// console.log('login info ', json)
	return {
		type: PASSWORD_RESET_SUCCESS,
		user: json,
		receivedAt: Date.now()
	}
}

function passwordResetError(creds, error){
	// console.log('typeof error', typeof error)
	// console.log('RP creds error', creds, error)
	alert(error)
	return {
		type: PASSWORD_RESET_ERROR,
		user: creds,
		error: error,
		receivedAt: Date.now()
	}
}

export const resetPassword = (creds) => {
	return function (dispatch) {
		// dispatch the request sent action
		dispatch(passwordResetSent(creds))
		// console.log('creds', creds)
		// return a promise from a rest request
		return fetch(`${hostUrl}/EaLogin/RequestPasswordReset`,
			{
				"body": JSON.stringify(creds),
				"headers": {
					'user-agent': 'ProjectDelivery',
					'content-type': 'application/json'
				},
				"method": 'POST'
			}
		)
		.then(response => response.json())
		.then(resetResponse => {
			// console.log('resetResponse', resetResponse)
			if (resetResponse.name === 'EaError'){
				dispatch(passwordResetError(creds, resetResponse.logEntry.message))
			} else {
				dispatch(passwordResetSuccess(creds, resetResponse))
				creds.cb()
			}
		})
		.catch(err => dispatch(passwordResetError(creds, err)))
	}
}

export function changeSelectedProject(item){
	// console.log('set selected project and default site', item);
	return function(dispatch, getState){
		dispatch(setSelectedProject(item))
		// console.log("getState()", getState());
		if ( getState().projects[item] && getState().projects[item].projectSites ){
			// we need to make sure that a default site is selected, which would normally be [0]
			// but just in case [0] is inactive, we need to filter by active sites, which is a bit of a pain

			// get the project sites. this is an array of IDs
			let projectSites = getState().projects[item].projectSites;
			// filter the sites list to find active ones, then map to an array of just the uids
			let activeAllSites = filter(getState().sites, ['active', true]).map(s => s.uid);
			// find the intersection between the project sites and the active sites
			let activeProjectSites = intersection(projectSites, activeAllSites);
			// get the first one of thems
			let selectedSiteId = activeProjectSites[0]
			// console.log('site', selectedSiteId)
			// let site = getState().sites[selectedSiteId]
			// let payload = {site: selectedSiteId, photosFolderPath: site.photosFolderPath, documentsFolderPath: site.documentsFolderPath}
			dispatch(setSelectedSite({site: selectedSiteId}))
		}
		dispatch(fetchProjectDocumentFolders())
		dispatch(fetchProjectPhotoFolders())
		return "PROJECTSELECTIONCHANGED";
	}
}

function setSelectedProject(payload){
	// console.log('setting selected project', payload);
	return {
		type: SET_SELECTED_PROJECT,
		payload
	}
}

export function setSelectedSite(payload){
	return function(dispatch, getState){
		// console.log('payload', payload)
		let site = getState().sites[payload.site] || null;
		// console.log('site', site)
		let sitePayload = {site: payload.site, photosFolderPath: site ? site.photosFolderPath: null, documentsFolderPath: site ? site.documentsFolderPath : null}
		// console.log('payload', sitePayload)
		dispatch({
			type: SET_SELECTED_SITE,
			payload: sitePayload
		})
	}
}

export function setSelectedProjectAndSite(payload){
	// console.log('payload', payload)
	return function(dispatch, getState){
		dispatch(setSelectedSite({site: payload.site}))
		dispatch(setSelectedProject(payload.project))
		dispatch(fetchProjectDocumentFolders())
		dispatch(fetchProjectPhotoFolders())
	}
}

export const toggleUserAttributes = (payload) => {
	// console.log('payload', payload)

	return function(dispatch, getState){
		let user = getState().user
		let body = {
			attributesJson: {
				showInactive: payload === 'inactive' ? !user.showInactive : user.showInactive,
				showArchived: payload === 'archived' ? !user.showArchived : user.showArchived
			}
		}
		let token = user.userInfo.token
		return fetch(`${hostUrl}/AppProjectDelivery/LicenseAttributes?authorization=${token}`,
			{
				"body": JSON.stringify(body),
				"headers": {
					'user-agent': 'ProjectDelivery',
					'content-type': 'application/json'
				},
				"method": 'POST'
			}
		)
		.then(response => response.json())
		.then(json => {
			// console.log('json', json)

			if (json.name === 'EaError'){
				throw('license change error', json)
			} else {
				dispatch(toggleShowArchived(json.attributesJson.showArchived))
				dispatch(toggleShowInactive(json.attributesJson.showInactive))
			}
		})
		.catch(err => { throw(err) })
	}
}

function toggleShowArchived(payload){
	return function(dispatch, getState){
		dispatch({
			type: TOGGLE_SHOW_ARCHIVED,
			payload
		})
	}
}

function toggleShowInactive(payload){
	// console.log('payload', payload)
	return function(dispatch, getState){
		dispatch({
			type: TOGGLE_SHOW_INACTIVE,
			payload
		})
		// dispatch(fetchProjects())
	}
}

export const logout = () => {
	return {
		type: 'LOGOUT'
	}
}
