import HostUrl from '../../utils/hostUrl'
// import exampleResponse from './SharepointResponse.json'

export const FETCH_SHAREPOINT_START = 'FETCH_SHAREPOINT_START'
export const FETCH_SHAREPOINT_FAILED = 'FETCH_SHAREPOINT_FAILED'
export const FETCH_SHAREPOINT_SUCCEED = 'FETCH_SHAREPOINT_SUCCEED'
export const SET_SELECTED_DOCUMENT_FOLDER = 'SET_SELECTED_DOCUMENT_FOLDER'
export const SET_SELECTED_PHOTO_FOLDER = 'SET_SELECTED_PHOTO_FOLDER'

let hostUrl = HostUrl();


function fetchSharepointStart(payload){
	// console.log('phase edit start', phase)
	// console.log('site', site)
	return {
      	type: FETCH_SHAREPOINT_START,
		payload
  	};
}

function fetchDocumentsSucceed(payload){
	// console.log('phase edit success', phase)
	// console.log('site', site)
	return {
      	type: FETCH_SHAREPOINT_SUCCEED,
		payload
  	};
}

export function fetchDocuments(payload){
	return function(dispatch, getState){
		// console.log('getState', getState().user)
		// let fetchFolder = payload.folder ? payload.folder : defaultFolder
		// console.log('payload', payload)
		dispatch(fetchSharepointStart(payload))
		let token = getState().user.userInfo.token
		// console.log('payload', payload)
		if (payload.projectNumber && payload.folder){
			// console.log('thiiiiiissss')
			return fetch(`${hostUrl}/EaSharepoint/Documents/${payload.projectNumber}?folder=${payload.folder}&authorization=${token}`)
				.then(response => {
					// console.log('response', response)
					return response.json()
				})
				.then(json => {
					// console.log('docs json', json)
					if (json.name === 'EaError'){
						// dispatch error action
						dispatch(fetchSharepointFailed(json))
					} else {
						// console.log('json', json)
						dispatch(fetchDocumentsSucceed({projectId: payload.projectNumber, folder: payload.folder, json: json}))
					}
				})
				.catch(error => {
					console.log('error: ', error)
					dispatch(fetchSharepointFailed(error))
				})
		} else {
			console.log(`project number: ${payload.projectNumber} or site folder: ${payload.folder} is blank`)
		}
		// let selectedProjectId = getState().user.selectedProjectId
	}
}

export function setSelectedDocumentFolder(payload){
	// console.log('document payload', payload)
	return {
		type: SET_SELECTED_DOCUMENT_FOLDER,
		payload
	}
}

export function setSelectedPhotoFolder(payload){
	// console.log('photo payload', payload)
	return {
		type: SET_SELECTED_PHOTO_FOLDER,
		payload
	}
}

function fetchSharepointFailed(){
	return {
      	type: FETCH_SHAREPOINT_FAILED,
  	};
}
