import React from 'react'
import { connect } from 'react-redux'
// import classNames from 'classnames'
// import MUIDataTable from 'mui-datatables'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
// import FolderIcon from '@material-ui/icons/Folder'
// import ImageIcon from '@material-ui/icons/Image'
// import Fab from '@material-ui/core/Fab'
// import HomeIcon from '@material-ui/icons/Home'
// import IconButton from '@material-ui/core/IconButton'
import GridList from '@material-ui/core/GridList'
// import ListSubheader from '@material-ui/core/ListSubheader'
import GridListTile from '@material-ui/core/GridListTile'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
// import Toolbar from '@material-ui/core/Toolbar'
import Breadcrumbs from '@material-ui/lab/Breadcrumbs'

// import Breadcrumbs, { BreadcrumbBack } from '../../utils/breadcrumbs'

import Tile from './tile'

import difference from 'lodash/difference'

import HostURL from '../../utils/hostUrl'

import { fetchDocuments, setSelectedPhotoFolder } from '../../actions/documents'

// import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'

let hosturl = HostURL();

// const AbortController = window.AbortController;

const styles = theme => ({
	paper: {
    	padding: theme.spacing.unit * 2,
		// marginTop: 20,
    	// textAlign: 'center',
    	color: theme.palette.text.secondary
    },
	downloadLink: {
		paddingLeft: 5
	},
	homeButton: {
		marginLeft: -10,
		marginRight: 5
	},
	hidden: {
		display: 'none'
	},
	breadcrumb: {
		display: 'inline-block',
		marginLeft: 10,
		'& h2': {
			display: 'inline-block'
		}
	},
	photosHeader: {

	},
	gridList: {
		// width: '100%',
		// height: 400
	},
	folderTile: {
		height: 120,
		width: 160,
		flex: 'auto',
		padding: 2,
		'& button': {
			height: '100%'
		}
	},
	paperTemp: {
		padding: '0 24px 24px 24px'
	},
	gridListRoot: {
		// marginTop: 10,
		padding: '0 24px 24px 24px',
		display: 'flex',
	    flexWrap: 'wrap',
	    justifyContent: 'space-around',
	    overflowY: 'scroll',
		overflowX: 'hidden',
	    backgroundColor: theme.palette.background.paper,
	},
})

const mapDispatchToProps = dispatch => {
	return {
		fetchDocuments: (payload) => dispatch(fetchDocuments(payload)),
		setSelectedPhotoFolder: (payload) => dispatch(setSelectedPhotoFolder(payload))
	}
}

const mapStateToProps = (state, ownProps) => {
	// console.log('photos statestate', state.docs)
	// we might eventually only pass the state of the current site/crossing/project
	return {
		token: state.user.userInfo.token,
		selectedFolder: state.docs.selectedPhotoFolder,
		projectPhotos: state.docs.documents[state.docs.selectedPhotoFolder] ? state.docs.documents[state.docs.selectedPhotoFolder] : [],
		photosLoading: state.docs.photosLoading,
		selectedSiteId: state.user.selectedSiteId,
		site: state.user.selectedSiteId && state.sites[state.user.selectedSiteId] ? state.sites[state.user.selectedSiteId] : null,
		projectNumber: state.user.selectedProjectId && state.projects[state.user.selectedProjectId] ? state.projects[state.user.selectedProjectId].projectNumber : null
	}
}

class Photos extends React.Component {
	constructor(props){
		super(props)
		this.openFolder = this.openFolder.bind(this)
	}

	componentDidMount(){
		// console.log('this.props', this.props)
		const { projectNumber, selectedFolder, fetchDocuments } = this.props
		// console.log('projectNumber', projectNumber);
		// console.log('selectedFolder', selectedFolder);
		
		function doIt(){
			return fetchDocuments({projectNumber, folder: selectedFolder})
		}
		
		if (projectNumber && selectedFolder){
			// this is a dumb hack because there are so many things happening here that the component doesn't seem to update correctly when they are finished
			this.stupidTimeout = setTimeout(doIt, 500)
		}
	}

	componentWillReceiveProps(nextProps){
		// console.log('this.props', this.props)
		// console.log('nextProps', nextProps)
		const { selectedFolder, projectNumber } = nextProps
		// console.log('nextProps', nextProps)
		// console.log('selectedFolder', selectedFolder, 'projectNumber', projectNumber)
		if ((this.props.projectNumber !== projectNumber || this.props.selectedFolder !== selectedFolder) && projectNumber && selectedFolder){
			nextProps.fetchDocuments({projectNumber, folder: selectedFolder})
		}
	}

	componentWillUnmount(){
		clearTimeout(this.stupidTimeout)
	}

	// componentDidUpdate(prevProps, prevState){
	// 	console.log('prevProps', prevProps)
	// 	console.log('prevState', prevState)
	// 	console.log('this.state', this.state)
	// }

	download(path){
		// console.log('path', path)
		let url = `${hosturl}/EaSharepoint/ProjectDocumentDownload?path=${path}&authorization=${this.props.token}`
		window.location.href = url
		return false

	}

	getBreadcrumbs(path, basePath){
        // const {path, basePath} = this.props
		// console.log("path", path)
        // console.log('basePath', basePath)
		let splitPath = path.split('/')
        let splitBasePath = basePath.split('/')
        let homePath = splitBasePath.slice(-1)
        let paths = [
            {
                linkPath: basePath,
                label: homePath[0]
            }
        ]

        let additionalPaths = difference(splitPath, splitBasePath)
        additionalPaths.forEach(a => {
            let interstitialPath = splitPath.slice(0, splitPath.findIndex(b => b === a) + 1).join('/')
            // console.log('interstitialPath', interstitialPath)
            paths.push({
                linkPath: interstitialPath,
                label: a
            })
        })
        // console.log('paths', paths)
		return paths
	}

	openFolder(e){
		console.log(e)
		this.props.setSelectedPhotoFolder({selectedFolder: e})
	}

	render(){
		// console.log('this.props', this.props)
		const {projectPhotos, photosLoading, projectNumber, selectedFolder, classes, widget, site} = this.props
		// console.log('site', site)
		// console.log("selectedFolder", selectedFolder);
		// console.log('projectNumber', projectNumber)
		// console.log('projectPhotos', projectPhotos)
		if (!projectNumber || !selectedFolder){
			return (
				<Paper className={classes.paper}>
					{/* <Toolbar> */}
						<Typography variant='h6'>
							Photos
						</Typography>
					{/* </Toolbar> */}
					<Typography>
						{
							projectNumber && !selectedFolder
							? 'This project is associated with a Project Number but this site is not associated with a Sharepoint photos folder.'
							: 'This project is not associated with a Sharepoint Project.'
						}
					</Typography>
				</Paper>
			)
		} else if (photosLoading) {
			return (
				<Paper className={classes.paper}>
					{/* <Toolbar> */}
						<Typography variant='h6'>
							Photos
						</Typography>
					{/* </Toolbar> */}
					<Typography className={classes.paperTemp}>
						Loading...
					</Typography>
				</Paper>
			)
		} else {
			return (
				<Paper className={classes.paper} >
					<Typography key={1} variant='h6' >Photos</Typography>
					<Breadcrumbs maxItems={widget ? 2 : 100} aria-label="Breadcrumb">
						{this.getBreadcrumbs(selectedFolder, site.photosFolderPath).map(p => {
							return <Link key={p.label} color='inherit' href="#" onClick={() => this.openFolder(p.linkPath)}>{p.label}</Link>
						})}
					</Breadcrumbs>
					<br/>
					<div className={classes.gridListRoot} style={widget ? {height: '400px'} : null}>
						<GridList cellHeight={120} spacing={4} className={classes.gridList} cols={widget ? 2 : 6}>
							{projectPhotos.length ? sortBy(projectPhotos, 'name').map((photo, index) => {
								// console.log('photo', photo)
								if (photo.type === 'folder'){
									return (
										<GridListTile
											cols={1}
											key={index}
											component={props => (
												<div className={classes.folderTile}>
													<Button
														variant="outlined"
														aria-label="Open Folder"
														onClick={() => this.openFolder(photo.path)}
													>
											        	<FolderOpenIcon className={classes.extendedIcon} />
											        	 {photo.name}
											      	</Button>
												</div>
											)}
										/>
									)
								} else {
									return <Tile key={index} tile={photo}/>
								}

							}) : <GridListTile component={() => <p>Photos folder empty</p>}/>}
					      </GridList>
					</div>
				</Paper>
			)
		}
	}
}

const PhotosOverview = connect(
	mapStateToProps,
	mapDispatchToProps
)(Photos)

export default withStyles(styles)(PhotosOverview)
